.rdtPicker {
  .rdtSwitch {
    color: #657790;
    font-weight: 600;
  }

  .rdtPrev>span,
  .rdtNext>span {
    color: #657790;
    font-weight: 400;
  }

  .dow {
    color: #96a1b0;
    font-weight: 400;
    font-size: 14px;
    padding: 15px 0;
  }

  .rdtDay {
    color: #153447;
    padding: 5px;
  }

  td.rdtActive,
  td.rdtActive:hover,
  td.rdtDay:hover,
  td.rdtHour:hover,
  td.rdtMinute:hover,
  td.rdtSecond:hover {
    border-radius: 50%;
  }

  .rdtTimeToggle:hover {
    border-radius: 4px;
  }
}
