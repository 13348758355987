.scanning-page {
  header {
    height: 56px;
    padding: 0 24px;

    .left-side {
      a {
        padding: 10px 0;
      }
    }

    .menu,
    .right-side {
      display: none !important;
    }

    .scanning-actions {
      position: absolute;
      padding: 15px;
      right: 0;

      button {
        padding: 5px;
      }
    }
  }
}

header {
  z-index: 11;
  background-color: var(--header-color);
  padding: 0 0 0 16px;
  position: relative;
  height: 56px;

  .header-wrapper {
    height: 56px;
    display: flex;
    align-items: center;
    flex-direction: row;

    >.container {
      >div {
        display: flex;
        position: relative;
        height: 56px;
      }
    }
  }

  .right-side {
    width: 300px;
    height: 56px;

    .user-wrapper {
      display: flex;
      width: auto;
      height: 100%;

      >div {
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        &::before {
          margin-right: 0;
        }

        >.select-advanced {
          width: 100%;
        }
      }

      .bottom-area {
        top: 50px;
      }

      .kmb-loader {
        width: 100%;

        >li {
          background-color: white;
        }
      }

      .upper-area {
        background-color: transparent;
        border: none;
        color: white;
        text-align: right;

        >span:first-child {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        >span:last-child {
          top: 10px;
        }
      }
    }

    .notifications-wrapper {
      font-size: 17px;
      width: 60px;
      height: 56px;
      text-align: center;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-left: 1px solid #7f8f99;

      >div {
        height: 100%;

        >div {
          position: relative;
          cursor: pointer;
        }
      }

      span.icon-bell {
        position: relative;
        top: 4px;
      }

      span.notification-count {
        background-color: var(--notification-color);
        border-radius: 100%;
        position: absolute;
        right: -8px;
        top: 0;
        width: 8px;
        height: 8px;
      }
    }

    button.logout {
      padding: 20px 0;
    }
  }

  .logo {
    width: max-content;
    height: 56px;
    display: flex;
    align-items: center;

    img {
      height: 56px;
    }

    a {
      display: inline-block;
    }

    h1 {
      display: inline-block;
      color: white;
      font-size: 24px;
      font-weight: 700;
      margin: 0;
    }

    span {
      display: inline-block;
      color: var(--secondary);
      font-size: 30px;
      position: relative;
      top: 5px;
      padding-right: 8px;
    }
  }

  .menu {
    >nav {
      height: 100%;
      display: flex;
      overflow: auto;
    }

    width: calc(100% - 200px);
    padding-left: 30px;

    ul.nav {
      display: flex;

      >li {
        position: relative;
        color: #fff;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        cursor: pointer;

        &#events-menu-item {
          min-width: 75px;
          max-width: 75px;
          width: 75px;
        }

        &#crm-menu-item {
          min-width: 62px;
          max-width: 62px;
          width: 62px;
        }

        &#sponsors-menu-item {
          min-width: 94px;
          max-width: 94px;
          width: 94px;
        }

        &#hotels-menu-item {
          min-width: 74px;
          max-width: 74px;
          width: 74px;
        }

        &#staff-menu-item {
          min-width: 63px;
          max-width: 63px;
          width: 63px;
        }

        &#bank-menu-item {
          min-width: 132px;
          max-width: 132px;
          width: 132px;
        }

        &.selected {
          .header-link {
            color: white;
          }
        }

        &>.header-link {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          background: none;
          border-radius: 4px;
          color: var(--black-tone-4);
        }

        &:hover {
          .fill-header-background {
            opacity: 1;
            visibility: visible;
            pointer-events: auto;
            max-height: 1000px;
          }
        }

        .fill-header-background {
          overflow: hidden;
          padding: 15px;
          transition: all 0.2s;
          visibility: hidden;
          max-height: 0;
          pointer-events: none;
          opacity: 0;
          position: absolute;
          top: 60px;
          background-color: #fff;
          width: 100%;
          z-index: 100;
          left: 0;
          box-shadow: 0 3px 50px 0 hsl(0deg 0% 48.2% / 50%);

          .nav-children {
            max-width: 1130px;
            background-color: #fff;

            .nav-children-item {
              display: flex;
              flex-flow: column;
              color: #f9f9f9;
              position: relative !important;

              .background {
                svg {
                  font-size: 100px;
                }

                &.small {
                  font-size: 70px;
                }

                position: absolute;
                top: 0;
                left: 0;
                font-size: 100px;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 1;
              }

              .item-content {
                .recent-item * {
                  flex-flow: 1 1 auto;
                }

                .recent-image {
                  object-fit: contain;
                  width: 32px;
                  height: 32px;
                }

                &.items-center {
                  align-items: center;
                }

                &>div {
                  display: flex;
                  flex-flow: row;
                }

                min-height: 137px;
                padding: 5px;
                position: relative;
                z-index: 2;
                flex: 1;
                display: flex;
                flex-flow: column;
                box-shadow: 0 20px 20px 0 #f9f9f9;

                label {
                  margin: 20px 10px;
                  color: var(--secondary);
                  font-size: 15px;
                  font-weight: bold;
                }

                .item-label {
                  align-items: center !important;
                  color: var(--secondary) !important;
                  font-size: 15px !important;
                  font-weight: bold !important;
                  cursor: pointer !important;
                }

                .inner-item {
                  padding: 10px;
                  flex: 1;
                  text-overflow: ellipsis;
                  display: flex;
                  justify-content: flex-start;
                  align-items: flex-start;
                  color: var(--primary);
                  font-weight: normal;
                  font-size: 14px;

                  &.inner-item-recent {
                    text-align: left;
                    align-items: flex-start;
                    justify-content: flex-start;
                  }

                  &:hover {
                    font-size: 14px;
                    color: var(--secondary);
                    transform: translateX(5px);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 600px) {
  .nav-children>.nav-children-item {
    max-width: 33.3% !important;
  }
}
