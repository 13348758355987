.tab-printers {
  .printer-type {
    text-align: left;
    margin-bottom: 30px;
  }

  .printers {
    text-align: center;
  }

  .select-advanced.multiselect {
    width: 100%;

    .select-checkboxes-outer {
      width: 100%;

      .upper-area {
        width: 100%;
      }
    }
  }

  .printer-box {
    position: relative;
    width: 400px;
    display: inline-block;
    border: 1px solid #f2f2f2;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 var(--secondary-tone-4);

    .select-advanced {
      .upper-area {
        border: none;
        border-top: 1px solid #d5d9df;
      }
    }

    .remove-printer {
      position: absolute;
      right: 15px;
      top: 15px;
      color: var(--secondary);
      height: 19px;
      width: 19px;
      text-align: center;
      cursor: pointer;

      > span {
        display: inline-block;
        font-size: 12px;
      }
    }

    > div.conditional {
      padding: 60px 15px;

      p {
        color: var(--secondary);
        font-size: 16px;
        margin: 30px 0 0;
      }
    }
  }
}
