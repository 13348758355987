.heading-wrapper {
  float: left;
  width: 200px;
}

.duplicate-event {
  .modal-body {
    padding-bottom: 0;
  }

  .form-container {
    .subtitle {
      display: none;
    }

    .field-type.type-datetime label {
      display: none;
    }

    .form-group {
      margin-bottom: 0;
      padding-left: 0;
      padding-right: 0;

      &.submit-container {
        margin-bottom: 0;
      }
    }
  }
}

.modal {
  &.delete-event {
    .modal-body {
      > p:nth-child(3) {
        margin-bottom: 40px;
      }

      .form-group {
        margin: 0;
      }
    }
  }
}

.main-list-events {
  .kmb-name {
    cursor: pointer;

    &:hover {
      color: var(--secondary);
    }
  }

  .kmb-actionButtons {
    display: flex;

    & > div {
      display: flex;
      gap: 12px;
      height: 30px;
    }
  }
}

.actions-container {
  text-align: right;
  margin-left: 200px;

  .listing-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;

    > div {
      display: inline-block;
      padding-right: 30px;

      &:last-child {
        padding-right: 0;
      }
    }
  }

  .switch-view {
    span {
      cursor: pointer;
      color: #d5d9df;
      font-size: 18px;
      position: relative;
      top: 5px;

      &:first-child {
        padding-right: 15px;
      }

      &.selected {
        color: #96a1b0;
      }
    }
  }

  .search-button {
    > span {
      cursor: pointer;
      font-size: 20px;
      color: #d5d9df;
      position: relative;
      top: 5px;
    }
  }

  .search-input {
    max-width: 400px;

    input {
      border-radius: 0;
    }
  }
}

.list-events {
  height: calc(100vh - 56px);
  flex: 1;
  position: relative;

  .status-indicator {
    text-transform: capitalize;
    background-color: #f6f6f6;
    padding: 8px;
    border-radius: 44px;
    width: max-content;
    color: #393f48;

    &.running {
      &::before {
        background-color: var(--error-color);
      }
    }

    &.completed {
      &::before {
        background-color: #1eefb0;
      }
    }

    &.upcoming {
      &::before {
        background-color: var(--secondary);
      }
    }

    &::before {
      content: "";
      width: 10px;
      height: 10px;
      border-radius: 50%;
      display: inline-block;
      margin-right: 5px;
      background-color: #ddd;
    }
  }

  .table {
    th,
    td {
      &.kmb-title {
        width: 320px;
      }

      &.kmb-mainBanner {
        width: 90px;
      }
    }

    td {
      &.kmb-id,
      &.kmb-name {
        font-weight: 500;
      }
    }

    tr:hover {
      .event-url {
        &:hover {
          border: 1px solid var(--secondary);
          color: var(--secondary);
        }
      }
    }
  }

  .date-wrapper {
    color: var(--primary);

    p {
      margin-bottom: 2px;
    }
  }

  .sort-dropdown {
    width: 200px;

    .dropdown {
      border-bottom: 1px solid #ddd;
    }

    .dropdown-menu {
      width: 100%;
      padding: 0;
      top: calc(100% + 5px);
      text-align: right;

      > li > a {
        padding: 12px 20px;

        &:hover {
          background-color: #eee;
        }
      }
    }
  }

  .kmb-status,
  .kmb-startDate,
  .kmb-endDate {
    white-space: nowrap;
  }

  .event-url {
    border: 1px solid #d5d9df;
    color: #96a1b0;
    border-radius: 4px;
    text-align: center;
    font-size: 14px;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;

    &:hover {
      color: var(--secondary);
      border: 1px solid var(--secondary);
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .event-name {
    color: var(--primary);
    font-size: 14px;
    max-width: 460px;
    display: inline-block;
  }

  .kmb-banners {
    width: 60px;
    max-width: 60px;
    min-width: 60px;
  }

  .event-banner-in-listing {
    overflow: hidden;

    img {
      width: 36px;
      height: 36px;
      border-radius: 4px;
      object-fit: cover;
    }
  }
}
