.edit-menu {
  .section-basicinfo,
  .section-options,
  .section-clientpanel:not(.Footer.Sponsors):not(.Videos.Sponsors):not(
      .eBag.List
    ),
  .section-fields,
  .section-registrationsettings,
  .section-schedulesettings,
  .section-judges,
  .section-schemas,
  .section-hooks:not(.innerHook),
  .section-emailsettings,
  .section-statistics,
  .section-charts,
  .section-peak,
  .section-paymentoptions,
  .section-digitalcategories,
  .section-digitalslots,
  .section-certprinting,
  .section-printers,
  .section-cardprinting,
  .section-scanningbanner,
  .section-api {
    .top-menu::before {
      content: "";
      position: absolute;
      bottom: -22px;
      left: 0;
      width: 100%;
      height: 21px;
      pointer-events: none;
      z-index: 0;
      background: rgb(210, 210, 217);
      background: linear-gradient(
        180deg,
        rgba(210, 210, 217, 1) 0%,
        rgba(240, 240, 244, 0) 100%
      );
    }
  }

  .top-menu {
    position: relative;

    height: 52px;

    & > div {
      display: flex;
      align-items: center;
      height: 52px;
      padding: 0px 24px;
      border-top-left-radius: 12px;
      background: white;
    }

    border-color: var(--background-content);
    background-color: var(--event-color);
    border-bottom-style: solid;
    border-width: 1px 0 1px;
    position: relative;
    z-index: 3;

    .left-tab {
      margin-right: 15px;

      & > div {
        width: 200px;
      }

      white-space: nowrap;
      background-color: white;
      z-index: 1;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-weight: 500;
      color: var(--black-tone-1);
    }

    .event-submenu-wrapper {
      overflow: hidden;
      height: 52px;
    }

    ul.event-submenu {
      align-items: center;
      list-style-type: none;
      height: 68px;
      display: flex;
      gap: 8px;
      justify-content: flex-start;
      overflow: auto;
      margin: 0px;
      padding: 0px;
      padding-top: 15px;

      li {
        display: inline-block;
        position: relative;
        height: 100%;
        padding: 0px 12px;

        &::after {
          content: "";
          display: block;
          width: 100%;
          left: 0;
          height: 3px;
          border-radius: 100px;
          background-color: var(--secondary);
          position: absolute;
          top: 34px;
          transform: scaleX(0);
          transition: transform 0.2s;
        }

        & button {
          font-weight: 500;
        }

        &:not(.active):hover {
          button {
            color: var(--secondary);
          }
        }

        &.active {
          button {
            color: var(--secondary);
          }

          &::after {
            transform: scaleX(1);
          }
        }
      }

      button {
        color: var(--event-menu-not-focused-text);
        font-size: 15px;
        font-weight: 400;
        line-height: 20px;

        &:hover,
        &:active,
        &:focus {
          color: #292929;
        }
      }
    }
  }

  .tab-items {
    padding: 0;
    position: relative;
    transition: all ease 0.2s;

    .btn > span {
      padding-left: 0px;
    }

    .header-burger button {
      flex-direction: row-reverse;
      gap: 18px;
      justify-content: space-between !important;
      padding-right: 16px;
      height: 52px;

      &:not(span) {
        font-size: 14px;
      }
    }

    &.open {
      .close-menu {
        transform: rotateZ(180deg);
      }
    }

    > .subtab {
      display: flex;
      font-size: 11px;
      gap: 12px;
      padding: 5px 0px;
      height: 40px;
      align-items: center;
      justify-content: flex-end;
      white-space: nowrap;
      border-right: 1px;
      border-right: 1px solid var(--background-content);

      .close-menu {
        font-size: 14px;
        cursor: pointer;
        color: var(--primary-tone-2);
        padding: 16px;

        &:hover {
          color: var(--primary);
        }
      }

      p {
        margin: 0px;
        padding-left: 16px;
        padding-right: 18px;

        &.bt {
          font-size: 10px;
        }

        color: var(--primary-tone-3);
      }
    }

    > ul {
      margin: 0px;
      width: calc(100% + 15px);
      margin-right: -15px;
      padding: 0px;
      list-style-type: none;
      height: 100%;
      overflow: auto;

      li {
        & > button {
          border-radius: 0px;
          padding-left: 14px;
          height: 45px;
          color: var(--black-tone-4);
          font-weight: 500;
          width: 100%;
          text-align: left;
          font-size: 14px;
          justify-content: flex-start !important;
        }

        svg {
          width: 20px;
          height: 20px;
          margin-right: 12px;

          path {
            stroke: var(--black-tone-4);
          }
        }

        &.active {
          > button {
            background: #566986;
            border-left: 3px solid white;
            color: white;
            padding-left: 11px;
          }

          svg {
            path {
              stroke: white !important;
            }
          }
        }

        &:not(.active):hover {
          > button {
            color: white;
          }

          svg {
            path {
              stroke: white !important;
            }
          }
        }

        &:last-child {
          margin-bottom: 0;
        }

        ul {
          // second level
          padding: 30px 15px;
          list-style-type: none;

          li {
            > button {
              text-transform: uppercase;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
