.section-communication {
  .tab-hooks {
    .med-table-container {
      padding: 0;
    }

    .med-table-container .kmb-cosmetic {
      width: 77px;
    }

    .kmb-hooks-actions-row {
      margin-bottom: 100px;
      padding: 20px;
      background: rgb(241 233 233 / 18%);
    }

    .kmb-hooks-actions-available-variables {
      color: #777;
    }

    .email-header {
      position: relative;
      padding-bottom: 20px;
    }

    .email-header h4 {
      color: var(--primary);
      font-size: 22px;
      font-weight: 300;
      margin-right: 30px;
    }

    .email-title {
      margin: 0;
      line-height: 1.42857143;
    }

    .email-header span.confirm-holder {
      cursor: pointer;
      position: absolute;
      right: 0;
      top: 0;
      font-size: 12px;
      color: #e8e8e9;
    }

    .form-container {
      position: relative;

      .form-container-header::after {
        content: "";
        display: block;
        clear: both;
      }

      .form-container-header {
        margin: -15px;
      }

      .form-container-header > h3 {
        color: var(--primary);
        font-size: 16px;
        margin-bottom: 50px;

        span {
          font-weight: 400;
        }
      }

      .form-container-header button {
        margin-left: 15px;
      }

      .btn.cancel {
        margin: 0;
        width: auto;
        min-width: 120px;
      }

      .hook-contacts-header::after {
        content: "";
        display: block;
        clear: both;
      }
    }
  }

  .search-wrapper {
    width: 100%;
  }

  .top-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
  }
}

.section-emailsettings {
  .email-sender {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    > span {
      color: var(--primary);
      width: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
    }

    .css-2b097c-container {
      color: var(--primary);
      width: 200px;
    }
  }
}

.section.communication {
  tbody {
    tr {
      height: 72px !important;
    }
  }

  .email-label-column {
    cursor: pointer;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #003253;

    &:hover {
      color: #0079ca;
    }
  }

  .id-column {
    color: #6d82a2;
  }

  .recipients-column {
    display: flex;
    column-gap: 8px;

    button {
      background-color: #e9edf1;
      border-radius: 4px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      color: #354152;
      text-transform: none !important;
      cursor: unset;
    }
  }
}

.section-questionnaires {
  .indexed {
    table {
      th:first-of-type {
        width: 54px;
      }

      th.kmb-name:first-of-type {
        width: 100px !important;
      }
    }
  }
}

.email-settings {
  .tox.tox-tinymce {
    border: 1px solid #dfe4eb;
    border-radius: 4px;
  }

  .email-label {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  .email-sender {
    display: flex;
    width: 100%;

    input {
      background: #ffffff;
      border: 1px solid #dfe4eb;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 6px;
    }

    .email-name {
      width: 65%;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #354152;

      span {
        &.icon {
          display: none;
        }
      }
    }

    .at-container {
      width: 30px;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: #425167;
    }

    .email-domain {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #354152;
      flex: 1;
    }

    input {
      width: 100%;
    }
  }

  .header-switch {
    display: flex;
    column-gap: 10px;
    align-items: center;
    margin-bottom: 16px;

    .header-switch-title {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #354152;
    }
  }

  .banner-container {
    .banner-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background: #e9edf1;
      border-radius: 7px;

      .actions {
        width: 100%;
        display: flex;
        justify-content: flex-end;

        > div {
          margin-right: 4px;
          margin-bottom: 4px;
        }
      }
    }

    img {
      margin-top: 24px;
      max-height: 300px;
    }

    margin-bottom: 16px;
  }
}
