.abstract-filters {
  display: flex;

  .select-advanced .bottom-area {
    min-width: max-content;
  }

  .search-wrapper {
    display: block;

    .search-input > span {
      top: 14px;
    }

    .form-control {
      height: 42px;
      border: 1px solid #d5d9df;
      border-radius: 4px !important;
    }
  }
}

.med-table-container {
  &.bulk-unrate {
    min-width: 210px;
  }
}

.tab-singleabstract {
  h2 {
    padding: 0 0 15px;
  }

  .tab-title {
    display: none;
  }

  .title {
    > p {
      font-size: 14px;
    }
  }

  .statistics {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;

    & > div {
      flex: 1;
      min-width: 200px;
    }
  }

  .flex-group {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;

    &.extras {
      & > div {
        flex-basis: calc(50% - 5px);
        justify-content: flex-end;
        display: flex;
        flex-direction: column;
      }
    }

    & > div {
      flex: 1;
      min-width: 200px;
    }
  }

  .author-item {
    margin-bottom: 5px;
    line-height: 1;

    & > div > input {
      min-height: 38px;
      padding: 6px 12px;
    }

    .author-institute-select {
      .select-institute {
        margin-right: 25px;

        input {
          position: absolute;
          height: 0px;
        }
      }

      .remove-item {
        color: var(--warning);
        position: absolute;
        right: 15px;
        font-size: 16px;
        cursor: pointer;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .institutes-container {
    display: flex;
    justify-content: center;
    align-items: center;

    .med-table-container {
      max-width: 100%;
      background-color: #fff;
      padding: 0px;

      table tbody > tr > td {
        padding: 2px 1px;

        input {
          padding: 6px 12px;
        }
      }

      .table > tbody > tr > td,
      .table > tbody > tr > th,
      .table > tfoot > tr > td,
      .table > tfoot > tr > th,
      .table > thead > tr > td,
      .table > thead > tr > th {
        border-top: none;
      }
    }

    .kmb-name {
      input {
        height: 40px;
        width: 100%;
        border: 1px solid #dae4f0 !important;
        color: var(--primary);
        outline: none !important;
        border-radius: 4px;
        padding: 10px;
      }
    }

    .kmb-remove {
      width: 23px;

      .remove-item {
        color: var(--warning);
        display: flex;
        justify-content: flex-end;
        font-size: 16px;
        align-items: center;
      }
    }
  }

  .extra-fields {
    > div > div {
      margin: 0 0 15px;

      &:last-child {
        margin: 0;
      }
    }

    h4 {
      color: #153447;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 13px;
    }

    p {
      color: var(--secondary-tone-4);
      font-size: 14px;
      margin-bottom: 0;
    }
  }

  .topics {
    p {
      display: inline-block;
      background-color: #57aeff;
      padding: 3px 10px;
      color: #fff;
      margin: 2px;
      font-size: 12px;
      border-radius: 2px;
      border: 1px solid #21a4cf;
    }
  }

  .box {
    margin: 25px 0;

    label {
      color: var(--secondary);
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 15px;
    }

    > div {
      padding: 15px;
      box-shadow: 0 2px 4px 0 #ebeff3;
    }
  }
}

.modal.rate-abstract {
  &.bulk-judge-rate {
    h5 {
      margin-top: 0;
    }
  }

  &.bulk-unrate,
  &.bulk-judge-rate {
    .form-container {
      margin-top: 35px;
    }
  }

  .modal-header {
    padding-bottom: 0;
    border: none;
  }

  table {
    margin-bottom: 0;

    tbody > tr > td {
      padding: 10px;
    }
  }

  h5 {
    font-size: 16px;
    margin: 24px 0;
    font-weight: 300;
  }

  button.btn {
    width: 100%;

    &.disabled {
      pointer-events: none;
    }
  }

  input {
    margin-top: 15px;
  }

  span {
    font-weight: 400;
    font-size: 14px;
  }

  .alert {
    margin: 10px 0 0;
    padding: 5px 15px;
    font-size: 12px;
  }
}

.published-abstracts-table {
  padding: 0px !important;

  .form-control {
    margin: 0px;
  }

  .table {
    display: block;
    width: 100%;

    thead,
    tbody,
    tr {
      display: block;
      width: 100%;
      padding: 5px 15px 5px 5px;
      margin-bottom: 5px;
    }

    tbody {
      max-height: 400px;
      overflow: auto;
    }

    th,
    td {
      display: inline-block;
    }

    th:first-child,
    td:first-child {
      width: 10%;
    }

    th:last-child,
    td:last-child {
      width: 90%;
    }
  }
}

.med-table-container[role="dialog"] {
  padding: 0;

  .modal-content {
    max-height: 90vh;
    overflow: auto;
  }
}

.section-abstractslist {
  .table {
    .kmb-name {
      max-width: 475px;
      min-width: 300px;
    }

    .kmb-checkbox {
      width: 36px;
    }
  }

  .rate-final {
    display: flex;
    justify-content: center;
    align-items: center;

    & button {
      padding: 5px 10px !important;
      margin-left: 15px;
      min-width: 65px;
    }
  }

  .final-score {
    padding-top: 2px;
    width: 36px;
    height: 36px;
    min-width: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    border-radius: 100%;
    color: white;
    background: #b1b1b1;
  }

  .minified-font-size {
    font-size: 14px;
  }

  .score-approved {
    background: #14da9e;
  }

  .score-rejected {
    background: var(--error-color);
  }

  .finalize {
    margin: 25px 0 0;

    .btn {
      width: auto;
      margin-bottom: 15px;
    }
  }

  .all-actions {
    margin-top: 60px;
    margin-bottom: 30px;

    & > div {
      width: 340px;
    }
  }

  .tab-header-filters {
    position: sticky;
    left: 0px;
    margin-bottom: 0px;

    th,
    td {
      text-align: center;
    }

    > .med-table-container {
      padding: 0;

      th {
        text-align: center;
      }

      td {
        text-align: center;
        font-size: 15px;
      }
    }
  }

  .med-table-container table tbody {
    > tr {
      cursor: pointer;

      &.rejected,
      &.approved {
        .kmb-cosmetic {
          > span {
            display: block;
            font-size: 14px;
            padding: 8px;
            border-radius: 50%;
            color: white;
            text-align: center;
            background-color: var(--error-color);

            &::before {
              top: 3px;
            }
          }
        }
      }

      &.approved {
        .kmb-cosmetic > span {
          background-color: #14da9e;
        }
      }
    }
  }

  .kmb-score {
    p {
      font-weight: 700;
      font-size: 16px;
    }

    text-align: center;

    span {
      font-weight: 400;
      margin-top: 15px;
      display: block;
      color: #31a783;
      font-size: 11px;
    }
  }

  .kmb-judging {
    table {
      border-spacing: 0 2px;

      th {
        padding: 0px 5px;
        font-size: 11px;

        &:last-child {
          padding-right: 0;
        }
      }

      td {
        padding: 0px 5px;
        font-size: 11px;
      }
    }
  }
}

.tab-schemas {
  position: relative;

  .header {
    .react-selectrix {
      display: inline-block;
      margin-left: 15px;
    }
  }

  label {
    > span {
      font-weight: 300;
      cursor: pointer;

      &:first-child,
      &:last-child {
        margin-right: 5px;
        margin-left: 5px;
      }

      &.selected {
        font-weight: 700;
        border-bottom: 1px dashed;
      }
    }
  }

  h2.tab-title {
    display: inline-block;
  }

  .form-container .form-group input#searchable {
    height: auto;
    border: none;
  }

  .form-container {
    > div > .btn {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .boolean-buttons {
    margin-bottom: 25px;
  }

  .select-advanced {
    margin-bottom: 25px;
  }

  .extra-fields .table > tbody > tr > td {
    text-align: left;
  }
}

@media (min-width: 1200px) {
  .tab-schemas {
    .wrapper,
    .form-container > div > .select-advanced {
      padding-right: 15px;
      max-width: 50%;
    }
  }
}

@media (max-width: 767px) {
  .author-item {
    margin-bottom: 15px !important;

    input {
      margin: 1px 0px;
    }
  }

  .author-header {
    display: none;
  }
}
