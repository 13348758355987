.edit-event {
  .main-grid {
    padding: 0;

    &>div.row {
      margin: 0;
    }

    &.vertical {
      >.row {
        flex-wrap: wrap;

        >div {
          width: 100%;
        }
      }

      .tab-items {
        ul {
          margin: 0;
          padding: 0;
          height: auto;
          border: none;

          li {
            margin: 0;
            display: inline-block;
            padding: 15px 25px;
          }
        }
      }
    }
  }

  .breadcrumb {
    margin: 0 0 15px;
    padding: 5px;
    display: inline-block;
    background-color: #f2f7fc;
    color: var(--secondary);
    font-size: 14px;
    text-transform: capitalize;
  }

  .top-bar {
    background-color: var(--event-color);
    max-height: 55px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    gap: 15px;
    padding: 12px 16px;
    z-index: 10;

    .left {
      flex: 1;
      min-width: 0;
      min-width: 30px;
    }

    .right {
      min-width: 430px;
    }

    .left,
    .right {
      gap: 12px;
      display: flex;
      align-items: center;
    }

    .banner {
      width: 30px;
      min-width: 30px;
      max-height: 30px;
      overflow: hidden;
      float: left;

      img {
        height: 32px;
        width: 32px;
        border-radius: 4px;
        border: 1px solid #495a72;
        object-fit: cover;
      }
    }

    .flags {
      cursor: default;
      display: flex;
      align-items: center;
      gap: 12px;

      div {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        white-space: nowrap;
        gap: 5px;
        border-radius: 16px;
        background-color: var(--primary-tone-4);
        padding: 5px 12px;
      }

      .status-indicator {
        &.upcoming {
          background-color: var(--event-status-upcoming);
        }

        &.running {
          background-color: var(--event-status-running);
        }

        &.completed {
          background-color: var(--event-status-completed);
        }
      }
    }

    .info {
      margin-left: 68px;
      padding-left: 10px;
      width: auto;
      overflow: hidden;
    }

    .title {
      width: calc(100% - 48px);

      h1 {
        width: 100%;
        color: white;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        margin: 0;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    .right-side {
      text-align: right;

      .wrapper {
        padding-top: 32px;

        >div:not(.actions) {
          display: inline-block;
        }

        padding-right: 100px;
      }
    }

    .actions {
      border-radius: 4px;
      background-color: #f2f7fc;
      font-size: 35px;
      color: #a7b7ce;
      position: absolute;
      right: 15px;
      top: 28px;
      height: 25px;
      width: 34px;
      cursor: pointer;

      >.dropdown {
        height: 100%;

        .handle {
          >span {
            display: block;
            height: 100%;

            &::before {
              top: -6px;
              position: relative;
            }
          }

          &.in {
            >span {
              color: var(--secondary);
            }
          }
        }

        >.dropdown-menu {
          box-shadow: 0 6px 12px 0 rgb(82 97 115 / 50%);
          background-color: white;
          left: auto;
          right: 0;
          top: 25px;
          padding: 0;
          min-width: 160px;

          &::before {
            content: "";
            width: 0;
            height: 0;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-bottom: 8px solid white;
            position: absolute;
            top: -8px;
            right: 10px;
            z-index: 99999999;
          }

          >li {
            color: var(--primary);
            padding: 12px 20px;

            &:hover {
              background-color: #eee;
            }

            &:last-child {
              margin-bottom: 0;
              padding: 0;

              >div>div {
                &:first-child {
                  padding: 12px 20px;
                }
              }

              span {
                color: $red;
              }
            }

            margin-bottom: 0;

            span {
              color: var(--secondary);
              font-size: 18px;
              position: relative;
              top: 3px;
              padding-right: 10px;
            }
          }
        }
      }
    }

    .date {
      margin-right: 30px;
    }

    .date,
    .event-id,
    .status {
      text-align: left;

      span {
        float: left;
        width: 25px;
        color: var(--secondary);
        font-size: 17px;
      }

      p {
        margin-left: 25px;
        width: auto;
        overflow: hidden;
        color: #96a1b0;
      }
    }
  }

  .section {
    h2.tab-title {
      padding: 0;
      margin-bottom: 24px;
    }

    .tab-options {
      .form-group {
        margin: 0;
        padding: 12px;
        margin-bottom: 15px;
        display: flex;
        align-items: center;

        &>div:not(.help-block) {
          flex: 1;
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }

    .image-container {
      background-color: white;
      overflow: hidden;
      position: relative;
      top: 0;
      left: 0;
      color: white !important;
      width: 100%;
      height: 100%;

      .delete-cover {
        &:hover {
          text-decoration: underline;
        }

        cursor: pointer;
        position: relative;
        bottom: 20%;
        text-align: center;
        z-index: 3;
      }

      .back-side {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 0;

        img {
          object-fit: contain;
          width: 100%;
          height: 100%;
        }
      }

      .front-side {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1;
      }

      /* text-align: center;

      h2.tab-title {
        display: none;
      }
      .kmb-dropzone{
        .vertical-center{
          padding-top:0px !important;
        }
      }
      > div.existing-cover {
        display: inline-block;
        text-align: center;
        position: relative;
        width: 450px;

        .back-side {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
          opacity: 0;

          .delete-cover {
            color: white;

            p {
              display: inline-block;
              cursor: pointer;
            }

            span {
              padding-right: 5px;
            }
          }

          .kmb-dropzone {

            .vertical-center {
              padding-top: 0px !important;
            }

            .prompt-message,
            .details,
            .details span,
            .file-info {
              color: white;
            }

            .text-area {
              span.icon {
                font-size: 50px;
                color: white;
              }
            }
          }
        }

        img {
          max-width: 100%;
          height: auto;
        }

        &::after {
          content: "";
          position: absolute;
          width: 100%;
          left: 0;
          top: 0;
          height: 100%;
          opacity: 0;
          transition: opacity 0.1s ease-in-out;
          background-image: -webkit-linear-gradient(bottom left, #0a233d 10%, rgba(39, 149, 255, 0.6) 100%);
          background-image: -o-linear-gradient(bottom left, #0a233d 10%, rgba(39, 149, 255, 0.6) 100%);
          background-image: linear-gradient(to top right, #0a233d 10%, rgba(39, 149, 255, 0.6) 100%);
        }

        &:hover,
        &.hovered {
          &::after,
          .back-side {
            opacity: 1;
          }
        }
      }
      */
    }

    .tab-cover,
    .tab-scanningbanner {
      text-align: center;

      h2.tab-title {
        display: none;
      }

      >div.existing-cover {
        text-align: center;
        min-height: 400px;

        .back-side {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
          opacity: 0;

          .delete-cover {
            color: white;

            p {
              display: inline-block;
              cursor: pointer;
            }

            span {
              padding-right: 5px;
            }
          }

          .kmb-dropzone {
            padding: 25px;

            .vertical-center {
              padding-top: 0;
            }

            .prompt-message,
            .details,
            .details span,
            .file-info {
              color: white;
            }

            .text-area {
              span.icon {
                font-size: 50px;
                color: white;
              }
            }
          }
        }

        img {
          max-width: 100%;
          height: auto;
        }

        &::after {
          content: "";
          position: absolute;
          width: 100%;
          left: 0;
          top: 0;
          height: 100%;
          opacity: 0;
          transition: opacity 0.1s ease-in-out;
          background-image: linear-gradient(to bottom left,
              #0a233d 10%,
              rgb(39 149 255 / 60%) 100%);
          background-image: linear-gradient(to bottom left,
              #0a233d 10%,
              rgb(39 149 255 / 60%) 100%);
          background-image: linear-gradient(to top right,
              #0a233d 10%,
              rgb(39 149 255 / 60%) 100%);
        }

        &:hover,
        &.hovered {

          &::after,
          .back-side {
            opacity: 1;
          }
        }
      }
    }
  }
}
