.select-advanced .bottom-area {
  .checkbox-wrapper {
    &.policy-checkboxes {
      transform: scale(0.8);
      transform-origin: 0 0;
      margin-bottom: 5px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
