.kmb-treeview {
  ul {
    padding-left: 25px;
    overflow: hidden;
    max-height: auto;
    margin: 0;
  }

  ul li {
    list-style: none;
    padding-bottom: 7px;
  }

  .kmb-tree-collapsed {
    max-height: 0;
  }

  .no-pointer-events {
    pointer-events: none;
  }

  .inline-block-wrapper {
    display: inline-block;
  }

  .icon-arrow-down-small,
  .icon-arrow-right-small {
    color: var(--primary);
    font-size: 10px;
    padding-left: 5px;
  }
}
