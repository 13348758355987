.my-account {
  h2 {
    margin-bottom: 25px;
  }

  .main-wrapper {
    border: 1px solid #f2f2f2;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 #ebeff3;
    padding: 25px;

    .form-container {
      gap: 12px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .form-group {
        margin-bottom: 0;
        background: #fff;
        padding: 12px;
        border-radius: 5px;
        flex: 1;
        width: 33%;
        min-width: 400px;

        & .med-table-container {
          min-width: calc(100vw - 100px);
        }

        &.submit-container {
          min-width: 100%;
        }
      }
    }
  }

  input.disabled {
    background: #f7f7f7;
  }
}
