.client-builder-wrapper {
  display: flex;
  height: 100%;
  width: 100%;
  background-color: var(--event-color);
  display: flex;

  .control-panel {
    border-top-left-radius: 12px;
    background-color: white;
    width: 252px;
    height: 100%;
    box-shadow: 0px 12px 16px -4px rgb(16 24 40 / 10%),
      0px 4px 6px -2px rgb(16 24 40 / 5%);
    z-index: 4;
    overflow-y: auto;

    .panel-section {
      padding: 16px;
      display: flex;
      flex-direction: column;
      row-gap: 4px;

      .panel-title {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #8c9db6;
        margin-bottom: 8px;
      }

      &:not(:last-child) {
        border-bottom: 1px solid #e4e7ec;
      }

      .panel-item {
        height: 36px;
        width: 100%;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #005a97;
        display: flex;
        align-items: center;
        column-gap: 12px;
        border-radius: 6px;
        cursor: pointer;

        .panel-item-icon {
          width: 20px;

          path {
            stroke: #0098fd !important;
          }
        }

        &.active {
          background-color: #e9edf1;
          color: #354152;

          .panel-item-icon {
            path {
              stroke: #566986 !important;
            }
          }
        }

        &:hover {
          color: #354152;

          .panel-item-icon {
            path {
              stroke: #566986 !important;
            }
          }
        }

        .indicator-wrapper {
          width: 20px;
          display: flex;
          justify-content: center;
          align-items: center;

          .indicator {
            border-radius: 50%;
            width: 8px;
            height: 8px;
            background: #f97066;

            &.enabled {
              background: #12b76a;
            }
          }
        }
      }
    }
  }

  .panel-content {
    width: 100%;
    height: 100%;
    background-color: #f0f0f4;

    .top-menu {
      display: flex;
      background-color: white;
      justify-content: space-between;

      .create {
        margin: 8px 24px;
        height: 36px;
      }

      .MuiTabs-root {
        border-top-left-radius: 0px;
      }

      .MuiTab-root {
        min-width: 100px;
      }

      .MuiTab-textColorPrimary.Mui-selected {
        color: #0079ca;
      }

      .MuiTabs-indicator {
        background-color: #0079ca;
        min-width: 100px;
      }

      .MuiTab-wrapper {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        text-transform: none;
      }
    }

    .submit-panel {
      width: 100%;
      height: 10px;
      background-color: red;
    }

    .panel-content-wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow-y: auto;

      .panel-content-card {
        height: max-content;
        margin: 24px;
        padding: 24px;
        background-color: white;
        border-radius: 4px;

        .submit-container {
          margin: 24px 0px 0px;
          column-gap: 12px;
        }

        &.abstract-panel,
        &.exhibition-panel {
          .switch-toggle-handle {
            transform: translate3d(12px, 2px, 0px) !important;
            height: 12px;
          }
        }
      }

      .access-language-content {
        .field-section {
          width: 100%;
          height: max-content;
          display: flex;
          justify-content: space-between;

          .field {
            width: 40%;
            min-width: 100px;
            margin-bottom: 36px;

            label {
              width: 100%;
              margin-bottom: 6px;
              color: #27303e;
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 18px;
            }

            .access-language-select {
              width: 100%;
            }

            .url-generator {
              position: relative;

              .field-type.type-text.kmb-text {
                span {
                  display: none;
                }

                input {
                  box-shadow: none !important;
                }
              }

              button {
                position: absolute;
                right: 0px;
                top: 0px;
                height: 100%;
              }

              .copy-action-icon {
                position: absolute;
                right: 12px;
                top: 12px;
                cursor: pointer;

                &.open,
                &:hover {
                  path {
                    stroke: #566986;
                  }
                }

                &.disabled {
                  path {
                    stroke: #bfc7d1;
                  }

                  &:hover {
                    path {
                      stroke: #bfc7d1;
                    }
                  }
                }
              }

              .link-copy-option {
                color: #354152;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                height: 40px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                column-gap: 12px;
              }
            }
          }

          .description {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #425167;
            width: 52%;
            min-width: 100px;
            margin-top: 24px;
          }
        }
      }

      .danger-zone {
        margin: 0px 24px 24px;
        background-color: white;
      }

      .branding-content {
        display: flex;
        flex-direction: column;
        row-gap: 30px;

        .event-color-picker-wrapper {
          width: 100%;
          display: flex;
          flex-direction: column;
          row-gap: 6px;

          .color-picker-title {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            color: #27303e;
          }

          .color-picker-container {
            display: flex;
            justify-content: space-between;
            padding: 10px 14px;
            border: 1px solid #abb8c9;
            border-radius: 6px;
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
            align-items: center;

            .color-selector {
              display: flex;
              align-items: center;
              column-gap: 10px;
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
              color: #354152;
              cursor: pointer;

              &:hover {
                color: #366bb6;
              }

              .color-indicator {
                width: 16px;
                height: 16px;
                border-radius: 2px;
                z-index: 1;
                position: relative;
                cursor: pointer;

                .chrome-picker {
                  z-index: 2;
                  position: absolute;
                  top: 20px;
                  left: 0px;
                }

                .backdrop {
                  z-index: 1;
                  position: fixed;
                  width: 100vw;
                  height: 100vh;
                  top: 0px;
                  left: 0px;
                }
              }
            }

            .reset-color-button {
              display: flex;
              align-items: center;
              justify-content: center;
              column-gap: 10px;
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
              color: #0079ca;
              cursor: pointer;

              &:hover {
                text-decoration: underline;
                color: #005b97;
              }
            }
          }
        }

        .preview-colors {
          display: flex;
          flex-direction: column;
          row-gap: 16px;

          .preview-title {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            color: #27303e;
          }

          .preview-shades {
            display: flex;
            align-items: center;
            column-gap: 8px;

            .preview-indicator {
              width: 16px;
              height: 16px;
              border-radius: 2px;
            }
          }
        }

        .logo-picker-wrapper {
          .image-container {
            border: 1px dashed #97d5ff;

            .back-side {
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: #e9edf1;

              img {
                width: max-content;
                object-fit: contain;
                max-height: 100px;
                border: 1px dashed rgb(218, 228, 240);
              }
            }
          }
        }
      }

      .footer-content {
        display: flex;
        flex-direction: column;
        row-gap: 36px;

        &.sponsors {
          background-color: transparent;
          padding: 0px;
        }

        .switch-holder {
          display: flex;
          align-items: center;
          column-gap: 16px;

          .check-box {
            height: max-content;
            width: max-content;
          }

          div {
            height: 16px;
          }
        }
      }

      .enable-reorder-content {
        &.enable-reorder {
          background-color: transparent;
          padding: 0px;
        }

        thead {
          display: none;
        }

        .page-controls {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #003253;
          height: 36px;
          margin-left: 24px;

          .page-label {
            &.home-label {
              padding-left: 50px;
            }
          }

          .page-toggle {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 12px;

            &.disabled {
              .react-switch-bg {
                background-color: #f97066 !important;
              }
            }
          }
        }
      }
    }

    .pages-wrapper {
      overflow: hidden;
      height: calc(100%);
      display: flex;
      flex-direction: column;

      .configuration-placeholder {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 26px;
        color: darkgrey;
      }

      .main-content-container,
      .speakers-container,
      .sponsors-container,
      .ebag-container,
      .video-sponsor-container {
        position: relative;
        box-shadow: var(--header-box-shadow);
        padding: 24px;
        flex: 1 1;
        overflow: auto;

        .sponsors-types-container {
          margin-right: 0px;
          margin-left: 0px;
          margin-bottom: 24px;
        }

        .sponsors-list {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          position: relative;
          overflow: auto;
          max-width: 100%;
          gap: 15px;

          .independent-table-container {
            label {
              top: 0px;
            }

            .med-table-container {
              padding-top: 26px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1450px) {
  .client-builder-wrapper {
    .field-section {
      flex-direction: column;

      .field {
        width: 100% !important;
        margin-bottom: 12px !important;
      }

      .description {
        width: 100% !important;
        margin-top: 0px !important;
        margin-bottom: 48px !important;
      }
    }
  }
}
