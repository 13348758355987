.notifications-area {
  h2 {
    margin-bottom: 20px;
  }

  .localstorage-errors {
    margin-bottom: 10px;
    border: 1px solid var(--toastify-color-info);
    border-radius: 4px;
    padding: 20px;
    background: #e4e9e8;
    color: var(--toastify-color-info);
  }
}

.notification-box {
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgb(225 225 225 / 50%);
  margin-bottom: 25px;
  padding: 15px;
  position: relative;
  transition: all 0.2s ease-in-out;

  &.viewed {
    opacity: 0.8;
  }

  &.dismissing {
    transform: translateX(-500px);
  }

  &.type-danger {
    .title span {
      color: var(--error-color);
    }
  }

  .title {
    padding-right: 45px;
    color: var(--primary);

    span {
      position: relative;
      top: 2px;
      padding-right: 8px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .notification-header {
    margin-bottom: 10px;
  }

  .notification-message {
    color: #96a1b0;
    font-size: 12px;
    margin-bottom: 20px;
  }

  .notification-actions {
    .dismiss {
      button.btn {
        border: 1px solid #e8e8e9;

        &.disabled {
          pointer-events: none;
        }

        &:not(.disabled) {
          &:hover {
            background-color: #ddd;
          }
        }
      }
    }

    .resolve {
      button.btn {
        background-color: #e8e8e9;

        &.disabled {
          pointer-events: none;
        }

        &:hover {
          background-color: #ddd;
        }
      }
    }

    button.btn {
      width: 100%;
      border-radius: 2px;
      font-size: 12px;
      text-transform: uppercase;
      padding: 5px;
      font-weight: 500;
      color: #96a1b0;
    }
  }

  .date {
    color: #a7b7ce;
    font-size: 11px;
    font-weight: 500;
    line-height: 16px;
    text-transform: uppercase;
    margin-top: 10px;
  }
}
