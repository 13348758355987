.assign-roles {
  .kmb-role {
    .select-checkboxes-outer,
    .upper-area {
      width: 100%;
    }
  }

  .heading-wrapper {
    float: none;
    width: 100%;

    h2 {
      a {
        color: var(--primary);
      }

      span {
        font-weight: 300;
      }
    }
  }
}

.page-list-crm,
.page-assign-roles,
.page-list-persons,
.page-list-staff {
  .list-view {
    > div {
      padding: 0;
    }
  }

  > main {
    background-color: #fff;
  }
}

.kmb-role {
  > .select-advanced {
    width: 170px;
  }
}

.med-table-container table tbody > tr .kmb-cosmetic {
  .person-thumbnail {
    width: 35px;
    display: block;
    position: relative;

    img {
      display: block;
      border-radius: 50%;
    }
  }
}
