img {
  max-width: 100%;
  height: auto;
}

.vertical-center {
  white-space: nowrap;
}

.vertical-center::before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -0.25em;
}

.vertical-center > * {
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
}
