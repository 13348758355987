.indexed {
  table {
    th:first-of-type {
      width: 54px;
    }

    th.kmb-name:first-of-type {
      width: 100px !important;
    }
  }
}

.section-questionnaires {
  .indexed {
    table {
      th:first-of-type {
        width: 54px;
      }

      th.kmb-name:first-of-type {
        width: 100px !important;
      }
    }
  }
}

.med-table-container {
  padding: 0px;
  min-width: 100%;
  width: max-content;
  max-width: 490px;

  &.actions-pinned {
    table thead th.kmb-actions::after {
      pointer-events: none;
      content: "";
      position: absolute;
      top: 0;
      left: -77px;
      width: 77px;
      height: calc(100% + 8px);
      background: #d2d2d9;
      background: linear-gradient(270deg, #d7d7e033, rgba(240, 240, 244, 0));
    }

    table tbody td.kmb-actions::after {
      pointer-events: none;
      content: "";
      position: absolute;
      top: 0;
      left: -77px;
      width: 77px;
      height: calc(100% + 8px);
      background: #d2d2d9;
      background: linear-gradient(270deg, #d7d7e033, rgba(240, 240, 244, 0));
    }
  }

  &.table-responsive {
    width: 100%;
    overflow: auto;
  }

  table {
    margin-bottom: 0;
    border-collapse: separate;
    border-spacing: 0px 8px;
    margin-top: -8px;

    thead th {
      white-space: nowrap;
      height: 28px;
    }

    thead > tr {
      background-color: transparent;
    }

    .kmb-stars {
      min-width: 110px;
      text-align: center;
    }

    .kmb-description,
    .kmb-facilities {
      max-width: 200px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      & > * {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .kmb-cosmetic {
      width: 59px !important;
      min-width: 59px !important;
      max-width: 59px !important;
    }

    .kmb-draghandle {
      width: 50px !important;
      min-width: 50px !important;
      max-width: 50px !important;
    }

    td.kmb-id,
    td.hook-id {
      width: 50px;
    }

    td.kmb-name {
      width: 350px;
      overflow-wrap: break-word;
      word-wrap: break-word;
      white-space: normal;
    }

    td.kmb-emailNumber {
      width: 500px;
    }

    td.kmb-email {
      width: 70px;
    }

    td.kmb-lastSent,
    td.kmb-scheduledSent,
    td.kmb-scheduledToSend {
      width: 180px;

      .view-all-scheduled {
        cursor: default;

        &:hover,
        &:active {
          color: #0079ca;
        }

        .table-interactive-backdrop {
          position: fixed;
          height: 100%;
          width: 100%;
          background: transparent;
          top: 0px;
          left: 0px;
          z-index: 11;
          cursor: default;
        }

        .table-interactive {
          z-index: 12;
          height: max-content;
          width: max-content;
          box-shadow: 0 2px 4px 0 #d1d4d8;
          background-color: white;
          position: absolute;
          border-radius: 4px;
          padding: 12px;
          display: flex;
          flex-direction: column;
          row-gap: 2px;
          justify-content: center;
          cursor: default;
          color: black;
          margin-top: 4px;

          .list-view-header {
            font-weight: 600;
          }
        }
      }
    }

    td.kmb-draghandle {
      cursor: move;
      text-align: center;
      color: #96a1b0;
      font-size: 14px;
    }

    > thead > tr > th {
      padding: 4px 12px 5px;
      border: unset;
      color: var(--gray-600);
      font-weight: 500;
      font-size: 12px;

      &.kmb-actions {
        position: sticky;
        right: -24px;
        background: var(--background-content);
      }

      .sortable-th {
        .icon-sort-arrow-down {
          opacity: 0;
        }

        &:hover .icon-sort-arrow-down {
          opacity: 1;
        }

        cursor: pointer;
        display: flex;

        > span {
          padding-left: 5px;
          color: var(--primary-tone-1);
        }
      }

      & span.icon-sort-arrow-down {
        color: var(--tab-header-menu);
        font-size: 10px;
      }

      &.is-active {
        background-color: inherit;

        span.icon-sort-arrow-down {
          opacity: 1;
          font-weight: bold;
          color: var(--primary-tone-1);
        }
      }
    }

    tbody {
      > tr {
        background-color: white;

        &:not(.has-colspan):hover {
          box-shadow: var(--table-hover-shadow);
        }

        > td {
          border: unset;
          vertical-align: middle;
          padding: 12px;
          font-size: 14px;
          color: var(--primary);

          &.kmb-actions {
            position: sticky;
            right: -24px;
            background: white;
            padding: 0px;
          }
        }

        td:first-child {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }

        td:last-child {
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }

        &.has-colspan {
          box-shadow: 0px -10px #fff;
          padding: 24px;

          tr {
            box-shadow: 1px 1px var(--background-content);
          }
        }

        .btn {
          &.table-button-link {
            justify-content: flex-start;
            color: var(--primary);
            text-align: left;

            &:hover {
              color: var(--secondary);
            }
          }

          &.table-button {
            color: #96a1b0;
            border: 1px solid #96a1b0;
            font-size: 12px;
            font-weight: 500;
            padding: 7px 17px 7px 44px;
            position: relative;
            text-transform: uppercase;
            line-height: normal;
            -webkit-transition: all 0.2s ease-in-out;
            transition: all 0.2s ease-in-out;
            background-color: transparent !important;

            &:hover {
              color: var(--secondary);
              border: 1px solid var(--secondary);
            }

            span {
              position: absolute;
              top: 50%;
              left: 10px;
              margin-top: -9px;
              font-size: 17px;
            }

            &.disabled {
              pointer-events: none;
            }
          }

          &.table-button-send {
            color: #14da9e;
            border: 1px solid #14da9e;
            font-size: 12px;
            font-weight: 600;
            padding: 7px 17px 7px 40px;
            position: relative;
            text-transform: uppercase;
            line-height: normal;
            -webkit-transition: all 0.2s ease-in-out;
            transition: all 0.2s ease-in-out;

            &:hover {
              color: #02bb83;
              border: 1px solid #02bb83;
            }

            &:focus {
              outline: 5px auto -webkit-focus-ring-color !important;
              outline-offset: -2px;
            }

            span {
              position: absolute;
              top: 50%;
              left: 17px;
              margin-top: -9px;
              font-size: 17px;
            }

            &.disabled {
              pointer-events: none;
            }
          }

          &.table-button-delete {
            color: var(--error-color);
            border: 1px solid var(--error-color);
            font-size: 12px;
            font-weight: 600;
            padding: 7px 17px 7px 40px;
            position: relative;
            text-transform: uppercase;
            line-height: normal;
            -webkit-transition: all 0.2s ease-in-out;
            transition: all 0.2s ease-in-out;

            &:hover {
              color: #e03a00;
              border: 1px solid #e03a00;
            }

            &:focus {
              outline: 5px auto -webkit-focus-ring-color !important;
              outline-offset: -2px;
            }

            span {
              position: absolute;
              top: 50%;
              left: 17px;
              margin-top: -9px;
              font-size: 17px;
            }

            &.disabled {
              pointer-events: none;
            }
          }
        }

        .kmb-cosmetic {
          span {
            color: #d5d9df;
            font-size: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }

  .actions-wrapper {
    > span > .btn {
      color: var(--error-color);
    }
  }

  td.kmb-actions {
    > div {
      padding: 0px;
      cursor: pointer;
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      top: -5px;

      &:hover {
        .toggle-actions {
          background-color: #666;

          &::before,
          &::after {
            background-color: #666;
          }
        }
      }
    }
  }

  .event-actions {
    transform: rotate(90deg) scale(0.8);
  }

  tr:hover {
    .actions-wrapper {
      background: linear-gradient(270deg, var(white) 50%, hsla(0, 0%, 100%, 0));
    }
  }

  span.icon-delete,
  span.icon-all-out {
    color: $error_color;
  }
}

.toggle-actions {
  width: 5px;
  height: 5px;
  display: inline-block;
  background-color: #d5d9df;
  border-radius: 50%;
  position: relative;
  transition: 0.2s background-color ease-in-out;

  &::before,
  &::after {
    content: "";
    width: 5px;
    height: 5px;
    display: inline-block;
    background-color: #d5d9df;
    border-radius: 50%;
    position: absolute;
  }

  &::before {
    right: -8px;
  }

  &::after {
    right: -16px;
  }
}
