.tab.tab-pricing {
  .pricing {
    display: flex;
    flex-direction: column;
  }

  .edit-date {
    cursor: pointer;
  }

  .period-date-header {
    background-color: white;
    border-bottom: 1px solid var(--background-content);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display: flex;
    align-items: center;
    padding: 10px 15px;
    gap: 10px;
    flex-wrap: wrap;

    .icon-delete {
      display: inline-flex;
      justify-content: flex-end;
      margin-left: auto;
      color: var(--error-color);
      cursor: pointer;
    }

    & > div,
    & > span {
      font-weight: bold;
      height: 30px;
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .icon-edit {
      margin-top: -3px;
      color: var(--secondary) !important;
    }

    .date-picker {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 5px;

      input.form-control {
        text-align: center;
        width: 100%;
        max-width: 190px;
      }
    }

    span.form-control,
    input.form-control {
      white-space: nowrap;
      border: none !important;
      box-shadow: none;
      height: 27px;
      padding: 0;

      &.period-name {
        display: inline-block;
        padding: 3px;
        min-width: 40px;
        width: auto;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 50%;

        &:focus {
          background-color: var(--primary-tone-4) !important;
        }
      }
    }

    .form-group,
    label {
      margin: 0;
    }
  }

  .apply-to-all {
    display: flex !important;
    transition: all ease 0.2s;
    height: 0 !important;
    max-height: 0 !important;
    min-height: 0 !important;
    overflow: hidden;

    &.show {
      min-height: 34px !important;
      height: 34px !important;
    }

    gap: 10px;

    & > div {
      display: flex;
    }
  }

  .form-container.pricing > .row {
    .sections .form-group {
      margin-bottom: 0;
    }

    .sections .form-group:first-child {
      margin-bottom: 25px;
    }
  }

  .order-fields {
    display: flex;
    justify-content: center;

    .med-table-container {
      min-height: 200px;
      background: white;
      margin: 0;
      padding: 12px 5px 5px;

      .kmb-draghandle {
        width: 20px;
      }
    }
  }

  .submit-container {
    margin-top: 15px;
  }

  .pricing-main {
    h2 {
      margin-bottom: 10px;
      font-size: 24px;
    }

    .icon-arrow-down-small {
      font-size: 10px;
    }

    .pricing-table-wrapper {
      max-width: 100%;
      overflow: auto;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    .pricing-table .row-header {
      white-space: nowrap;
      min-width: 190px;

      & > div {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;

        & > div {
          display: flex;
          flex: 1;
          justify-content: space-between;
          gap: 5px;
          align-items: center;
          cursor: pointer;
          min-height: 27px;
        }
      }
    }

    .pricing-table {
      table-layout: fixed;

      span.group-tag {
        background-color: var(--primary-tone-5);
        font-size: 14px;
        font-weight: normal;
        padding: 6px 12px;
        color: var(--primary);
        border-radius: 18px;
        margin-right: 5px;
        height: 32px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
      }

      min-width: 100%;
      border-collapse: separate;

      & > tbody {
        overflow-x: auto;
      }

      & > tbody > tr > td input {
        border: none !important;
        width: 100%;
        height: 100%;
        outline: none !important;
        text-align: center;

        &.error::placeholder {
          color: var(--error-color);
        }
      }

      & > tbody > tr > td {
        color: var(--primary);
        border-bottom: 1px solid var(--background-content);
        border-left: 1px solid var(--background-content);
        height: 50px;
        padding: 5px 15px;
      }

      & > tbody > tr > td:first-child {
        border-left: none;
      }

      & > tbody > tr {
        background-color: white;
      }

      & > tbody > tr:last-child {
        border-bottom: none;
      }

      & > tbody > tr:nth-child(even),
      & > tbody > tr:nth-child(even) input {
        background: var(--table-hover);
      }

      .price-value > div {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
