.header-pagination {
  display: flex;
  color: var(--black-tone-1);
  align-items: center;

  .details {
    position: relative;

    .options {
      &::before {
        content: "";
        position: absolute;
        top: -10px;
        height: 10px;
        left: 0;
        width: 100%;
      }

      position: absolute;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      top: 48px;
      background: white;
      width: 112px;
      min-height: 50px;
      box-shadow: 0 6px 12px 0 rgb(82 97 115 / 50%);
      z-index: 3;
      border-radius: 4px;
      font-weight: 400;

      & > div {
        padding: 5px 15px;
        width: 100%;

        &:hover {
          background-color: var(--primary-tone-4);
        }
      }
    }
  }

  .arrow {
    &.disabled {
      pointer-events: none;
      color: var(--black-tone-3);
    }

    cursor: pointer;
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-radius: 4px;

    &:hover {
      background-color: var(--primary-tone-4);
      color: var(--primary);
    }

    span {
      position: relative;
      font-size: 28px;
    }
  }
}
