.kmb-calendar-toolbar {
  color: #333 !important;
  margin-bottom: 0 !important;
  padding-bottom: 10px;
  border: 1px solid #ddd;
  align-items: unset !important;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  button:disabled {
    pointer-events: none;
    opacity: 0.6;
  }
}

.kmb-caledard-delete-item {
  visibility: hidden;
  top: 0;
  position: absolute;
  left: 0;
  font-size: 80%;
  color: var(--error-color);
  background: white;
  padding: 4px;
}

.rbc-event:hover .kmb-caledard-delete-item {
  visibility: visible;
}

.kmb-calendar-toolbar-row {
  padding-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.kmb-calendar-list-item {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  &:hover {
    text-decoration: underline overline;
  }
}

.kmb-calendar-tab {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--primary-tone-4);
  margin-top: -15px;
  height: 40px;
  cursor: pointer;
  border: 1px solid #ddd;
  border-top: 1px solid transparent;
  color: var(--secondary);
  background: #f7fbfd;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }

  &.selected {
    border: 1px solid transparent;
    background: white;
    opacity: 1;
  }
}

.rbc-calendar {
  & button {
    border: none !important;
    border-bottom: 2px solid transparent !important;
    box-shadow: none !important;
    outline: none !important;
    border-radius: 0 !important;
    background-color: white !important;
  }

  .rbc-active {
    background: white !important;
    border-bottom: 2px solid #008fc3 !important;
  }

  & button:hover {
    background: #f7fbfd !important;
  }

  .italic {
    font-style: italic;
  }

  .subtitle {
    color: #96a1b0;
  }
}
