.MuiTooltip-popper {
  z-index: 10000 !important;
}

.past-date-warning {
  font-size: 16px;
  position: relative;
  top: 2px;
  padding-left: 4px;
  color: #ff9900;

  &.table-positioner {
    top: 0px;
    font-size: 14px;
  }
}

.scheduler-container {

  .scheduler-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    >div {
      padding: 12px;
      background-color: var(--background-content);
      border-radius: 5px;
    }

    .scheduler-new-date {
      display: flex;
      column-gap: 16px;

      .new-static-scheduling,
      .new-dynamic-scheduling {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 12px;
        border-radius: 6px;
        height: 40px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        background-color: white;
        color: var(--secondary);
        border: 1px solid var(--secondary);
        cursor: pointer;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;

        &:hover {
          color: white;
          border: 1px solid var(--secondary-tone-1);
          background-color: var(--secondary-tone-1);
        }
      }
    }

    .scheduler-actions-wrapper {
      display: flex;
      column-gap: 16px;

      .scheduler-action {
        cursor: pointer;
        font-weight: 500;
        padding: 8px 23px;
        height: 40px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 120px;

        &.cancel-button {
          background-color: white;
          color: var(--secondary);
          border: 1px solid var(--secondary-tone-4);
        }

        &.save-button {
          background-color: var(--secondary);
          color: white;

          &.disabled {
            cursor: not-allowed;
            background-color: #72cbff;
          }
        }

        &:hover {
          &:not(.disabled) {
            color: white;
            border: 1px solid var(--secondary-tone-1);
            background-color: var(--secondary-tone-1);
          }
        }
      }
    }

    .scheduling-input-container {
      position: relative;
      display: flex;
      align-items: center;
      border: 1px solid var(--background-content);

      &.error-input {
        border: 1px solid red;

        .past-date-warning {
          color: red;
        }

        button.btn.circle.remove-condition {

          &.email {
            transform: rotate(45deg);
            border-top: 1px solid red;
            border-right: 1px solid red;

            border-bottom: 1px solid var(--background-content);
            border-left: 1px solid var(--background-content);

            i {
              transform: rotate(-45deg);
            }
          }
        }

      }

      &.dynamic {
        height: 104px;
      }

      &.static {
        height: 122px;
      }

      .scheduling-input-wrapper {
        height: 100%;

        .scheduling-input-label {
          font-style: normal;
          font-weight: 700;
          font-size: 15px;
          line-height: 24px;
          color: #27303E;
        }

        .scheduling-input-estimate {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #6D82A2;
        }

        .scheduling-input-fields {
          margin-top: 14px;

          &.static {
            display: flex;
            column-gap: 16px;

            >div {
              flex: 1;
            }
          }

          &.dynamic {
            display: flex;
            column-gap: 5px;

            .field-type {
              height: max-content;
              width: max-content !important;

              input {
                position: relative;
                top: -3px;
                width: 34px;
                height: 26px;
                padding: 4px 2px;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
              }
            }

            .sentence-select {
              position: relative;
              top: -3px;

              &.units-select {
                >div:first-of-type {
                  >div:first-child {
                    width: 62px;

                    >div {
                      width: 62px;
                    }
                  }
                }
              }

              &.sequence-select {
                >div:first-of-type {
                  >div:first-child {
                    width: 52px;

                    >div {
                      width: 52px;
                    }
                  }
                }
              }

              &.timeflag-select {
                >div:first-of-type {
                  >div:first-child {
                    width: 94px;

                    >div {
                      width: 94px;
                    }
                  }
                }
              }

              >div:first-of-type {
                height: 26px;
                padding: 0px;
                margin: 0px;
                min-height: 26px;
                text-align: center;
                justify-content: center;
                align-items: center;
                display: flex;

                >div:first-child {
                  padding: 2px 2px;
                  height: 26px;
                  margin: 0px;
                  min-height: 26px;
                  text-align: center;

                  >div {
                    height: 26px;
                    padding: 0px;
                    min-height: 26px;
                  }
                }

                >div:last-child {
                  width: max-content;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  padding-right: 2px;
                  height: 26px;
                  width: 20px;
                  min-height: 26px;

                  >div {
                    padding: 0px;

                    svg {
                      position: relative;
                      top: -1px;
                      height: 16px;
                      width: 16px;
                    }
                  }
                }

                input {
                  padding: 0px;
                  margin: 0px;
                  max-height: 26px;
                  min-height: 26px;
                  width: 100%;
                  max-width: 102px;
                }
              }
            }


            span {
              display: none;
            }
          }
        }
      }
    }
  }
}
