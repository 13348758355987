.search-wrapper {
  min-width: 200px;
  max-width: 300px;
  flex: 1;
  position: relative;
  display: inline-block;

  &,
  & * {
    cursor: pointer;
  }

  &:focus-within,
  &:hover {
    & * {
      color: var(--primary) !important;
    }

    & input {
      background-color: var(--primary-tone-4);
    }
  }

  .form-control {
    border: none;

    &:focus {
      box-shadow: none;
    }
  }

  &.active .search-input > span,
  .form-control:focus * {
    color: var(--primary) !important;
  }

  .search-input {
    width: 100%;

    input:not(:placeholder-shown) {
      background-color: var(--primary-tone-4);
      color: var(--primary);
    }

    input {
      border-radius: 0;
      padding-left: 36px;
    }

    > span {
      font-size: 17px;
      color: var(--tab-header-menu);
      position: absolute;
      top: 12px;
      left: 12px;
    }
  }
}
