.side-modal {
  max-width: 100%;
  width: 600px;
  position: fixed;
  background-color: white;
  top: 0;
  right: 0;
  transform: translateX(100%);
  z-index: 9999;
  transition: transform 0.4s ease-in-out;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.5);
  padding: 25px;
  overflow: auto;
  height: calc(100% - 56px);
  min-height: calc(100% - 56px);
  margin-top: 56px;
  overflow-x: hidden;

  &.in {
    transform: translateX(0);
  }

  &.large {
    width: 700px;
  }

  .close-modal {
    button>span {
      font-size: 16px;
    }
  }

  .form-group {
    background-color: var(--background-content) !important;

    h2 {
      margin-bottom: 20px;
    }
  }

  &.user-notifications-modal {
    width: 400px;
    background-color: var(--primary-tone-4);
    padding: 25px 35px;

    .close-modal button>span {
      color: #96a1b0;
    }
  }
}
