.section-guests,
.section-registration {
  .policy-settings-table {
    padding-bottom: 0;
  }

  textarea {
    min-height: 100px;
  }

  .additional-settings {
    .table > tbody > tr > td,
    .table > tbody > tr > th,
    .table > tfoot > tr > td,
    .table > tfoot > tr > th,
    .table > thead > tr > td,
    .table > thead > tr > th {
      border: none !important;
    }
  }
}

.section-registrationsettings {
  .single-policy {
    .submit-container {
      margin: 0 15px;
    }
  }

  thead {
    display: none;
  }

  .single-policy.row {
    display: flex;
    flex-direction: column;
  }

  .flex-gap {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;

    & > div {
      min-width: 200px;
      flex: 1;
    }
  }
}
