.top-bar {
  &.more-actions .upper-area {
    width: 130px;
  }
  & .upper-area {
    border: none;
    background-color: white;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    .fa-angle-down {
      top: 10px;
      font-size: 20px;
      font-weight: bold !important;
      right: 10px;
    }
    &:hover {
      background-color: var(--primary-tone-4);
      color: var(--primary);
      .fa-angle-down {
        color: var(--primary);
      }
    }
  }

  & .bottom-area {
    min-width: 200px;
  }
}

.crm-menu {
  .crm-tab-menu {
    .users-header {
      padding: 10px 0 0;

      .select-advanced {
        display: inline-block;

        .directional {
          top: 7px;
          color: var(--secondary);
        }

        &.more-actions {
          li {
            font-size: 14px;
          }
        }

        &.filters {
          .directional {
            display: none;
          }
        }
      }

      > div {
        &:first-child {
          padding-right: 0;

          > div {
            &:last-child {
              padding-right: 0;
            }
          }
        }
      }

      .search-wrapper {
        width: auto;
        display: block;
        padding-right: 15px;
        margin-right: 150px;

        .search-input {
          display: block;
        }

        span {
          right: 24px;
        }
      }

      .add-new-user {
        float: right;
        width: 150px;
      }

      .search-advanced-wrapper {
        display: inline-block;
        padding-right: 15px;
      }

      .search-advanced {
        &.active {
          background-color: var(--secondary);
          color: white;
          padding: 7px 35px 7px 25px;
          position: relative;

          > span[class^="icon-"] {
            position: absolute;
            top: 11px;
            font-size: 10px;
            right: 10px;
          }

          &:hover {
            background-color: var(--secondary);
          }
        }
      }

      .upper-area,
      .search-advanced {
        display: inline-block;
        border: 1px solid var(--secondary);
        color: var(--secondary);
        padding: 7px 30px;
        padding-left: 20px;
        font-size: 14px;
        font-weight: 500;

        &:hover {
          background-color: #f5f9fd;
        }

        span[class^="icon-"] {
          font-size: 12px;
          padding-right: 5px;
          position: relative;
          top: 1px;
        }
      }

      .bottom-area {
        min-width: 200px;
      }
    }

    ul {
      list-style-type: none;
      padding: 0;
      margin-left: -10px;

      li {
        display: inline-block;
        margin-right: 20px;
        padding-right: 15px;
        padding-bottom: 20px;
        padding-left: 15px;
        padding-top: 10px;
        position: relative;
        transition: all 0.2s ease-in-out;

        &::after {
          content: "";
          display: flex;
          width: 100%;
          left: 0;
          height: 3px;
          position: absolute;
          bottom: 13px;
          transform: scaleX(0);
          transition: transform 0.1s ease-in-out;
        }

        &.active {
          button {
            color: #292929;
            font-weight: 500;
          }

          &::after {
            transform: scaleX(1);
          }
        }
      }

      button {
        color: var(--primary);
        font-size: 15px;
        font-weight: 400;
        line-height: 20px;
        transition: color 0.2s ease-in-out;

        &:hover,
        &:active,
        &:focus {
          color: #292929;
        }
      }
    }
  }

  .main-grid {
    padding-left: 0;

    &.vertical {
      > .row {
        flex-wrap: wrap;
        padding: 0;

        > div {
          width: 100%;
        }
      }

      .tab-items {
        > ul {
          list-style-type: none;
          height: 100%;

          li {
            &.active {
              > button {
                color: var(--secondary);
                font-weight: 500;
              }
            }

            &:last-child {
              margin-bottom: 0;
            }

            button {
              color: var(--primary);
              font-size: 14px;
            }

            ul {
              // second level
              padding: 30px 15px;
              list-style-type: none;

              li {
                > button {
                  text-transform: uppercase;
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.crm-table {
  background-color: black;
  padding: 25px;
  border-radius: 5px;
  margin-bottom: 10px;
}
