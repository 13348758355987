.exhibitor-page-edit-component {
  .tox-fullscreen {
    width: 700px !important;
    z-index: 10;
  }
  .submit-container {
    margin-top: 35px;
    text-align: right;
    & button {
      margin-left: 15px;
    }
  }
}
.tox.tox-silver-sink.tox-tinymce-aux {
  z-index: 10000;
}
.section-exhibitorslist {
  .kmb-cosmetic {
    span {
      font-size: 12px !important;
    }
    .exhibitor-thumbnail img {
      height: 35px;
      width: 35px;
      object-fit: cover;
    }
  }
}
.section-digitalcategories,
.section-exhibitorslist,
.section-digitalslots {
  .slots-wrapper {
    .category-container {
      display: flex;
      justify-content: center;
      align-items: center;

      .kmb-name {
        input {
          height: 40px;
          width: 100%;
          border: 1px solid #dae4f0 !important;
          color: var(--primary);
          outline: none !important;
          border-radius: 4px;
          padding: 10px;
        }
      }
      .kmb-remove {
        width: 23px;
        .remove-item {
          cursor: pointer;
          color: var(--secondary);
          display: flex;
          justify-content: flex-end;
          font-size: 16px;
          align-items: center;
        }
      }
    }
    .slot-container {
      margin: 0px;
      position: relative;
      min-height: 70px;
      border: 1px solid #dae4f0 !important;
      border-radius: 4px;

      .title {
        color: var(--secondary);
        font-size: 14px;
        font-weight: 500;
        position: absolute;
        left: 15px;
        top: -10px;
        background: white;
        padding: 0px 5px;
      }
      .slot-item.occupied {
        background: none !important ;
        pointer-events: none;
      }
      .slot-item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(100% - 15px);
        height: 50px;
        color: var(--primary);
        margin: 15px 0px;
        padding: 0px;
        text-align: center;
        outline: none !important;
        border: 1px solid #dae4f0 !important;
        border-radius: 4px;
        width: 100%;
        .type-checkbox {
          pointer-events: none;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        &.disabled {
          pointer-events: none;
          background-color: var(--primary-tone-4);
        }
        .occupied-exhibitor {
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            object-fit: contain;
            height: 40px;
            width: 40px;
          }
        }
      }
    }
    .submit-container {
      text-align: right;
      & button {
        margin-left: 15px;
      }
    }
  }
}
.section-exhibition,
.section-exhibitorslist {
  .main-page-header .exhibitor-editable-item {
    padding: 0px;
  }
  .exhibitor-editable-item {
    padding: 15px;
    transition: box-shadow ease 0.2s;
    height: 100%;
    width: 100%;
    & > * {
      max-height: 100%;
    }

    .header {
      height: 150px;
    }

    &.row-height-1,
    &.row-height-2 {
      background-color: var(--primary-tone-4);
      margin-top: 15px;
      padding: 0px;
      .component {
        overflow: hidden;
        padding: 15px;
        .exhibitor-editable-item > *:not(.editable-buttons) {
          overflow: auto;
          width: 100%;
        }
      }
    }

    &.row-height-1,
    &.row-height-1 .component,
    &.row-height-1 .exhibitor-editable-item {
      height: 283px;
    }
    &.row-height-2,
    &.row-height-2 .component,
    &.row-height-2 .exhibitor-editable-item {
      height: 566px;
    }
    .component {
      padding: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .editable-buttons.active {
      display: flex !important;
    }
    &:hover {
      > .editable-buttons {
        display: flex;
      }
      box-shadow: 0 0 20px 0px #cccccc;
    }

    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .editable-buttons {
      transition: all ease 0.2s;
      cursor: pointer;
      position: absolute;
      top: 10px;
      font-size: 14px;
      width: 30px;
      height: 30px;
      z-index: 2;
      background-color: white;
      border-radius: 100%;
      display: none; //unflex for hide
      justify-content: center;
      align-items: center;
      box-shadow: 0 0 20px 0px #cccccc;
    }
    .edit-btn {
      right: 40px;
      color: var(--primary);
      padding-top: 3px;
    }
    .delete-btn {
      right: 5px;
      padding-top: 3px;
      color: var(--error-color);
      &.center {
        right: -50px;
        top: calc(100% - 15px);
        transform: translate(-50%);
      }
    }

    .add-row-btn {
      z-index: 3;
      transition: all ease 0.2s;
      color: var(--primary);
      .options {
        transition: all ease 0.2s;
        display: none;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 240px;
        position: absolute;
        background: #fff;
        top: -240px;
        margin: 20px;
        box-shadow: 0 0 20px 0px #cccccc;
        min-height: 210px;
        .disabled.option {
          pointer-events: none;
          color: #aaa !important;
        }
        .option {
          color: var(--primary);
          padding: 10px;
        }
        .option:hover {
          background: #f7fbfd !important;
          cursor: pointer;
        }
        & > div {
          width: 100%;
          border-bottom: 1px solid #fcfcfc;
        }
      }
      .add-row {
        transition: transform ease 0.2s;
      }
      &.active {
        & > .add-row {
          transform: rotate(90deg);
        }
        .options {
          display: flex;
        }
      }
      right: 50%;
      top: calc(100% - 20px);
      transform: translateX(50%);
    }
  }
  .exhibitor-thumbnail {
    width: 35px;
    display: block;
    position: relative;

    img {
      display: block;
      border-radius: 4px;
    }
  }
  .single-exhibitor-page {
    .exhibitor-page-header {
      height: 150px;
      display: flex;
      justify-content: center;
      align-items: center;
      .exhibitor-editable-item {
        width: 200px;
      }
      img.background {
        width: calc(100% - 30px);
        height: 100%;
        object-fit: cover;
        position: absolute;
        background-color: #888;
      }
      .logo {
        width: 200px;
        height: 150px;
        object-fit: contain;
        z-index: 1;
        color: white;
        font-size: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .tools {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      .submit-container {
        display: flex;
        gap: 5px;
      }
    }
  }

  .single-exhibitor {
    textarea {
      height: 68px;
      max-height: 68px;
      min-height: 68px;
      border: 1px solid #dae4f0 !important;
      color: var(--primary);
      outline: none !important;
    }
    .submit-container {
      text-align: right;
      & button {
        margin-left: 15px;
      }
    }
  }
}
