@media (max-width: 600px) {
  .client-panel-settings .general-settings {
    & > div.item {
      padding: 0 !important;
    }
  }
}

.client-panel-settings {
  .kmb-settings {
    display: flex;
    flex-wrap: wrap;
  }

  .home-sponsors-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    position: relative;
    width: 100%;
    max-width: 1400px;
  }

  .home-sponsor {
    max-width: 18%;
    margin: 5px;
  }

  .home-sponsor img {
    max-height: 100px;
  }

  .home-cta-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 70%;

    .home-cta {
      flex: calc(50% - 10px);
      background: white;
      color: #333;
      border-radius: 30px;
      padding: 5px;
      margin: 5px;
      cursor: pointer;
      border: 2px solid #f5f6f8 !important;
      text-align: center;
    }

    .home-cta:hover {
      background: var(--primary-tone-4);
    }
  }

  .med-table-container {
    padding: 0;
  }

  .client-settings-table {
    .kmb-name {
      min-width: 100px;
      width: 100px;
    }

    .table-button {
      width: 170px;
    }

    .switch-button {
      cursor: pointer;
      display: flex;
      width: 170px;
      height: 30px;
      border: 1px solid #96a1b0;
      border-radius: 2px;
      padding: 0 10px;
      color: #96a1b0;
      font-size: 12px;
      font-weight: 500;
      text-transform: uppercase;

      &:hover {
        border-color: var(--secondary);
        color: var(--secondary);
      }

      & > div {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 29px;

        & > span {
          padding-left: 5px;
        }
      }

      & .onoffswitch {
        position: relative;
        top: 1px;
        left: -5px;
      }
    }
  }

  .form-disabled {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(186 194 205 / 14%);
    z-index: 10;
    cursor: not-allowed;
  }

  .general-settings {
    .general-settings-container {
      & > div {
        display: flex;
        flex-flow: column;

        label,
        .input {
          width: 100%;
        }

        .form-group {
          height: 100%;
        }
      }

      & * {
        word-break: break-all;
      }

      & > div.item > span {
        color: #444;
        font-size: 12px;
        padding-top: 5px;
      }

      & > div.item-left {
        padding-right: 20px;
      }

      & > div.item-right {
        padding-left: 20px;
      }

      & > div.item-center {
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    .submit-container {
      text-align: right;
    }
  }

  .sponsors-container {
    .disabled {
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgb(0 0 0 / 10%);
      display: flex;
      justify-content: center;
      padding: 10px 30px;
      font-size: 25px;
      color: #818181;
      text-align: center;
      min-width: 400px;
    }

    .independent-table-container {
      margin-top: 50px;
      flex: 1;
      min-width: 400px;

      & > div {
        border-radius: 4px;
        padding: 5px;
        min-height: 100%;
      }
    }

    .sponsors-types-container {
      background-color: #dcdcdc;
      padding: 10px;
      border-radius: 16px;
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      & > div {
        background-color: var(--background-content);
        border-radius: 16px;
        padding: 10px;
        position: relative;

        & .table-container {
          padding-top: 15px;
          padding-bottom: 10px;
        }
      }

      & .form-container {
        max-width: 700px;
      }

      & > div {
        display: flex;
        flex-flow: column;
        width: 100%;
        max-width: 600px;
      }

      & .submit-container {
        text-align: right;
        display: flex;
        width: 100%;
        justify-content: flex-end;
        margin-top: 25px;
        padding-right: 20px;
      }

      .onoffswitch {
        height: 20px;
      }

      ul {
        padding: 0;

        li {
          pointer-events: none;
          padding: 5px 0;
          border-bottom: 1px solid #ccc;
          display: flex;
          flext-direction: row;

          span.fa {
            cursor: pointer;
            font-size: 9px;
            width: 34px;
            height: 34px;
            color: #798eab;
            padding: 10px;
            pointer-events: auto;

            &.fa-times-circle {
              font-size: 17px;
              color: #2d90c4;
              width: 40px;
            }
          }

          input {
            pointer-events: auto;
            flex: 1;
          }

          &.form-group {
            margin-bottom: 0 !important;
          }
        }

        list-style: none;
      }

      .display-sponsor-types {
        margin-top: 30px;
        display: flex;

        label {
          flex: 1;
        }

        & > div {
          width: 30px;
        }
      }
    }
  }

  .form-label {
    color: var(--secondary);
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 13px;
  }

  .free-access-toggle {
    > div {
      display: inline-block;
      border: 1px solid #a0d6e9;
      padding: 7px 15px;

      > span {
        color: var(--secondary);
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
        float: left;
        padding-top: 2px;
        padding-right: 10px;
      }

      .onoffswitch {
        float: left;
      }
    }
  }
}
@media (max-width: 600px) {
  div.item-left,
  div.item-right,
  div.item-center {
    padding: 10px 0 !important;
  }
}
