.no-results-blue {
  .no-results {
    background-color: #d4e4f63b;
  }
}

.no-results {
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 400px;

  .no-results-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 76px;
    height: 75px;
    font-size: 35px;
    background: #d0d5dd;
    border-radius: 100%;
    color: white;
    margin-bottom: 20px;
  }

  .no-results-title {
    color: var(--primary);
    font-weight: 500;
  }

  .no-results-message {
    color: #928f98;
  }
}
