.tab-scannerdashboard {
  .room-wrapper {
    .inner {
      padding: 15px;
      background: #f9f8f8;

      h3 {
        margin-top: 0;
      }

      p:last-child {
        margin-bottom: 0;
      }

      span {
        font-weight: 700;
      }

      &::after {
        content: "";
        width: 12px;
        height: 12px;
        border-radius: 50%;
        display: block;
        background-color: #14da9e;
        position: absolute;
        right: 30px;
        top: 15px;
      }

      &.locked {
        &::after {
          background-color: var(--error-color);
        }
      }
    }
  }
}

.kmb-lockScanner {
  .onoffswitch .onoffswitch-inner::before {
    background-color: var(--error-color);
  }
}

.allout-past {
  .btn.disabled {
    pointer-events: none;
  }
}

.rooms {
  .left-area {
    >button {
      margin-right: 15px;
    }
  }
}

.upload-hits {
  .info {
    display: block;
    margin-top: 10px;
    font-size: 12px;
    font-weight: 300;
    color: var(--primary);

    span {
      position: relative;
      top: 2px;
      padding-right: 5px;
    }
  }

  .kmb-dropzone {
    .vertical-center {
      padding: 0;
    }

    .details,
    .prompt-message {
      display: inline-block;
      margin: 0;
      color: var(--secondary);
    }

    .details {
      font-size: 14px;
      padding-left: 40px;

      span {
        font-weight: 500;
      }
    }

    .text-area {
      position: relative;
    }

    .prompt-message {
      position: absolute;
      top: -8px;
      left: 0;

      span {
        font-size: 30px;
      }
    }
  }
}

.undo-massive {
  h2 {
    margin-bottom: 30px;
  }

  table {
    width: 100%;

    tbody>tr>td {
      color: var(--primary);
      font-size: 14px;

      &:nth-child(2) {
        color: #96a1b0;
        font-size: 14px;
      }

      &:last-child {
        text-align: right;
      }
    }

    button.btn {
      border: 1px solid var(--error-color);
      border-radius: 4px;
      color: var(--error-color);
      font-size: 12px;
      font-weight: 500;
      padding: 6px 20px;
      line-height: normal;
      text-transform: uppercase;
      transition: all 0.2s ease-in-out;

      &:hover {
        background-color: var(--error-color);
        color: white;
      }
    }
  }
}

.create-rooms,
.create-sessionTypes {

  .add-more-rooms-container,
  .add-more-sessiontypes-container {
    button {
      color: var(--secondary);
      font-size: 12px;
    }
  }

  .remove-container {
    margin-top: 10px;

    .btn {
      font-size: 12px;

      span {
        font-size: 9px;
        padding-right: 5px;
      }
    }
  }

  .submit-container {
    margin-top: 60px;
  }
}
