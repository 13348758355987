.cssload-thecube {
  width: 73px;
  height: 73px;
  margin: 49px auto 0;
  position: relative;
  transform: rotateZ(45deg);
  transform: rotateZ(45deg);
  transform: rotateZ(45deg);
  transform: rotateZ(45deg);
  transform: rotateZ(45deg);
}

.cssload-thecube .cssload-cube {
  transform: rotateZ(45deg) scale(1.1);
  transform: rotateZ(45deg) scale(1.1);
  transform: rotateZ(45deg) scale(1.1);
  transform: rotateZ(45deg) scale(1.1);
  transform: rotateZ(45deg) scale(1.1);
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
}

.cssload-thecube .cssload-cube::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(43 160 199);
  animation: cssload-fold-thecube 2.76s infinite linear both;
  animation: cssload-fold-thecube 2.76s infinite linear both;
  animation: cssload-fold-thecube 2.76s infinite linear both;
  animation: cssload-fold-thecube 2.76s infinite linear both;
  animation: cssload-fold-thecube 2.76s infinite linear both;
  transform-origin: 100% 100%;
  transform-origin: 100% 100%;
  transform-origin: 100% 100%;
  transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

.cssload-thecube .cssload-c2 {
  transform: scale(1.1) rotateZ(90deg);
  transform: scale(1.1) rotateZ(90deg);
  transform: scale(1.1) rotateZ(90deg);
  transform: scale(1.1) rotateZ(90deg);
  transform: scale(1.1) rotateZ(90deg);
}

.cssload-thecube .cssload-c3 {
  transform: scale(1.1) rotateZ(180deg);
  transform: scale(1.1) rotateZ(180deg);
  transform: scale(1.1) rotateZ(180deg);
  transform: scale(1.1) rotateZ(180deg);
  transform: scale(1.1) rotateZ(180deg);
}

.cssload-thecube .cssload-c4 {
  transform: scale(1.1) rotateZ(270deg);
  transform: scale(1.1) rotateZ(270deg);
  transform: scale(1.1) rotateZ(270deg);
  transform: scale(1.1) rotateZ(270deg);
  transform: scale(1.1) rotateZ(270deg);
}

.cssload-thecube .cssload-c2::before {
  animation-delay: 0.35s;
  animation-delay: 0.35s;
  animation-delay: 0.35s;
  animation-delay: 0.35s;
  animation-delay: 0.35s;
}

.cssload-thecube .cssload-c3::before {
  animation-delay: 0.69s;
  animation-delay: 0.69s;
  animation-delay: 0.69s;
  animation-delay: 0.69s;
  animation-delay: 0.69s;
}

.cssload-thecube .cssload-c4::before {
  animation-delay: 1.04s;
  animation-delay: 1.04s;
  animation-delay: 1.04s;
  animation-delay: 1.04s;
  animation-delay: 1.04s;
}

.kmb-loader {
  padding-left: 0;
  margin: 0;

  > li {
    border-radius: 8px;
    display: block !important;
    padding: 0 !important;
    background: linear-gradient(-45deg, #e9edf1, #dfe4eb, #e9edf1, #dfe4eb);
    background-size: 400% 400%;
    animation: gradient 3s ease infinite;
  }

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }

    50% {
      background-position: 100% 50%;
    }

    100% {
      background-position: 0% 50%;
    }
  }
}

@keyframes cssload-fold-thecube {
  0%,
  10% {
    transform: perspective(136px) rotateX(-180deg);
    opacity: 0;
  }

  25%,
  75% {
    transform: perspective(136px) rotateX(0deg);
    opacity: 1;
  }

  100%,
  90% {
    transform: perspective(136px) rotateY(180deg);
    opacity: 0;
  }
}

@keyframes cssload-fold-thecube {
  0%,
  10% {
    transform: perspective(136px) rotateX(-180deg);
    opacity: 0;
  }

  25%,
  75% {
    transform: perspective(136px) rotateX(0deg);
    opacity: 1;
  }

  100%,
  90% {
    transform: perspective(136px) rotateY(180deg);
    opacity: 0;
  }
}

@keyframes cssload-fold-thecube {
  0%,
  10% {
    transform: perspective(136px) rotateX(-180deg);
    opacity: 0;
  }

  25%,
  75% {
    transform: perspective(136px) rotateX(0deg);
    opacity: 1;
  }

  100%,
  90% {
    transform: perspective(136px) rotateY(180deg);
    opacity: 0;
  }
}

@keyframes cssload-fold-thecube {
  0%,
  10% {
    transform: perspective(136px) rotateX(-180deg);
    opacity: 0;
  }

  25%,
  75% {
    transform: perspective(136px) rotateX(0deg);
    opacity: 1;
  }

  100%,
  90% {
    transform: perspective(136px) rotateY(180deg);
    opacity: 0;
  }
}

@keyframes cssload-fold-thecube {
  0%,
  10% {
    transform: perspective(136px) rotateX(-180deg);
    opacity: 0;
  }

  25%,
  75% {
    transform: perspective(136px) rotateX(0deg);
    opacity: 1;
  }

  100%,
  90% {
    transform: perspective(136px) rotateY(180deg);
    opacity: 0;
  }
}
