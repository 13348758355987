.autocomplete {
  .item {
    cursor: pointer;
    padding: 11px 20px;
    color: #6a7f9d;
    overflow: hidden;
    font-size: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: normal;

    &:hover {
      background-color: #faf7f7;
    }
  }

  border-radius: 2px;
  background-color: #fff;
  position: absolute;
  width: 100%;
  overflow: auto;
  left: 0;
  z-index: 1;
  box-shadow: 0 3px 7px 0 rgb(139 155 175 / 50%);
}

.icon-notpaid {
  color: var(--error-color);

  &.sponsored {
    color: #3c96ff;
  }
}

.advanced-search {
  &.form-container {
    .submit-container {
      margin-top: 30px;

      button {
        margin-right: 0;
      }
    }
  }

  .box {
    box-shadow: inset 0 1px 0 0 rgb(232 232 233 / 50%), inset 0 -1px 0 0 #e8e8e9;
    padding: 40px 20px;

    .form-group {
      margin: 0;
    }

    &:last-child {
      div.type-checkbox {
        display: inline-block;
        margin-right: 15px;
      }
    }

    h3 {
      margin: 0 0 20px;
      color: var(--secondary);
      font-size: 14px;
      font-weight: 500;

      span {
        cursor: pointer;
        color: var(--primary);
        border-bottom: 1px dashed var(--primary);
      }
    }

    .multiselect {
      margin-bottom: 20px;
    }

    .now-container {
      margin-top: 10px;

      button {
        font-size: 14px;
        padding: 2px 10px;
      }
    }
  }
}

.rdtPickerOpenUpwards .rdtPicker {
  top: auto;
  bottom: 100%;
}

.rtdTimeToggle:hover {
  border-radius: 4px !important;
}

.user-events-modal {
  width: 95vw;
  height: 82vh;
}

.user-events {
  .scans-wrapper {
    margin-bottom: 100px;
    background-color: rgb(241 233 233 / 18%);
    padding: 15px;

    > .delete-full {
      clear: both;
      display: block;
      text-align: center;
      padding-top: 30px;

      button {
        height: 40px;
        width: 130px;
        padding: 0px;
      }
    }

    .actions {
      display: flex;
      height: 40px;

      .edit-actions-wrapper {
        display: flex;
      }

      .btn {
        height: 40px;
        width: 130px;
        padding: 0px;
      }

      .btn.disabled {
        pointer-events: none;
        opacity: 0.4;
      }
    }
  }

  .main {
    height: 80vh;
    overflow-y: scroll;
  }

  .add-event-btn {
    margin-top: 12px;
  }

  .add-event {
    margin-top: 30px;
    border: 1px solid #e9f8ff;

    .panel-body {
      .form-container,
      .select-advanced {
        margin-bottom: 15px;
      }

      .btn.save {
        padding: 4px 15px;
      }

      .select-advanced {
        &.empty {
          .upper-area {
            color: #999;
          }
        }

        .upper-area {
          border: 1px solid #d5d9df;
          border-radius: 2px;
          background-color: #fff;
          line-height: normal;
          color: #555;
        }

        span {
          font-weight: 400;
          font-size: 14px;
        }
      }
    }

    .panel-header {
      background-color: white;
      color: var(--secondary);
      padding: 15px;
      border-bottom: 1px solid #e9f8ff;
    }

    h4 {
      font-size: 15px;
      font-weight: 400;
      margin: 0;
    }
  }

  .actions {
    margin-top: 20px;

    > div {
      display: inline-block;
      margin-right: 5px;
    }

    button {
      padding: 5px 20px;
      width: auto;

      &:first-child {
        margin-right: 5px;
      }

      &.btn-danger {
        border: 1px solid transparent;
      }
    }
  }

  span.minutes {
    top: 0px;
    right: 0px;
    position: absolute;
    font-size: 22px;
    cursor: default;
    padding-left: 10px;
    float: right;
    color: var(--primary);
    padding-right: 30px;
    z-index: 5;

    > span {
      font-weight: 500;
    }
  }

  .modal-title {
    > span {
      display: block;
    }
  }

  .modal-body {
    span.minutes {
      float: none;
      font-weight: 300;
      font-size: 22px;
      padding: 0;
      display: block;
      margin-bottom: 25px;

      > span {
        font-size: 22px;
      }
    }
  }

  .modal-dialog {
    width: 100%;
    padding: 0 15px;
    margin: 15px 0 !important;

    .modal-content {
      min-height: 100%;
    }

    h3 {
      font-size: 22px;
      margin: 0;
      font-weight: 300;
    }
  }

  .nav {
    li {
      a {
        background-color: #f7fbfd;

        &:hover {
          background-color: #e9f8ff;
        }
      }

      &.active {
        a {
          background-color: #e9f8ff;
          color: var(--secondary);
        }
      }
    }
  }
}

.kmb-quickEdit {
  i {
    color: #a7b7d1;
    cursor: pointer;
    font-size: 18px;
  }
}

.tab-certprinting,
.tab-cardprinting {
  > h2 {
    display: none;
  }

  .cert-printing {
    .preview .canvas {
      height: 300px;
    }
  }

  .kmb-slider > div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
  }
}

.card-printing {
  .submit-container {
    margin-top: 70px;
  }

  .preview {
    .canvas {
      > p {
        &.barcode {
          padding: 20px;
        }

        &.name {
          text-align: center;
        }
      }

      img {
        max-width: 100px;
        max-height: 100px;
      }
    }
  }

  .kmb-checkbox {
    input[type="checkbox"] + label {
      font-size: 14px;
      font-weight: 500;
    }
  }

  .banner-container {
    max-width: 200px;
    margin-bottom: 35px;
    position: relative;

    .actions {
      height: 0;
      opacity: 0;
      visibility: hidden;
    }

    &:hover {
      .actions {
        > div {
          height: 100%;
          width: 100%;
          text-align: center;

          span {
            font-size: 20px;
            cursor: pointer;
          }

          span.icon-delete {
            color: var(--error-color);
            margin-right: 5px;
          }
        }

        transition: 0.2s opacity ease-in-out;
        opacity: 1;
        visibility: visible;
        display: block;
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgb(255 255 255 / 85%);
        left: 0;
        top: 0;
      }
    }
  }
}

.cert-printing,
.card-printing {
  .subtitle {
    display: block;
  }

  .user-barcode {
    > svg > rect {
      fill: transparent !important;
    }
  }

  h3 {
    color: var(--secondary);
    font-size: 14px;
    font-weight: 500;
    margin: 0 0 25px;
  }

  .submit-container {
    background-color: #f7f9fc;
    border-radius: 5px;
    padding: 12px;
    margin: 0;
    margin-top: 16px;
  }

  .card-editor-wrapper {
    display: flex;
    height: max-content;
    width: 100%;
    column-gap: 30px;

    .card-drag-editor {
      background-color: #f7f9fc;
      border-radius: 5px;
      flex: 1;
      display: flex;
      justify-content: center;
      padding: 12px;
      align-items: center;
      flex-direction: column;
      row-gap: 16px;

      .printer-preview {
        display: flex;
        width: 100%;

        .preview-selector {
          width: 100%;
          margin-right: 2%;
        }

        .preview-test {
          margin-top: 24px;
          height: 40px;

          button {
            height: 40px;
          }
        }

        &.cert {
          .preview-selector {
            display: flex;
            align-items: center;
          }

          .preview-test {
            margin-top: 0px;
          }
        }
      }

      .dymo-preview {
        position: relative;
        width: 780px;
        height: 224px;
        display: flex;
        align-items: center;
        flex-direction: column;
        border: 1px dashed #dae4f0;

        svg {
          position: absolute;
          bottom: 10px;
        }
      }

      .editor-row {
        .form-wrapper {
          flex: 1;
          background-color: white;
          padding: 12px;
        }
      }

      .image-wrapper {
        background-color: white;
        border-radius: 5px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 12px;

        .image-container {
          border-radius: 5px;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          width: max-content;
          height: 350px;

          .badge-image-wrapper {
            width: 100%;
            height: 100%;
          }

          img {
            width: 100%;
            object-fit: cover;
            z-index: 1;
          }

          .drag-wrapper {
            height: 350px;
            display: flex;
            position: absolute;
            z-index: 2;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            border: 1px dashed rgb(218 228 240);

            .center-drag {
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;

              div {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
        }
      }
    }

    .card-editor-tools {
      flex: 1;
      display: flex;
      flex-direction: column;
      border-radius: 5px;

      .tools-wrapper {
        background-color: #f7f9fc;
        padding: 12px;
        border-radius: 5px;
        flex: 1;
      }
    }

    .editor-row {
      display: flex;
      width: 100%;
      height: max-content;

      .kmb-dropzone {
        .zone-area {
          padding: 11px 5px !important;
        }
      }

      .form-group {
        border-radius: 5px;
        background-color: white;
        padding: 12px;
        flex: 1;
        display: flex;
        column-gap: 32px;

        &.font-group {
          margin-bottom: 0;
        }

        .form-wrapper {
          display: flex;
          flex: 1;
          flex-direction: column;

          .label-with-switch {
            display: flex;
            column-gap: 16px;
          }

          .divider {
            width: 100%;
            height: 1px;
            background-color: #d7d7d7;
            margin-bottom: 12px;
          }

          .merged-row {
            display: flex;
            flex: 1;
            column-gap: 16px;
            margin-bottom: 16px;
            flex-wrap: wrap;

            &.font-options {
              flex-direction: column;
              row-gap: 12px;

              > div {
                width: 100%;

                .flex-input {
                  flex: 1;
                }
              }
            }

            .icons-wrapper {
              display: flex;
              align-items: center;
              column-gap: 12px;

              .icon-container {
                width: 24px;
                height: 24px;
                flex: 1;

                .icon-selector {
                  color: #888;

                  &:hover {
                    color: #474747;
                  }

                  &.selected {
                    color: #57adfe;

                    &:hover {
                      color: #0083fd;
                    }
                  }

                  &.disabled {
                    color: #a7a7a7;

                    &.selected {
                      color: #82c1fc;
                    }
                  }
                }

                svg {
                  width: 100%;
                  height: 100%;
                }
              }
            }

            .color-picker-container {
              width: 100%;
              display: flex;

              .MuiFormControl-root.MuiTextField-root {
                display: flex;
                flex: 1;
                max-height: 40px;

                input {
                  padding: 6px 12px;
                  height: 28px;
                  font-size: 14px;
                  font-weight: 500;
                  border-radius: 5px;
                }

                ::before {
                  border: none !important;
                }
              }

              > div:not(.MuiFormControl-root) {
                left: calc(-100% + 200px);
                top: -240px;
              }
            }

            &.full-width-fields {
              flex-direction: column;
              row-gap: 12px;

              > div {
                width: 100%;
              }
            }

            label {
              min-width: 110px;
              flex: unset;
              margin: 0;
              margin-right: 3px;
              display: flex;
              align-items: center;
            }

            > div {
              flex: 1;
              width: calc((1 / 3) * (100% - 32px));
            }

            &:last-child {
              margin-bottom: 0;
            }

            .type-switch {
              display: flex;
              justify-content: flex-start;
              flex: 1;
            }

            .text-area-holder {
              flex: 1;

              label {
                &.text-area-subtitle {
                  color: #96a1b0;
                  display: block;
                  font-size: 14px;
                  margin-bottom: 6px;
                }
              }

              textarea {
                resize: none;
              }
            }

            .kmb-select {
              flex: 1;
            }
          }

          .kmb-text-outter {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .kmb-text {
              max-width: 80px;
            }
          }

          .switch-holder {
            margin-top: 0;
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: flex-start;
          }
        }
      }
    }
  }

  .preview {
    .canvas {
      height: 400px;
      border: 1px solid #e8e8e9;
      background-color: #f7f9fc;
      margin-bottom: 25px;
      position: relative;
      overflow: hidden;

      > p {
        margin: 0;
        position: absolute;
        border-radius: 3px;
        background-color: #c9d5e5;
        color: #fff;
        text-align: center;
        text-transform: uppercase;
        padding: 3px 15px;
      }
    }
  }

  @media (max-width: 1770px) {
    .merged-row {
      flex-direction: column;
      row-gap: 16px;

      > div {
        width: 100% !important;
      }

      .kmb-text-outter {
        justify-content: flex-start !important;
      }
    }
  }

  @media (max-width: 1200px) {
    .card-editor-wrapper {
      flex-direction: column;
      row-gap: 16px;
    }
  }
}

.cert-print {
  display: none;
}

.section {
  .tab-participants,
  .tab-guests {
    p.total-rows {
      margin-bottom: 20px;
    }

    .kmb-cosmetic {
      & > div {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .users-header {
      span[class^="icon-"] {
        font-size: 16px;
      }

      .search-div {
        display: flex;
        flex: 1;
      }

      .users-buttons {
        background-color: var(--primary-tone-4);
        display: flex;
        border-radius: 4px;
        align-items: center;
        justify-content: center;

        &.active {
          background-color: var(--primary-tone-4);
        }

        .icon-close.group-close {
          font-size: 16px;
          color: var(--primary);
          padding-right: 8px;
          cursor: pointer;
        }

        & span,
        & svg {
          color: var(--tab-header-menu);
          fill: var(--tab-header-menu);
        }

        &:hover,
        & .active {
          & span,
          & svg {
            color: var(--primary);
            fill: var(--primary);
          }
        }

        &.group-search {
          .select-button,
          .bt-select-button {
            .css-26l3qy-menu {
              min-width: 220px;
            }
          }
        }

        &.group-select {
          .select-button,
          .bt-select-button {
            .css-26l3qy-menu {
              min-width: 250px;
            }
          }
        }

        .group-select.icon-close {
          cursor: pointer;
          top: -26px;
          position: relative;
          font-size: 10px;
          left: 13px;
        }

        &.active .select-advanced .select-checkboxes-outer .upper-area {
          background-color: var(--secondary);
        }

        &.active,
        &.active .select-advanced .select-checkboxes-outer .upper-area > span {
          color: white;
        }

        .select-advanced {
          padding-right: 0;
        }

        .search-advanced-wrapper,
        button,
        .select-advanced,
        .select-advanced .select-checkboxes-outer,
        .select-advanced .select-checkboxes-outer .upper-area {
          width: 100%;
        }

        .select-advanced .select-checkboxes-outer .upper-area {
          padding-left: 32px;
        }
      }

      .select-advanced {
        display: inline-block;
      }

      > div {
        &:first-child {
          padding-right: 0;

          > div {
            &:last-child {
              padding-right: 0;
            }
          }
        }
      }

      .add-new-user {
        float: right;
        width: 150px;
      }

      .search-advanced-wrapper {
        display: inline-block;
        padding: 0;
      }

      .search-advanced {
        position: relative;
        padding: 5px 12px;
        text-align: left;

        span[class^="icon-"] {
          padding: 0;

          &.icon-criteria-search {
            top: 10px;
            font-size: 20px;
          }

          font-size: 16px;
        }

        &.active {
          color: var(--primary) !important;
          background-color: var(--primary-tone-4);
          color: white;
          position: relative;

          &:hover {
            background-color: var(--primary-tone-4);
          }
        }
      }

      .upper-area {
        background-color: white;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;

        .fa-angle-down {
          top: 10px;
          font-size: 16px;
          font-weight: bold !important;
          right: 10px;
        }

        &:hover {
          background-color: var(--primary-tone-4);
        }
      }

      .bottom-area {
        min-width: 200px;
        width: max-content;
      }
    }

    h2.tab-title {
      margin-bottom: 8px;
    }

    p.subtitle {
      color: #a7b7ce;
      font-size: 14px;
      font-weight: 400;
    }

    .table {
      > thead {
        .kmb-bulkSelect {
          padding-left: 12px;
        }

        > tr {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          > th {
            padding: 4px 12px 5px;

            &.kmb-actions {
              width: 95px;
            }
            &.kmb-quickEdit {
              width: 42px;
            }
          }
        }
      }

      > tbody {
        > tr {
          > td {
            font-size: 14px;
            color: var(--primary);
          }
        }
      }

      .form-group {
        margin: 0;
      }

      .custom-field-dropdown {
        .upper-area {
          min-width: 130px;
        }
      }

      .kmb-radio {
        .radio-wrapper {
          display: block;

          &:first-child {
            margin-bottom: 5px;
          }
        }
      }

      tr.has-colspan {
        td {
          padding-left: 11px;

          button {
            font-size: 12px;
            width: auto;
            padding: 5px 20px;

            &:first-child {
              margin-right: 10px;
            }
          }
        }
      }

      .kmb-minutes {
        .minutes-progress {
          position: absolute;
          display: block;
          width: 0;
          height: 2px;
          left: 0;
          bottom: -9px;
          background-color: var(--primary-tone-1);
        }

        > div {
          position: relative;

          > div {
            display: inline-block;
            cursor: pointer;

            &.refresh-minutes {
              > span {
                font-size: 10px;
                padding-left: 10px;
                top: -1px;
                position: relative;
                color: #888282;
              }
            }
          }
        }
      }

      .kmb-bulkSelect {
        width: 20px;

        .type-checkbox {
          width: 16px;
        }
      }

      .kmb-events,
      .kmb-card,
      .kmb-certificate {
        button,
        a {
          span {
            font-size: 17px;
          }
        }
      }

      .kmb-certificate {
        .upper-area {
          padding: 0;
          border: none;
        }

        input {
          display: inline;
          max-width: 50px;
          margin-left: 10px;
          text-align: center;
        }

        .bottom-area {
          min-width: 200px;
        }
      }
      td .btn.save {
        min-width: unset;
      }
    }
  }
}

@media (min-width: 900px) {
  .tab-participants {
    .med-table-container.is-pinned thead .kmb-_name::after,
    .med-table-container.is-pinned tbody .kmb-_name::after {
      pointer-events: none;
      content: "";
      position: absolute;
      top: 0;
      right: -77px;
      width: 77px;
      height: calc(100% + 8px);
      background: linear-gradient(90deg, #d7d7e033, rgb(240 240 244 / 0%));
    }

    .table {
      > thead {
        .kmb-actions {
          position: sticky;
          right: -24px;
          background: var(--background-content);
          z-index: 1;
        }

        .kmb-_name {
          position: sticky;
          left: -24px;
          background: var(--background-content);
          z-index: 1;
        }
      }

      > tbody {
        .kmb-_name {
          position: sticky;
          left: -24px;
          background: white;
          z-index: 1;
        }

        .kmb-actions {
          position: sticky;
          right: -24px;
          background: white;
          z-index: 1;
        }
      }
    }
  }
}
