.tab-charts {
  .panel-info {
    border-radius: 5px;
    border: none;
    overflow: hidden;

    >.panel-heading {
      background-color: #fff;
    }
  }

  .panel-heading {
    border: none;
    border-radius: 0;

    h4 {
      margin: 15px 0;
      color: var(--primary);
    }
  }

  .selectdate {
    margin: 0;
    padding: 0;

    >li>a {
      padding: 7px 15px;
      line-height: normal;
      font-size: 12px;
      border-radius: 4px;
      border: none;

      &:hover,
      &:focus,
      &:active {
        background-color: var(--secondary-tone-1);
        color: white;
        border: none;
      }
    }

    a {
      color: var(--primary);
    }

    >li.active {
      a {
        background-color: var(--secondary);
        color: white;
      }
    }
  }
}

.tab-peak {
  .stats {
    display: flex;
    justify-content: space-around;
    gap: 12px;
    flex-wrap: wrap;

    .box {
      text-align: center;

      &:last-child {
        border-right: none;
      }

      .inner-box {
        >p {
          font-size: 15px;
          font-weight: bold;
          padding: 0 25px;
          font-weight: 400;
          line-height: 1.2;
          margin: 0;
          color: var(--black-tone-1);
        }

        >div {
          font-size: 26px;
          text-transform: uppercase;
          display: flex;
          align-items: center;
          justify-content: center;

          >span:first-child {
            position: relative;
            color: var(--secondary);
            top: 3px;
            padding-right: 5px;
            font-size: 30px;
            display: inline-block;
          }
        }
      }
    }
  }
}

.tab-statistics {
  .stats {
    display: flex;
    justify-content: space-around;
    gap: 12px;
    flex-wrap: wrap;

    .box {
      .inner-box {
        display: inline-block;

        >div {
          position: relative;
          color: var(--primary);
          font-size: 36px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 12px;

          span:first-child {
            display: flex;
            justify-content: center;
            align-items: center;
            color: var(--secondary);
            font-size: 30px;
          }
        }

        >p {
          color: var(--black-tone-1);
          font-size: 14px;
          margin: 0;
          text-align: center;
        }
      }
    }
  }

  h2.tab-title {
    display: none;
  }

  .data-table {
    margin-top: 12px;

    h3 {
      margin-bottom: 0;
    }

    &.latest-scans {
      table {
        tr:nth-child(4) {
          opacity: 0.7;
        }

        tr:nth-child(5) {
          opacity: 0.5;
        }
      }

      .scan-type {
        span {
          display: inline-block;
          border: 1px solid #14da9e;
          border-radius: 3px;
          color: #14da9e;
          width: 100px;
          padding: 5px;
          text-transform: uppercase;
          text-align: center;

          &.exit {
            border-color: var(--error-color);
            color: var(--error-color);
          }
        }
      }
    }
  }
}
