.container.kmb-tiers {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 580px;
  margin: 0;
  width: 100%;
  padding: 0;
  overflow: auto;

  .kmb-tiers-row {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    flex: 1;
    min-height: 50px;
    gap: 10px;
    max-width: 100%;

    &:not(.kmb-tiers-header):hover {
      background-color: #f7fbfd;
    }

    .kmb-tiers-label {
      display: flex;
      align-items: center;
      width: 30%;
      color: var(--primary);
      font-size: 12px;
      padding: 5px;
      font-weight: normal;
      border-bottom: 1px solid var(--primary-tone-4);
    }

    .kmb-tiers-value {
      display: flex;
      flex: 1;
      gap: 3%;

      .kmb-tiers-item {
        border-bottom: 1px solid var(--primary-tone-4);
        position: relative;

        & .overlay {
          box-shadow: none;
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 100;
        }

        &.selected {
          cursor: pointer;
          border: none;

          .overlay {
            display: block;
            box-shadow: 0 0 4px 2px #8d8d8d;
          }

          input {
            color: var(--primary);
          }
        }

        input {
          z-index: 100;
          width: 80%;
          border-top: none;
          border-left: none;
          border-right: none;
          background-color: transparent;
          text-align: center;
          color: #a9a9a9;
        }

        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
      }
    }
  }

  .kmb-tiers-header {
    .kmb-tiers-label {
      color: var(--secondary);
      font-weight: 14px;
      font-weight: 500;
    }

    .kmb-tiers-item {
      border: none;

      &.tier-btn {
        line-height: 35px;
        background-color: white;
        color: #2d90c4;
        margin-bottom: 5px;
        border: none;

        &:hover {
          font-weight: bold;
        }

        border-bottom: 5px solid transparent !important;

        &.selected {
          font-weight: bold;
          border-bottom: 5px solid #2d90c4 !important;
        }
      }
    }
  }

  .disabled {
    pointer-events: none;
  }

  .not-used.disabled {
    background-color: #e5e5e5;
  }

  .used.disabled {
    background-color: #d4e9ff;
  }
}
