.info-box-popper {
  z-index: 10000;
  margin: 2px;
  .popper-content {
    position: relative;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2);
    padding: 10px;
    max-width: 300px;
    font-style: italic;
  }
}

.info-box-button {
  color: #2a79d1 !important;
  .info-box-icon {
    font-size: 18px !important;
  }
}
