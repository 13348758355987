.notification-list {
  position: fixed;
  top: 65px;
  width: 560px;
  left: 50%;
  margin-left: -280px;
  text-align: center;
  z-index: 2051;

  .notification-bar {
    display: block;
    width: 560px;
    margin: 0 auto;
    text-align: left;
    background-color: #eee;
    color: white;
    font-weight: 600;
    padding-left: 80px;
    position: relative;
    transform: translateY(-100px);
    opacity: 0;
    transition: 0.3s all ease-in-out;
    border: none;
    margin-bottom: 3px;

    &.notification-bar-active {
      opacity: 1;
      transform: translateY(0);
    }

    &::before {
      font-family: blue-tree-admin !important;
      font-style: normal !important;
      font-weight: normal !important;
      font-variant: normal !important;
      text-transform: none !important;
      speak: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "\7e";
      position: absolute;
      left: 20px;
      top: 50%;
      margin-top: -10px;
      font-size: 20px;
    }

    &.alert-error {
      background-color: var(--error-color);

      &::before {
        content: "\58";
      }
    }

    &.alert-success {
      background-color: #14da9e;

      &::before {
        content: "\2a";
      }
    }

    &.alert-warning {
      background-color: #eca93c;

      &::before {
        content: "\30";
      }
    }
  }
}

@media (max-width: 767px) {
  .notification-list {
    .notification-bar {
      width: 100%;
      max-width: 300px;
    }
  }
}
