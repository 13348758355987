.rdg-editor-container input.form-control.editor-main,
.rdg-editor-container input.editor-main,
select.editor-main,
.form-control {
  border-radius: 0 !important;
  background: white;
}

.kmb-datagrid {
  .react-grid-Container,
  .react-grid-Canvas {
    max-width: 100%;
  }

  .kmb-datagrid-toolbar {
    background: white;
    border: 1px solid #fff;
    border-radius: 5px;
    min-height: 37px;
    display: flex;
    align-items: center;
    color: var(--tab-header-menu) !important;
    user-select: none;
    border-radius: 5px;

    .kmb-datagrid-toolbar-item {
      font-size: 20px;
      padding: 5px 7px;

      .selectable {
        cursor: pointer;
      }

      &.selectable:hover,
      & .selectable:hover {
        cursor: pointer;
        background: var(--primary-tone-4) !important;
      }

      &.text {
        font-weight: 500;
        font-size: 14px;

        & *:nth-child(1) {
          padding: 4px;
        }
      }
    }
  }
  // test
  .react-grid-Row {
    margin: 3px 0;
  }

  .react-grid-Canvas {
    background-color: var(--background-content);
  }
  // test

  .react-grid-Cell {
    border-bottom: none;
  }

  .react-grid-Cell.react-grid-Cell--frozen.rdg-last--frozen {
    color: var(--primary) !important;
    font-size: 12px;
    border-radius: 0 !important;
  }

  .react-grid-HeaderCell {
    background: var(--primary-tone-4) !important;
  }

  .react-grid-Header {
    color: var(--gray-600);

    .react-grid-HeaderCell {
      font-size: 12px;
      font-weight: 500;
      display: flex !important;
      align-items: center !important;
      line-height: 100%;
      overflow: hidden;
    }
  }

  .react-grid-Canvas {
    color: var(--primary) !important;
  }

  .react-grid-Container {
    border: 1px solid #eee;
  }

  .react-grid-Row {
    &:hover > div {
      background: var(--primary-tone-4) !important;
    }
  }

  .react-grid-Main {
    border-radius: 5px;
  }

  .react-grid-Main,
  .react-grid-Grid {
    outline: none !important;
    border: 1px solid white;
  }

  .react-grid-Canvas {
    overflow: auto !important;
    height: auto;
  }

  .react-grid-HeaderRow {
    width: 100% !important;
  }
}
