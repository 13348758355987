.room-types {
  h2 {
    margin: 0 0 5px;
  }

  .hotel-name {
    font-weight: 300;
    font-size: 20px;
  }

  .range-wrapper {
    .form-group {
      margin: 0;
    }
  }

  > .form-container {
    margin-top: 30px;
  }

  .single-room {
    background-color: #f5f9fd;
    padding: 30px;

    h3 {
      font-size: 14px;
      color: var(--secondary);
      margin: 0 0 30px;
    }

    label {
      color: #96a1b0;
      font-size: 14px;
      font-weight: 400;
      text-transform: capitalize;
    }

    .boolean-buttons {
      margin: 20px -15px 30px;
    }
  }

  .med-table-container {
    margin-top: 30px;

    .rdt {
      table {
        > thead > tr {
          &:hover {
            background-color: inherit;
          }

          > th {
            text-transform: none;
          }
        }

        > tbody > tr {
          &:hover {
            background-color: inherit;
          }

          > td {
            border: none;
          }
        }
      }
    }
  }
}

.hotels-inner {
  .search-wrapper {
    width: auto;
    display: block;
    padding-right: 15px;
    margin-right: 150px;

    span {
      right: 24px;
    }
  }

  .right-area {
    .btn {
      float: right;
      width: 150px;
    }
  }

  .med-table-container table tbody > tr {
    &:hover {
      .kmb-cosmetic > div {
        background-color: #a0d6e9;
      }
    }

    .kmb-cosmetic {
      > div {
        border-radius: 50%;
        display: inline-block;
        padding: 4px 10px;
        width: 40px;
        height: 40px;
        background-color: #d5d9df;
      }

      span {
        font-size: 20px;
        color: white;
      }
    }
  }
}

.kmb-stars {
  i {
    font-size: 14px;
    padding-right: 2px;
    color: #d5d9df;

    &:last-child {
      padding-right: 0;
    }
  }

  i.active {
    color: #eca93c;
  }
}

.single-hotel {
  .boolean-buttons {
    margin: 10px -15px 20px;
  }
}

.single-room,
.single-hotel {
  .select-advanced {
    margin-bottom: 20px;
  }

  .btn-green {
    width: auto;

    &:hover {
      color: white;
    }
  }
}

.form-container {
  .kmb-stars {
    i {
      font-size: 16px;
      cursor: pointer;
    }
  }
}

.section-accomodation {
  .search-wrapper {
    width: 100%;
  }
}
