.login-page {
  display: flex;
  width: 100%;
  height: 100%;

  .left {
    overflow: hidden;
    position: relative;
    flex: 55%;
    .espa_logo{
      position: absolute;
      top: 20px;
      left: 20px;
      width: 400px;
      max-width: 90%;
      height: 50px;
      z-index: 10;
    }
    .background-image {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 1;
      top: 0;
      left: 0;
    }

    .main-image {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: contain;
      z-index: 2;
      top: 0;
      right: 8%;
      max-width: 750px;
      padding: 40px;
      padding-right: 0;
    }

    .overlay-color {
      position: absolute;
      background-color: rgb(73 90 114 / 50%);
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 2;
    }

    .text-wrapper {
      position: absolute;
      bottom: 30%;
      left: 15%;
      z-index: 10;
      display: flex;
      flex-direction: column;
      color: white;

      svg {
        width: 250px;
        height: 67px;
        margin-bottom: 20px;
      }

      span {
        font-size: 30px;
        padding-left: 20px;
      }
    }
  }

  .right {
    display: block;
    overflow: auto;
    flex: 45%;
    padding: 20px 10%;
    background-color: var(--background-content);

    & > div {
      display: flex;
      justify-content: center;
      flex-direction: column;
      min-height: 100%;
    }

    h2 {
      font-weight: 600;
      font-size: 30px;
      color: #282b34;
      margin-bottom: 12px;

      span {
        color: var(--secondary-tone-1);
      }
    }

    p {
      font-size: 16px;
      color: #393d4b;
      margin-bottom: 32px;
    }

    h3 {
      color: #393d4b;
      font-weight: 500;
      font-size: 20px;
      margin-bottom: 20px;
    }

    .form-container {
      .form-group {
        background-color: unset;
        padding: 0;
        margin-bottom: 20px;

        label {
          color: #393d4b;
          font-weight: 500;
        }
      }

      .error {
        color: var(--error-color);
      }
      .password-hint{
        font-size: 0.9em;
        color: #555;
        padding-top:10px;
      }
      .btn {
        height: 44px;
        width: 100%;
        font-size: 16px;
        margin-top: 35px !important;
        &.change-password {
          margin-top: 0 !important;
          justify-content: flex-end;
          max-width: fit-content;
          float: right;
          margin-bottom: 20px;
        }

        &.cancel{
          margin-top: 5px !important;
        }
      }
    }
  }
}

@media (max-width: 1270px) {
  .login-page {
    .right {
      padding: 20px 5%;
    }
  }
}

@media (max-width: 863px) {
  .login-page {
    .right {
      padding: 20px;
    }
  }
}

@media (max-width: 700px) {
  .login-page {
    flex-direction: column;

    .left {
      height: 200px;
      max-height: 200px;

      .main-image {
        position: absolute;
        width: 327px;
        height: 224px;
        object-fit: cover;
        z-index: 2;
        top: 0;
        right: 8%;
        max-width: 750px;
        padding: 10px;
        padding-right: 39px;
      }
    }

    .right {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}
