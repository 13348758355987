.single-speech {
  .boolean-buttons {
    display: flex;
    gap: 12px;

    & > div {
      flex: 1;
    }

    button {
      flex: 1;
    }

    button.btn.selected {
      background-color: var(--secondary);
      color: white;
    }
  }
}

.tab-sessions {
  .sessions-header {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .kmb-name {
    max-width: 300px;
  }

  .kmb-chairperson {
    max-width: 200px;
  }
  @media (max-width: 991px) {
    .sessions-header {
      display: block !important;
    }
  }

  .quick-change {
    .select-advanced {
      display: inline-block;
    }

    .bottom-area {
      font-size: 14px;
    }

    .options-wrapper {
      max-height: 125px;

      li {
        padding: 5px 2px;
      }

      input {
        display: inline;
        max-width: 50px;
        margin-left: 10px;
        text-align: center;
      }
    }

    .time-mode {
      padding-left: 0;
      margin: 5px 0;
      user-select: none;

      li {
        display: inline-block;
        padding-right: 5px;
        cursor: pointer;

        &.selected {
          color: var(--secondary);
        }

        &::after {
          padding-left: 5px;
          content: "|";
          color: var(--primary-tone-2);
        }

        &:last-child {
          padding-right: 0;

          &::after {
            display: none;
          }
        }
      }
    }

    .upper-area {
      background-color: transparent;
      border-radius: 2px;
      font-size: 12px;
      color: var(--secondary);
      text-align: center;
      border: none;
      padding: 5px 15px;
      margin-top: 10px;
      transition: all 0.1s ease-in-out;
      display: flex;
      align-items: center;

      &:hover {
        background-color: var(--secondary);
        color: white;
      }

      span.icon-time {
        position: relative;
        top: 1px;
        padding-right: 0;
        padding-left: 4px;
      }
    }
  }

  .switch-view-inner {
    position: absolute;
    right: 0;
    top: 30px;
    color: #d5d9df;
    font-size: 20px;

    & > span {
      opacity: 0.5;
      padding: 10px;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }

      &.selected {
        opacity: 1;
        color: #96a1b0;
      }
    }
  }

  .single-session {
    .row-blue:first-child,
    .row-white:first-child {
      padding-top: 35px;
    }

    .row-blue {
      background-color: #f5f9fd;
      padding: 0 65px 35px 50px;
      margin: 0 -35px;
      width: 700px;
    }

    .row-blue .type-speaker.kmb-speaker {
      padding: 0 50px !important;
    }

    .field-type div.date-icon-holder > span {
      height: calc(100% - 2px);
      width: 40px;
      border-left: 1px solid #d5d9df;
      border-radius: 2px;
      background-color: #f9fafb;
      top: 1px;
      right: 1px;
      color: var(--secondary);
      text-align: center;

      &::before {
        position: relative;
        top: 10px;
      }
    }
  }

  .form-container {
    .kmb-speaker,
    .kmb-subscription {
      .more-speakers,
      .remove-speaker {
        button.btn {
          color: var(--secondary);
          font-size: 12px;
        }
      }

      .remove-speaker {
        margin-bottom: 30px;
      }

      .speaker-wrapper > .form-group {
        margin: 0;
      }

      .new-speaker-wrapper {
        &::after {
          content: "";
          height: 5px;
          background-color: white;
          display: block;
          left: 0;
          margin: 0 -50px 30px;
        }
      }
    }

    .kmb-sessionType,
    .kmb-speaker,
    .kmb-subscription {
      background-color: #f5f9fd;
      padding: 35px 50px;
      margin: 0 -50px;

      .clearfix {
        > div {
          padding: 0;
        }

        button.btn {
          width: 100%;
          background-color: transparent;
          color: var(--secondary);
          font-size: 14px;
          font-weight: 500;
          border: 1px solid var(--secondary);
          box-shadow: none;
          padding: 8px 15px;

          &.selected {
            background-color: var(--secondary);
            color: white;
          }
        }
      }

      label.label-inner {
        color: #96a1b0;
        font-size: 14px;
        font-weight: 400;
      }

      .sessiontype-wrapper,
      .speaker-wrapper {
        margin-top: 25px;
      }
    }
  }

  .kmb-cosmetic {
    span.collapse-session {
      font-size: 8px;
      background-color: #425167;
      width: 18px;
      height: 18px;
      border-radius: 100%;
      color: white;
      cursor: pointer;
      transition: transform 0.2s ease-in-out;

      &.collapsed {
        padding-top: 1px;
        background-color: #8e97a4;
      }

      &:hover {
        transform: rotate(180deg);
      }
    }
  }

  table {
    td.kmb-status {
      text-transform: capitalize;
    }
  }

  .right-area {
    .btn {
      float: right;
      width: 150px;
    }
  }

  .kmb-endDate,
  .kmb-startDate {
    text-align: center;

    p {
      margin-bottom: 0;
    }

    span.hour {
      font-size: 12px;
      font-weight: 300;
    }
  }

  td.kmb-name {
    color: var(--primary);
  }

  .speeches {
    .speech-header {
      display: flex;
      justify-content: space-between;
      padding: 15px;

      .title {
        margin: 0;
      }
    }

    &.empty {
      padding: 25px 0;

      > .clearfix {
        margin: 0;
      }
    }

    > .clearfix {
      margin-bottom: 30px;
    }

    p.title {
      color: var(--primary);
      font-size: 20px;
      margin: 0;
      padding-left: 15px;
    }

    table {
      background-color: transparent;

      td.kmb-cosmetic {
        padding: 15px 8px 15px 25px;
      }

      p {
        margin: 0;
      }
    }

    .kmb-cosmetic {
      span {
        color: #a0d6e9;
      }
    }
  }
}
