.judge-listing {
  div.right {
    flex: unset !important;
  }

  .breadcrumbs {
    margin-bottom: 0;

    span.abstract-name {
      display: block;
      width: calc(100vw - 390px);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  table td.kmb-eventName {
    max-width: 350px;
  }

  .judge-score {
    .error {
      background-color: var(--error-background);
      color: var(--error-color);
      padding: 12px;
      margin-top: 12px;
      border: 1px solid var(--error-color);
      border-radius: 4px;
    }

    .success {
      background-color: var(--success-background);
      color: var(--success-color);
      margin-top: 12px;
      padding: 12px;
      border: 1px solid var(--success-color);
      border-radius: 4px;
    }
  }

  .internal-status {
    &::before {
      content: "";
      height: 7px;
      width: 7px;
      display: inline-block;
      border-radius: 50%;
      margin-right: 5px;
    }

    &.rated {
      &::before {
        background-color: #18efb0;
      }
    }

    &.awaiting-your-review {
      &::before {
        background-color: #1ca4cf;
      }
    }

    &.under-review--draft {
      &::before {
        background-color: #ff5d01;
      }
    }

    &.awaiting-resubmission {
      &::before {
        background-color: #c40000;
      }
    }
  }
}

.component-wrapper.abstract {
  padding-top: 0;
}

.kmb-deadline {
  .deadline {
    >span {
      padding-left: 10px;
      color: #ffd452;
      font-size: 19px;
      position: relative;
      top: 4px;
    }
  }
}

.page-abstract {
  .downloads {
    >span {
      background-color: white;
      display: inline-block;
      padding: 12px;
      border-radius: 4px;
      font-size: 14px;
      margin-right: 15px;
      color: var(--secondary);
      display: flex;
      gap: 5px;
      cursor: pointer;

      & i {
        font-size: 18px;
      }

      &:hover {
        background-color: var(--primary-tone-4);
      }
    }
  }

  .tab-singleabstract {
    padding: 25px 15px;

    div.title {
      p {
        margin-bottom: 2px;

        span {
          font-weight: 500;
        }
      }
    }
  }

  button.btn {
    height: 40px;
    min-width: 130px;
    width: auto;

    &.disabled {
      cursor: default;
      pointer-events: none;
      opacity: 0.4;
    }
  }

  .actions {
    margin-top: 25px;
    display: flex;
    justify-content: flex-end;
  }

  p.description {
    margin-top: 25px;
  }

  .form-container {
    input[type="number"] {
      width: auto;

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        opacity: 1;
        height: auto;
      }

      appearance: normal;
    }
  }

  .box {
    background-color: white;
    padding: 15px;
    box-shadow: 0 2px 4px 0 #ebeff3;

    >label {
      margin-bottom: 15px;
    }

    >div {
      padding: 0;
      box-shadow: none;
    }

    &.files {
      .file {
        margin-bottom: 3px;
      }

      a {
        >i {
          padding-right: 7px;
        }
      }
    }

    &.history {
      background-color: transparent;
      box-shadow: none;
      padding: 0;

      .revision-wrapper {
        padding-left: 80px;
        position: relative;
      }

      .revision {
        margin-bottom: 25px;
        box-shadow: 0 2px 4px 0 #ebeff3;
        padding: 15px;
        background-color: white;

        &.new-comment {
          background-color: transparent;
          box-shadow: none;
          padding: 0;

          .textarea-wrapper {
            box-shadow: 0 2px 4px 0 #ebeff3;
            background-color: white;
          }

          textarea {
            width: 100%;
            display: block;
            padding: 10px;
          }

          .extra-actions {
            margin-top: 10px;
            max-width: 300px;

            >div {
              margin-bottom: 10px;
            }
          }
        }

        &::before {
          position: absolute;
          left: 0;
          content: "";
          display: block;
          border-radius: 50%;
          height: 50px;
          width: 50px;
          border: 2px solid #9f80d3;
        }

        .date {
          color: var(--secondary-tone-4);
        }

        .comment {
          color: #153447;
        }
      }
    }
  }

  >main {
    background-color: #f9f9f9;
  }
}

.page-abstracts {
  table {
    tbody>tr {
      cursor: pointer;

      &.rated {
        opacity: 0.6;
      }
    }
  }
}
