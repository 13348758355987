.event-loader {
  &.container {
    padding: 0;
  }

  .wrapper {
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .header {
    width: 100vw;
    height: 55px;
    background: var(--event-color);
  }

  .main {
    display: flex;
    flex: 1;
    background: var(--event-color);
  }

  .left {
    width: 200px;
    background: var(--event-color);
  }
  @media (max-width: 992px) {
    .left {
      width: 49px;
    }
  }

  .right {
    flex: 1;
    background: var(--background);
    border-top-left-radius: 16px;
    display: flex;
    flex-direction: column;
  }

  .top {
    width: 100%;
    height: 52px;
    background: #a1a8b3;
    border-top-left-radius: 16px;
  }

  .bottom {
    width: 100%;
    flex: 1;
  }
}
