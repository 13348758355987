.tags-input {
  .select2-tags-container {
    flex: 1;
  }
  border: 1px solid var(--primary);
  border-radius: 4px;
  width: 100%;
  max-width: 600px;
  min-width: 100%;
  position: relative;

  .main {
    max-height: 400px;
    overflow: auto;
  }

  .DraggableTags {
    overflow: hidden;
  }

  .inputs {
    input {
      height: 30px;
      border: none !important;
      max-width: 100%;
      padding: 5px;
      width: 100%;
    }

    input:focus {
      border: none !important;
      outline: none !important;
    }
  }

  .tag {
    word-break: break-word;
    color: var(--primary);
    padding: 0 10px;
    background-color: var(--primary-tone-5);
    border-radius: 16px;
    margin: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      display: flex;
      padding: 5px;
      margin-left: 3px;
      font-size: 16px;
      cursor: pointer;
      font-weight: 400;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .icon-close:hover {
      background-color: var(--remove-background-color);
    }
    .icon-edit:hover {
      background-color: var(--secondary-tone-3);
    }
  }
}
