.form-container-label {
  color: var(--primary) !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  margin-bottom: 13px !important;
}

.section-abstractslist {
  .form-group .form-group {
    margin: 0;
  }

  .form-group.has-error {
    .RichEditor-root {
      border: 1px solid var(--error-color);
      box-shadow: none;
      color: var(--error-color);
    }
  }
}

.group-container {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;

  &.two .form-group {
    flex: calc(50% - 12px);
  }

  &.three .form-group {
    flex: calc(33% - 12px);
  }

  &.four .form-group {
    flex: calc(25% - 12px);
  }

  .form-group {
    flex: 1;
  }
}

.form-group {
  .search-wrapper {
    width: 100%;
    max-width: 100%;
  }

  div.form-details {
    font-size: 12px;
    color: var(--black-tone-1);
    padding-bottom: 5px;
  }

  p.form-info {
    border: 1px solid var(--primary-tone-4);
    padding: 10px;
    border-radius: 5px;
    min-height: 40px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    white-space: normal;
  }

  div.form-info {
    color: var(--primary);
    background-color: #fff;
    border-radius: 5px;
    font-size: 14px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 4px;

    p {
      margin: 0;
    }
  }

  position: relative;

  .type-switch {
    label {
      margin: 0;
    }

    .switch-holder {
      text-align: right;
      margin-top: -20px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      > div {
        display: inline-block;
      }
    }
  }

  .type-textarea {
    span.icon {
      top: 45px;
    }
  }

  .kmb-iban {
    .form-group > div {
      position: relative;
    }
  }

  .kmb-select {
    .locate-me {
      position: absolute;
      right: 0;
      padding: 2px 5px 2px 10px;
      font-size: 12px;
      top: -28px;

      span {
        font-size: 14px;
        padding-left: 5px;
      }
    }
  }

  .kmb-copy {
    cursor: pointer;
    font-size: 10px;
    color: var(--primary-tone-3);
    bottom: 10px;

    > span {
      font-size: 14px;
      position: relative;
      top: 3px;
      padding-right: 5px;
    }

    &:hover {
      color: #14da9e;
    }
  }

  .kmb-token {
    .create-new {
      background-color: #f1f7f9;
      border-radius: 4px;
      color: var(--secondary);
      padding: 6px 10px;
      font-size: 11px;
      cursor: pointer;
      transition: 0.2s background-color ease-in-out;

      &:hover {
        background-color: #e5f8ff;
      }

      > span {
        position: relative;
        top: 2px;
        padding-right: 5px;
      }
    }

    .subtitle {
      display: block;
    }

    input.form-control {
      background-color: white;
      padding-right: 80px;
    }

    .copy-token {
      position: absolute;
      right: 15px;
      cursor: pointer;
      font-size: 10px;
      color: var(--primary-tone-3);
      bottom: 10px;

      > span {
        font-size: 14px;
        position: relative;
        top: 3px;
        padding-right: 5px;
      }

      &:hover {
        color: #14da9e;
      }
    }
  }
}

select,
select.form-control {
  height: 38px;
  border: 1px solid var(--primary-tone-3);
  border-radius: 3px;
  background-color: #fff;
}

.kmb-slider {
  .input-range__slider {
    width: 20px;
    height: 20px;
    background-color: var(--secondary);
    border: 4px solid white;
    box-shadow: 0 2px 8px 0 rgb(53 67 84 / 27%);
    margin-top: -12px;
  }

  .input-range__track--active {
    background: var(--secondary);
  }

  .input-range__label-container {
    display: none;
  }

  > div {
    width: calc(100% - 100px);
  }

  .outer-input {
    width: 90px;

    input {
      width: 60px;
      display: inline-block;
      padding: 5px !important;
      margin-right: 5px;

      &:focus {
        outline: none;
      }
    }

    span {
      display: inline-block;
      width: 15px;
    }
  }
}

.has-error.error-select-handler,
.has-error > div > input,
.has-error > div > div > input {
  border-radius: 4px;
  border: 1px solid $error_color !important;
  box-shadow: none !important;
}

.has-error > div > div:has(> input[type="file"]),
.form-container
  > div.has-error
  > div
  > div:has(div > input[id^="react-select"]) {
  border: 1px solid $error_color !important;
  border-radius: 5px;
  box-shadow: none !important;
}

body .react-tagsinput {
  &.has-error {
    border: 1px solid $error_color !important;
    box-shadow: none !important;
  }

  .react-tagsinput-tag {
    &.has-error {
      border: 1px solid $error_color !important;
      box-shadow: none !important;
      color: $error_color !important;

      .react-tagsinput-remove {
        color: $error_color !important;
      }
    }
    background-color: transparent;
    border-color: var(--secondary);
    color: var(--secondary);
  }

  padding: 6px;
  border: 1px solid var(--primary-tone-3);
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 7.5%);
  border-radius: 2px;
  box-sizing: border-box;

  &.react-tagsinput--focused {
    border-color: var(--primary-tone-3);
    outline: 0;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 7.5%),
      0 0 8px rgb(102 175 233 / 60%);
  }

  input.react-tagsinput-input {
    margin: 0;
    border: none !important;
    width: 100%;
    box-shadow: none;
    padding: 0 !important;
  }
}

.form-container {
  h2 {
    margin-bottom: 30px;
  }

  label {
    color: var(--primary);
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 4px;
  }

  textarea {
    border-radius: 2px;
    border: 1px solid var(--primary-tone-3);
  }

  input[type="number"],
  input[type="password"],
  input[type="email"],
  input[type="text"] {
    border: 1px solid var(--primary-tone-3);
    border-radius: 2px;
    background-color: #fff;
    height: 40px;
  }

  input[type="number"] {
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      appearance: none;
      margin: 0;
    }

    appearance: textfield;
  }

  .radio-wrapper {
    display: inline-block;

    label {
      margin: 0;
      color: var(--primary);
      font-weight: 400;
      padding-left: 5px;
    }
  }

  .subtitle {
    display: block;
    color: #96a1b0;
    font-size: 14px;
    margin-bottom: 10px;
  }

  .btn.cancel {
    margin-right: 20px;
  }

  & > form > .form-group,
  & > .form-group,
  & > div > .form-group {
    background: white;
    padding: 12px;
    border-radius: 5px;
    margin-bottom: 16px;

    &.duration {
      margin-bottom: 20px;
    }

    input {
      padding-right: 25px;
      min-width: 120px;
    }

    input,
    textarea {
      box-shadow: none;

      &:hover {
        border: 1px solid #96a1b0;
      }

      &:focus {
        border: 1px solid var(--secondary);

        ~ span {
          color: var(--secondary);
        }
      }
    }

    &.has-error,
    .form-group.has-error {
      input,
      textarea,
      .type-text input,
      .type-select .css-dt7kyz-control,
      .kmb-select .css-dt7kyz-control {
        border-color: $error_color;
      }

      .css-dm7ar9-placeholder,
      .css-tlfecz-indicatorContainer {
        color: $error_color;
      }

      ::input-placeholder,
      ::placeholder,
      :input-placeholder,
      :placeholder {
        color: $error_color;
      }

      .ignore-error {
        &:focus {
          box-shadow: none;
        }

        &::input-placeholder,
        &::placeholder,
        &:input-placeholder,
        &:placeholder {
          color: #999;
        }
      }

      .upper-area,
      input,
      textarea {
        &:not(.ignore-error) {
          border: 1px solid $error_color;
          box-shadow: none;
          color: $error_color;
        }
      }

      .field-type {
        > div {
          > span {
            color: $error_color;
          }
        }
      }
    }
  }

  .field-type {
    position: relative;

    &.type-datetime {
      .rdtOpen {
        ~ span {
          color: var(--secondary);
        }
      }
    }

    > div,
    div.date-icon-holder {
      position: relative;

      span.icon {
        position: absolute;
        right: 10px;
        bottom: 12px;
        color: var(--primary-tone-3);
        font-size: 17px;
        transition: color 0.2s ease-in-out;
        pointer-events: none;
      }
    }
  }
}

.help-block {
  color: $error_color !important;
  font-size: 12px;
}

$md-radio-checked-color: var(--secondary-tone-1);
$md-radio-border-color: var(--primary-tone-3);
$md-radio-size: 18px;
$md-radio-checked-size: 8px;
$md-radio-ripple-size: 10px;

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 1px rgb(0 0 0 / 0%);
  }

  50% {
    box-shadow: 0 0 0 $md-radio-ripple-size rgb(0 0 0 / 10%);
  }

  100% {
    box-shadow: 0 0 0 $md-radio-ripple-size rgb(0 0 0 / 0%);
  }
}

.kmb-range {
  .range-wrapper {
    &.has-duration {
      padding-right: 100px;

      .duration-holder {
        position: absolute;
        right: 0;
        width: 85px;
        top: 27px;
        height: 110px;

        > .vertical-center {
          height: 100%;

          &::before {
            margin: 0;
          }
        }
      }

      .duration-wrapper {
        text-align: center;
        width: 100%;
        position: relative;

        &::before,
        &::after {
          width: 40px;
          height: 6px;
          border-style: solid;
          border-color: var(--primary);
          content: "";
          display: block;
        }

        &::before {
          border-width: 1px 1px 0 0;
        }

        &::after {
          border-width: 0 1px 1px 0;
          margin-top: 4px;
          height: 10px;
        }

        > span {
          color: var(--primary);
          font-size: 12px;
          line-height: 21px;
        }

        .select-advanced {
          height: 30px;

          .select-checkboxes-outer {
            height: 30px;
          }

          .options-wrapper {
            max-height: 155px;
            overflow: auto;

            > li {
              padding: 5px;
            }
          }

          .bottom-area {
            width: 110px;
            right: 0;
            left: unset;
          }

          .upper-area {
            height: 30px;
            background-color: var(--secondary);
            border-radius: 2px;
            font-size: 14px;
            color: white;
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;

            > span.directional {
              position: unset;
              padding-left: 5px;
              color: white;
              top: 12px;
            }
          }
        }
      }
    }
  }

  .type-datetime {
    > div {
      position: relative;
    }
  }
}

.field-type.type-section.kmb-section {
  background: white;
  margin: -12px;
  padding: 20px 0 0;
}

.kmb-radio {
  margin: 0;

  input[type="radio"] {
    display: none;

    &:checked + label::before {
      border-color: $md-radio-checked-color;
      animation: ripple 0.2s linear forwards;
    }

    &:checked + label::after {
      transform: scale(1);
    }

    &:checked + label {
      color: var(--primary);
    }
  }

  .radio-wrapper {
    label {
      display: inline-block;
      height: $md-radio-size;
      position: relative;
      padding: 0 ($md-radio-size + 10px);
      margin-bottom: 0;
      cursor: pointer;
      vertical-align: bottom;

      &::after,
      &::before {
        position: absolute;
        content: "";
        border-radius: 50%;
        transition: all 0.3s ease;
        transition-property: transform, border-color;
      }

      &::before {
        left: 0;
        top: 0;
        width: $md-radio-size;
        height: $md-radio-size;
        border: 2px solid $md-radio-border-color;
      }

      &::after {
        top: calc($md-radio-size / 2 - $md-radio-checked-size / 2);
        left: calc($md-radio-size / 2 - $md-radio-checked-size / 2);
        width: $md-radio-checked-size;
        height: $md-radio-checked-size;
        transform: scale(0);
        background: $md-radio-checked-color;
      }
    }
  }
}

.kmb-checkbox {
  input[type="checkbox"] {
    position: absolute;
    opacity: 0;

    &:not(:checked) {
      + label {
        &::before {
          width: 0;
          height: 0;
          border: 3px solid transparent;
          left: 6px;
          top: 10px;
          transform: rotateZ(37deg);
          transform: rotateZ(37deg);
          transform-origin: 100% 100%;
          transform-origin: 100% 100%;
        }

        &::after {
          height: 15px;
          width: 15px;
          background-color: transparent;
          border: 1px solid var(--primary-tone-2);
          border-radius: 2px;
          top: 0;
          z-index: 0;
        }
      }
    }

    + label {
      position: relative;
      padding-left: 25px;
      cursor: pointer;
      display: inline-block;
      height: 15px;
      user-select: none;
      user-select: none;
      user-select: none;
      user-select: none;
      color: var(--primary);
      font-size: 14px;
      line-height: 14px;
      font-weight: 400;
      margin-bottom: 0;

      &::after,
      &::before {
        content: "";
        left: 0;
        position: absolute;
        transition: border 0.25s, background-color 0.25s, width 0.2s 0.1s,
          height 0.2s 0.1s, top 0.2s 0.1s, left 0.2s 0.1s;
        transition: border 0.25s, background-color 0.25s, width 0.2s 0.1s,
          height 0.2s 0.1s, top 0.2s 0.1s, left 0.2s 0.1s;
        z-index: 1;
      }

      &::after {
        height: 15px;
        width: 15px;
        background-color: transparent;
        border: 2px solid #5a5a5a;
        top: 0;
        z-index: 0;
      }
    }

    &:checked {
      + label {
        &::before {
          top: 1px;
          left: 1px;
          width: 6px;
          height: 11px;
          border-top: 2px solid transparent;
          border-left: 2px solid transparent;
          border-right: 2px solid #fff;
          border-bottom: 2px solid #fff;
          transform: rotateZ(37deg);
          transform: rotateZ(37deg);
          transform-origin: 100% 100%;
          transform-origin: 100% 100%;
        }

        &::after {
          top: 0;
          height: 15px;
          width: 15px;

          /* previus color 1da4cf */
          border: 2px solid $md-radio-checked-color;
          background-color: $md-radio-checked-color;
          z-index: 0;
          border-radius: 2px;
        }
      }
    }

    &:disabled {
      + label {
        opacity: 0.4;
        cursor: not-allowed;
      }
    }
  }
}
