.page-rentable-scanning {
  .switch-toggle-handle {
    margin-top: 2px;
  }
}
.page-rentable-scanning {
  .actions-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    flex: 1;
  }
  .action-buttons {
    display: flex;
    padding: 0 !important;
    justify-content: flex-start;
    flex-direction: row-reverse;
    gap: 10px;
  }
  .settings-toggler {
    font-size: 25px;
    color: #005683;
    cursor: pointer;
    opacity: 0.7;
    &.toggled {
      opacity: 1;
    }
  }
}

.scanning-page,
.page-minutes-scanning,
.page-rentable-scanning,
.page-room-scanning {
  &.page-room-scanning {
    .component-wrapper.room-scanning.in {
      flex: 1;
    }

    .settings-toggler {
      margin-top: 3px;
      font-size: 22px;
      color: #005683;
      cursor: pointer;
      float: right;
      width: 50px;
      opacity: 0.7;

      &.toggled {
        opacity: 1;
      }
    }

    .inner-settings {
      margin-right: 50px;
      width: auto;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      gap: 12px;
    }
  }

  .minutes-scanning {
    .poster {
      > div {
        position: relative;
        display: inline-block;

        .scanning-banner {
          > .vertical-center {
            height: 100%;

            &::before {
              margin: 0;
            }
          }

          position: absolute;
          top: 130px;
          left: 22px;
          width: calc(100% - 78px);
          height: calc(100% - 183px);
        }
      }
    }

    h1 {
      color: #135893;
      font-size: 24px;
      font-weight: 400;

      > span {
        font-weight: 700;
        display: block;
      }

      margin: 0;
    }

    .text-container {
      margin-bottom: 80px;
    }

    .user-scanned {
      h1 {
        color: #19d78c;
        margin-bottom: 25px;

        > span {
          display: inline-block;
        }
      }

      p.minutes {
        font-size: 24px;
        color: #105995;

        > span {
          font-weight: 700;
        }
      }
    }

    .image-holder {
      position: absolute;
      bottom: 80px;
    }

    .row {
      display: flex;

      .scan-area {
        .vertical-center {
          height: 100%;
        }
      }
    }
  }

  .top-bar {
    background-color: #f7f9fc;
    box-shadow: inset 0 -1px 0 0 #dbe3f0;
  }

  .offline-panel {
    background-color: $error_color;

    h4 {
      margin: 15px 0;
      font-weight: 400;
      color: white;
      font-size: 16px;

      span {
        font-weight: 700;
      }
    }

    ul {
      padding-left: 0;
      margin: 0 0 15px;

      li {
        display: inline-block;

        &:first-child {
          margin-right: 10px;
        }
      }
    }

    .btn {
      background-color: rgb(255 255 255 / 40%);
      border: none;
      box-shadow: none;
      transition: all 0.2s ease-in-out;

      &:hover {
        background-color: rgb(255 255 255 / 90%);
        color: var(--error-color);
      }
    }
  }

  .not-found {
    color: var(--primary);
    font-size: 28px;
    text-align: center;
    margin-top: 160px;

    > span {
      font-size: 150px;
      display: block;
      color: var(--error-color);
    }
  }

  .scan-table {
    margin-top: 40px;

    tr {
      > td {
        font-size: 16px;
        font-weight: 500;
        color: white;
        box-shadow: inset 0 -2px 0 0 rgb(0 0 0 / 4%);
        padding: 8px;

        &.scan-type {
          > div {
            background-color: rgb(0 0 0 / 30%);
            display: inline-block;
            padding: 5px 20px;
            border-radius: 4px;
          }
        }

        &:first-child {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }

        &:last-child {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }

      &.type-exit {
        > td {
          background-color: var(--error-color);
        }
      }

      &.type-enter {
        > td {
          background-color: #14da9e;
        }
      }

      &.type-offline {
        > td {
          background-color: #1182be;
        }
      }
    }
  }

  .action-buttons {
    padding: 27px 15px;

    > .btn,
    .confirm-holder {
      display: inline-block;
    }

    .btn {
      padding: 6px 20px;
      font-size: 12px;
    }

    > .btn {
      margin-right: 0;
    }
  }

  .informations {
    padding: 15px;

    > div {
      display: inline-block;
      padding-right: 50px;

      &:last-child {
        padding-right: 0;
      }

      label {
        color: #97a7c2;
        font-size: 14px;
        font-weight: 500;
      }

      p {
        margin: 0;
        color: var(--primary);
        font-size: 24px;
      }
    }
  }
}
