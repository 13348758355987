.independent-table-container {
  border: 1px solid rgb(218 228 240);
  border-radius: 16px;
  padding: 0 10px;
  flex: 1;
  width: 100%;

  .med-table-container {
    padding-top: 12px;
  }

  .it-title {
    background: var(--background-content);
    padding: 0 5px;
    position: absolute;
    top: -12px;
    left: 10px;
    text-transform: uppercase;
  }

  .it-add-btn {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }

  & > div {
    position: relative;
  }
}
