.create-user,
.bulk-import {
  h2 {
    margin-bottom: 5px;
  }
  .information-row {
    background: white;
    box-shadow: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    min-width: 100%;
    padding-top: 5px;
    z-index: 10000;
    user-select: none;
    position: relative;
  }
  td.kmb-name {
    width: 460px;
  }
  p.subtitle {
    font-size: 14px;
    color: #96a1b0;
    font-weight: 400;
    margin-bottom: 30px;
  }

  .submit-container {
    .btn {
      width: auto;
      margin-right: 15px;
    }
  }
}

.policy-actions > .row {
  margin-bottom: 20px;
}

.bulk-import {
  .exportables {
    a:first-child {
      &::after {
        content: "/";
        padding: 0 5px;
      }
    }
  }

  .submit-container {
    .save {
      &.disabled {
        pointer-events: none;
      }
    }
  }

  .guide,
  .drop-area {
    margin-bottom: 30px;

    h3 {
      font-size: 14px;
      color: var(--secondary);
      font-weight: 500;
    }

    > p {
      color: #797a7b;
      font-size: 14px;
    }
  }

  .preview-panel {
    margin-top: 14px;
    > p {
      margin-top: 15px;
      color: #797a7b;
      font-size: 14px;
    }
  }

  .available-fields,
  .selected-fields,
  .available-rooms,
  .available-sessiontypes,
  .csv-seperator,
  .ignore-first {
    margin-top: 14px;

    h3 {
      color: var(--primary);
      font-size: 14px;
      font-weight: 500;
      text-transform: uppercase;
      border-bottom: 1px solid #dae4f0;
      padding-bottom: 10px;
    }
  }

  .available-rooms,
  .available-sessiontypes {
    div {
      padding: 15px 15px 0;
      background-color: #f5f9fd;
      max-height: 240px;
      overflow-y: auto;
    }
  }

  .drop-area {
    .kmb-dropzone {
      .vertical-center {
        padding: 0;
      }

      .prompt-message,
      .details {
        display: inline-block;
        margin: 0;
        color: var(--secondary);
      }

      .details {
        font-size: 14px;
        padding-left: 40px;

        span {
          font-weight: 500;
        }
      }

      .text-area {
        position: relative;
      }

      .prompt-message {
        position: absolute;
        top: -8px;
        left: 0;

        span {
          font-size: 30px;
        }
      }
    }
  }

  .selected-fields {
    > p {
      font-size: 12px;
    }

    a.export-template {
      font-size: 14px;
    }

    ol {
      padding-left: 15px;
      list-style: none;
      counter-reset: li;
      margin-bottom: 25px;

      li {
        border-radius: 3px;
        background-color: #f5f9fd;
        box-shadow: inset 2px 0 0 0 #a0d6e9;
        counter-increment: li;
        padding: 15px;
        position: relative;
        margin-bottom: 5px;
        color: var(--secondary);

        &::before {
          content: counter(li);
          color: #56b8d9;
          display: inline-block;
          font-size: 12px;
          position: absolute;
          left: -15px;
          border-bottom: 1px solid #edf1f5;
        }
      }
    }
  }

  .available-fields {
    ul {
      padding-left: 0;
      list-style-type: none;

      li {
        border-bottom: 1px solid #edf1f5;
        padding: 15px;
        background: white;

        label {
          color: var(--primary);
          font-size: 14px;
        }
      }
    }

    .kmb-checkbox {
      input[type="checkbox"] {
        + label {
          padding: 0;
          display: block;

          &::before,
          &::after {
            left: auto;
          }

          &::before {
            right: 8px;
          }

          &::after {
            right: 0;
          }
        }
      }
    }
  }
}

.extra-fields {
  h4 {
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 25px;
    margin-top: 0;
  }

  p.subtitle {
    color: var(--secondary);
    font-weight: 500;
  }

  .table {
    th {
      font-size: 12px;
      color: #798eab;
      font-weight: 400;

      &:first-child {
        text-align: left;
      }
    }

    tr {
      cursor: pointer;
    }

    span.fa {
      cursor: pointer;
      font-size: 9px;
      color: #798eab;
      padding-right: 5px;
    }

    > tbody > tr > td {
      color: var(--primary);
      font-size: 14px;
      padding: 15px 8px;
      line-height: normal;
      vertical-align: middle;

      &:first-child {
        text-align: left;
      }
    }
  }

  .add-fields {
    .select-advanced {
      display: inline-block;
    }

    .upper-area {
      display: inline-block;
      color: var(--primary-tone-2);
      padding: 7px 30px;
      font-size: 14px;

      &:hover {
        background-color: var(--primary-tone-4);
      }

      > span {
        > span {
          font-size: 10px;
          padding-right: 10px;
        }
      }
    }

    .bottom-area {
      text-align: left;
    }
  }

  .pool {
    p.empty {
      border: 1px solid #e6edf5;
      text-align: center;
      border-radius: 3px;
      padding: 84px 30px;
      color: #a7b7ce;
      font-size: 12px;
      font-style: italic;
    }
  }

  table {
    td {
      &.has-error {
        input[type="text"] {
          border: 1px solid $error_color;
          box-shadow: none;
          box-shadow: none;
          color: $error_color;
        }
      }

      &:last-child {
        .fa {
          font-size: 20px;
          color: var(--secondary);
        }
      }
    }

    .form-group {
      margin-bottom: 0;
    }

    thead > tr > th {
      &:first-child {
        width: 120px;
      }

      &:nth-child(2) {
        width: 180px;
      }

      &:nth-child(6) {
        width: 150px;
      }
    }

    input[type="text"] {
      font-size: 14px;
      font-style: italic;
      padding: 6px;
      height: auto;
      width: 100%;
      margin: 0;
    }
  }
}

.tab-accesspolicies {
  .free-access-toggle {
    > div {
      display: inline-block;
      border: 1px solid #a0d6e9;
      border-radius: 3px;
      padding: 7px 15px;

      > span {
        color: var(--secondary);
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
        float: left;
        padding-top: 2px;
        padding-right: 10px;
      }

      .onoffswitch {
        float: left;
      }
    }
  }

  .table {
    th {
      &.kmb-manageUsers,
      &.kmb-setupPolicy {
        width: 180px;
      }

      > tbody {
        > tr {
          > td {
            &.kmb-type {
              text-transform: capitalize;
            }
          }

          &.kmb-manageUsers,
          &.kmb-setupPolicy {
            button,
            a {
              span {
                position: absolute;
                top: 50%;
                left: 17px;
                margin-top: -9px;
                font-size: 17px;
              }
            }
          }

          &.kmb-setupPolicy {
            button span {
              font-size: 15px;
            }
          }
        }
      }
    }
  }
}

.new-policy {
  padding-bottom: 110px;
  position: relative;

  .fields {
    table {
      tr > th:last-child {
        width: 130px;
      }

      tr > td:last-child {
        padding: 0 8px;

        input {
          height: auto;
        }
      }
    }
  }

  .fields-crm {
    table {
      tr > td:last-child {
        padding: 0 8px;

        input {
          height: auto;
        }
      }
    }
  }

  .submit-container {
    position: absolute;
    bottom: 30px;
    text-align: right;
    margin-bottom: 0;
    width: 100%;
  }

  p.subtitle {
    color: var(--secondary);
    font-weight: 500;
  }

  h4 {
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 25px;
  }

  .table {
    th {
      font-size: 12px;
      color: #798eab;
      font-weight: 400;
      text-align: center;

      &:first-child {
        text-align: left;
      }
    }

    tr {
      cursor: pointer;
    }

    span.fa {
      cursor: pointer;
      font-size: 9px;
      color: #798eab;
      padding-right: 5px;
    }

    > tbody > tr > td {
      color: var(--primary);
      font-size: 14px;
      padding: 15px 8px;
      line-height: normal;
      text-align: center;
      vertical-align: middle;
      padding-left: 10px;

      &:first-child {
        text-align: left;
      }
    }
  }
}
