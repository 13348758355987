.whole-page-wrapper {
  display: none !important;
}

@media print {
  .app-wrapper {
    display: none !important;
  }

  .whole-page-wrapper {
    &.printing {
      display: block !important;

      .page {
        page-break-after: always !important;
      }

      .whole-page-wrapper {
        visibility: visible;
      }

      .reverse-page {
        transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
      }
    }
  }
}
