:root {
  --background: #fff;
  --background-content: #f0f0f4;
  --primary: #153447;
  --primary-tone-1: var(--primary);
  --primary-tone-2: #d1def1;
  --primary-tone-3: #d5d9df;
  --primary-tone-4: #f0f0f4;
  --primary-tone-5: #e5e8ea;
  --table-box-shadow: #96a1b066;

  --secondary: #2a79d1;
  --secondary-tint-1: #14539e;
  --secondary-tone-1: #57aeff;
  --secondary-tone-2: #56b8d9;
  --secondary-tone-3: #e6f4f9;
  --secondary-tone-4: #f5f9fd;

  --secondary-shade-4: #b1d0f0;

  --header-color: #2c3644;
  --event-color: #425167;
  --event-menu-not-focused-text: #495a72;
  --tab-items: #d4e4f6;
  --black: #000;
  --black-tone-1: #797a7b;
  --black-tone-4: #adb5c0;
  --black-tone-2: #bcbcbd;
  --black-tone-3: #e8e8e9;

  --text-disabled: #bcbcbd;
  --gray-600: #6d6976;
  --gray-500: #928f98;
  --gray-200: #bdc6cd;
  --tab-header-menu: #516673;
  --table-hover-shadow: 0 0 7px 0px hsl(215deg 14% 64% / 50%);
  --success: #14da9e;
  --active-ok: #81e1da;
  --active-not: #ff9900;
  --warning: #ff775c;
  --warning-color: #ff8c00;

  --success-color: #088147;
  --success-background: #d1f6df;
  --error-color: #f04438;
  --error-background: #f6d1d1;
  --remove-background-color: #ffbdad;
  --notification-color: #ff775c;
  --header-box-shadow: -1px -3px 20px 4px var(--background-content);
  --event-status-upcoming: #b1d0f0;
  --event-status-completed: #b1f0bf;
  --event-status-running: #f0b1b1;
}

html {
  --scrollbarBG: #d6d6d6;
  --thumbBG: #6b7077;
}

::-webkit-scrollbar {
  width: 11px;
  height: 11px;
}

body {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}

::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}

body {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #12b76a;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #f04438;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);
  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);
  --toastify-toast-width: 375px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: "Ubuntu", sans-serif;
  --toastify-z-index: 9999;
  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;
  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;
  --toastify-color-progress-light: linear-gradient(
    to right,
    #4cd964,
    #5ac8fa,
    #007aff,
    #34aadc,
    #5856d6,
    #ff2d55
  );
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);

  .Toastify__toast-container {
    z-index: 1000000000;
  }

  .Toastify__toast {
    padding: 16px;
  }

  .Toastify__progress-bar {
    height: 3px;
  }

  .Toastify__toast-body {
    padding: 0px 17px 0px 0px;

    & > div > div {
      word-break: break-word;
      word-wrap: break-word;
    }
  }

  .Toastify__toast-icon {
    align-self: flex-start;
    margin-right: 12px;
  }

  color: var(--primary);
  overflow: hidden !important;
  height: 100vh;
  max-height: 100vh;
  min-height: 100vh;
  font-family: "Ubuntu", sans-serif !important;

  .modal-backdrop {
    z-index: 2040;
  }

  .hidden {
    display: none;
  }

  .main-area {
    display: flex;

    & > .content {
      display: flex;
      flex: 1;

      & .edit-event {
        display: flex;
        flex: 1;
        flex-direction: column;

        .event-edit {
          max-width: 100vw;
          display: flex;
          flex-direction: column;
          flex: 1;

          & > .edit-menu {
            display: flex;
            flex-direction: column;
            flex: 1;

            & .main-grid {
              max-height: calc(100vh - 110px);
              position: relative;
              display: flex;
              flex-direction: row;
              flex: 1;

              .tab-items {
                background-color: var(--event-color);

                &.open {
                  min-width: 200px;
                  max-width: 200px;
                  width: 200px;
                }

                min-width: 49px;
                max-width: 49px;

                button span {
                  font-size: 20px;
                  padding-right: 12px;
                }
              }

              .inner-content {
                transition: max-width, min-width ease 0.2s;
                max-width: calc(100vw - 200px);
                min-width: calc(100vw - 200px);
                flex: 1;

                &.max {
                  max-width: calc(100vw - 49px) !important;
                  min-width: calc(100vw - 49px) !important;
                }

                & > .section {
                  display: flex;
                  flex-direction: column;
                  background-color: var(--background-content);
                  overflow: hidden;
                  position: relative;
                  height: 100%;

                  .tab {
                    overflow: hidden;
                    height: calc(100% - 48px);
                    display: flex;
                    flex-direction: column;

                    .tab-header {
                      &::before {
                        content: "";
                        pointer-events: none;
                        position: absolute;
                        bottom: -21px;
                        left: 0;
                        width: 100%;
                        height: 21px;
                        z-index: 0;
                        background: rgb(210, 210, 217);
                        background: linear-gradient(
                          180deg,
                          rgba(210, 210, 217, 1) 0%,
                          rgba(240, 240, 244, 0) 100%
                        );
                      }

                      position: relative;
                      border-top: 1px solid #e1e1e6;
                      flex-wrap: wrap;
                      width: 100%;
                      background: white;
                      min-height: 52px;
                      z-index: 2;
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                      border-bottom: 1px solid var(--background-content);
                      padding: 4px 24px;
                      flex-wrap: wrap;
                      gap: 4px;

                      .left {
                        display: flex;
                        flex-wrap: wrap;
                        gap: 4px;
                        flex: 1 0 fit-content;
                      }

                      .right {
                        display: flex;
                        justify-content: flex-end;
                        align-items: flex-end;
                        gap: 4px;
                        flex: 1;
                      }
                    }

                    .tab-content {
                      position: relative;
                      box-shadow: var(--header-box-shadow);
                      padding: 24px;
                      flex: 1;
                      overflow: auto;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .submit-container {
    display: flex;
    justify-content: flex-start;
  }

  .modal-content .submit-container {
    justify-content: center;
  }

  .kmb-bulkSelect,
  .kmb-quickEdit {
    min-width: 42px !important;
  }

  /* new styles height and color */
  .bt-select-button > div {
    border-radius: 3px;
    border-color: var(--secondary);
  }

  .breadcrumbs {
    .active {
      color: var(--event-menu-not-focused-text);
      cursor: pointer;

      &:hover {
        color: var(--secondary);
      }
    }

    position: sticky;
    left: 0px;
    margin-bottom: 15px;
    position: sticky;
    left: 0;
    margin-bottom: 15px;
    display: flex;
    gap: 14px;
    font-weight: 500;
    color: var(--gray-500);
    margin-bottom: 17px;

    span {
      display: flex;
      gap: 14px;
      align-items: center;
    }

    .arrow {
      color: #d0d5dd;
      font-size: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .select-button {
    .css-fvfylv-control {
      border: 1px solid var(--secondary);
    }

    .css-6q0nyr-Svg,
    .select-button-placeholder {
      color: var(--secondary);
    }

    .css-1okebmr-indicatorSeparator {
      background-color: var(--secondary);
    }

    .icon-add-1 {
      font-size: 10px;
      padding: 10px;
    }
  }

  input.form-control {
    box-shadow: none;
    border-radius: 4px !important;
    height: 40px;
    color: var(--primary) !important;
  }

  textarea.form-control {
    min-height: 40px;
    border-radius: 4px !important;
    max-height: 400px;
    color: var(--primary) !important;
  }

  .search-advanced,
  .select-advanced,
  .select-checkboxes-outer,
  div.upper-area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    height: 40px;
  }

  /* end */
  .kmb-cosmetic {
    min-width: 35px !important;
  }

  .clearfix:after {
    display: unset;
  }

  .actions-wrapper {
    padding-left: 150px;
    padding-top: 10px;
    padding-right: 16px;
    position: absolute;
    right: 32px;
    width: auto !important;
    display: flex !important;
    opacity: 1;
    z-index: 1;
    pointer-events: auto;
    top: 50%;
    transform: translateY(-50%);
    background: linear-gradient(
      to left,
      rgba(255, 255, 255, 1) 50%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  .actions-wrapper.hidden {
    opacity: 0;
    pointer-events: none;
  }

  .modal {
    z-index: 2050;
  }

  .no-cover-image-cover {
    height: 100% !important;
    color: #333;

    & .kmb-dropzone {
      height: 100% !important;
      height: 100% !important;

      & .zone-area {
        background-position-y: 20px;
        height: 100% !important;
      }
    }
  }

  .warning-message {
    width: 100%;
    min-height: 40px;
    background: #fcb138;
    color: #fff;
    padding: 20px 15px;
    display: flex;
    text-align: center;
    align-items: center;
    margin-bottom: 20px;
    position: relative;

    &::before {
      content: "i";
      border-radius: 100%;
      border: 2px solid white;
      width: 25px;
      height: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 4px;
      padding-left: 1px;
      font-weight: bold;
      position: absolute;
    }

    & > span {
      margin-left: 35px;
      flex: 1;
    }

    .onoffswitch {
      width: 36px;
      height: 20px;
    }
  }

  textarea {
    max-width: 100% !important;
    min-width: 100% !important;
  }

  .existing-cover {
    max-width: 100%;
  }

  .table-responsive {
    border: none !important;
  }

  .right-area,
  .right-side {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .search-wrapper {
      width: 100%;
    }
  }

  .no-pointer-events {
    pointer-events: none;
  }

  .kmb-flex-center {
    display: flex;
    align-items: center;
    flex: 1;
  }

  .kmb-pointer {
    cursor: pointer;
  }

  .container {
    width: 100%;
  }

  .MuiTooltip-tooltip {
    font-size: 14px;
  }

  .experimental::after {
    width: 3px;
    height: 3px;
    border-radius: 100%;
    background: #005683;
    position: relative;
    display: block;
    left: 100%;
    z-index: 10;
    opacity: 1;
    content: "";
  }

  .breadcrumb-item {
    font-size: 16px;
    background: var(--primary-tone-4);
    padding: 5px;
    border-radius: 16px;
  }

  &.login-page {
    background-color: white;
  }

  .header-client-panel-select {
    .header-client-panel-options {
      display: none;
    }

    & :hover .header-client-panel-options {
      display: block;
    }
  }

  .bottom-line {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    border-bottom: 1px solid #eceff1;
    width: 100%;

    &.header-input {
      span {
        padding-top: 3px;
      }
    }

    & span {
      color: #888;
    }

    & input {
      flex: 1;
      width: 100%;
      border: none;
      box-shadow: none;
      box-shadow: none;

      &:focus {
        box-shadow: none !important;
        outline: none !important;
      }

      padding: 0px 3px;
    }
  }

  .screen-header {
    margin-bottom: 30px;
  }

  .kmb-emails {
    background: #dfe4eb;
    border-radius: 5px;
    position: relative;

    .header-input {
      span {
        display: inline-block !important;
        color: #354152;
        position: absolute;
        pointer-events: none;
        padding-top: 10px;
      }

      color: white;
      font-weight: 400;
      border-radius: 2px;
      outline: none !important;
      box-shadow: none;
      border: none;
      padding: 5px;

      & span {
        font-style: italic;
      }

      & input {
        padding: 5px;
        color: #354152;
        background: none;
        padding-left: 30px;
      }

      & input:focus {
        background: #dfe4eb;
        border-bottom: 1px solid #354152;
      }

      &:focus-within * {
        color: #333;
      }
    }

    .email-row {
      display: flex;
      column-gap: 16px;
    }

    .divider {
      width: calc(100% + 32px);
      height: 1px;
      background-color: #dfe4eb;
      left: -16px;
      position: relative;
      margin-bottom: 8px;
    }

    .editor-label {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #27303e;
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      margin-bottom: 8px;
    }

    .locked-danger-zone {
      border: 1px solid red;
      border-radius: 8px;
      margin-top: 16px;
      padding: 12px;
      display: flex;
      flex-direction: column;

      .danger-zone-title {
        font-size: 20px;
        font-weight: 400;
        margin: 0 0 12px;
        padding: 5px 0;
      }

      .danger-zone-subtitle {
        margin-bottom: 10px;
      }
    }

    .email-actions-wrapper {
      margin-top: 16px;
      display: flex;
      column-gap: 8px;

      .scheduled-indication {
        height: 40px;
        display: flex;
        align-items: center;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #003253;
        margin-left: 8px;
      }
    }

    .header {
      height: 40px;
      display: flex;
      align-items: center;
      padding: 5px 16px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #354152;

      .icon-close {
        position: absolute;
        right: 10px;
        top: 10px;
      }
    }

    .main {
      background: white;
      padding: 16px;

      .tox.tox-tinymce {
        z-index: 0;
        border: 1px solid #dfe4eb !important;
        border-radius: 6px;
      }

      .tox-fullscreen {
        z-index: 10 !important;
      }

      .row-container {
        display: flex;
        flex-direction: column;
        row-gap: 6px;
        width: 100%;
        margin-bottom: 16px;

        &.half-container {
          width: 50%;
        }

        .row-title {
          width: 100%;
          height: 18px;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          color: #354152;
        }

        .row-field {
          height: 40px;
          width: 100%;
          padding: 10px 14px;
          border: 1px solid #dfe4eb;
          box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
          border-radius: 6px;

          &:focus-visible {
            outline: none;
          }

          &.non-input {
            height: max-content;
            min-height: 40px;
          }
        }

        &.select-container {
          > div {
            padding: 0px;
            margin: 0px;
            height: max-content;
            min-height: 40px;
            border: 1px solid #dfe4eb;
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
            border-radius: 6px;

            > div {
              border: none;
              box-shadow: none;
            }
          }
        }

        &.external-select-container {
          .MuiInput-underline:before,
          .MuiInput-underline:after {
            border: none;
          }

          .MuiSelect-select:focus {
            background-color: transparent;
          }

          border-radius: 4px;

          svg {
            margin-top: 4px;
            margin-right: 6px;
            height: 20px;
            width: 20px;
            color: #153447;
          }

          div {
            text-decoration: none !important;
          }

          input {
            position: absolute;
            top: 0px;
          }

          > div {
            padding: 0px;
            margin: 0px;
            height: max-content;
            min-height: 40px;
            border: 1px solid #dfe4eb;
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
            border-radius: 6px;

            > div {
              height: max-content;
              min-height: 40px;
              padding: 0px;
              margin: 0px;

              > div {
                height: max-content;
                min-height: 40px;
                padding: 0px;
                margin: 0px;
                padding-top: 6px;
                padding-bottom: 6px;
                margin-left: 6px;

                > div {
                  padding: 0px;
                  margin: 0px;
                  margin-top: 6px;
                  margin-left: 6px;
                  border-radius: 4px;
                  height: 24px;
                  font-style: normal;
                  font-weight: 500;
                  font-size: 12px;
                  line-height: 18px;
                  background: #e9edf1;
                  color: #354152;
                }
              }
            }
          }
        }
      }
    }
  }

  .multi-tooltip-class {
    margin-top: -50px;
  }

  .contacts {
    position: relative;
    z-index: 0;

    .contact-item {
      position: relative;
      z-index: 1;
      height: 40px;

      & .error {
        color: red;
      }

      .MuiIconButton-root {
        &:hover {
          background-color: transparent;
          color: #56b8d9;
        }
      }
    }

    & .input-container {
      padding-left: 25px;
      color: transparent;
      width: 100%;
      height: 100%;
      position: absolute;
      border: none;
      outline: none !important;
    }

    & .placeholder {
      padding-left: 3px;
      color: #888;
      z-index: 1;
    }

    &:focus-within {
      input {
        padding-left: 25px;
      }

      .placeholder {
        display: inline-block;
      }
    }

    .add-recipient {
      position: absolute;
      right: 14px;
      top: 39px;
      cursor: pointer;
    }

    display: flex;
    align-items: center;

    &:focus {
      border-bottom: 1px solid #eceff1;
    }

    .items {
      width: calc(100% - 40px);
      padding-left: 5px;
      display: flex;
      align-items: center;
      column-gap: 8px;
      row-gap: 8px;
      flex-wrap: wrap;
      height: max-content;
      left: 0px;
    }

    .recipients-placeholder {
      color: #6d82a2;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }

    .icon-add-1 {
      color: #fff;
      background: #005683;
      border-radius: 100%;
      width: 25px;
      height: 25px;
      margin-left: 5px;
      font-size: 12px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }

    .contact-item {
      background: #e9edf1;
      height: 24px;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      left: -8px;

      button {
        text-transform: none;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        color: #354152;
      }
    }

    .icon-close {
      font-size: 9px;
    }
  }

  .flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .inline-flex-center {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  > li {
    list-style-type: none;
    border-radius: 3px;
    background-color: #fff;
    box-shadow: inset 2px 0 0 0 #56b8d9;
    z-index: 10;
    padding: 15px;
    margin-left: 15px;
  }

  > tr {
    z-index: 10;
    border-radius: 3px;
    background-color: var(--secondary-tone-4);
    box-shadow: inset 2px 0 0 0 #a0d6e9;

    &.hotels {
      td {
        height: 70px;
      }
    }

    &.hotel-rooms {
      td {
        height: 75px;
      }
    }

    span.fa {
      font-size: 9px;
      color: #798eab;
      padding-right: 5px;
    }

    > td {
      color: var(--primary);
      font-size: 14px;
      padding: 15px 8px;
      line-height: normal;
      text-align: center;
      vertical-align: middle;
    }
  }
}

.danger-zone {
  border: 1px solid var(--warning);
  padding: 12px;
  border-radius: 8px;
  margin-top: 12px;
}

.MuiBreadcrumbs-root * {
  font-size: 16px !important;
}

.event-policies-dropdown {
  &.disabled {
    color: var(--text-disabled);
    cursor: not-allowed;
  }

  span.default.icon-private-policy::after {
    position: absolute;
    width: 6px;
    height: 6px;
    content: "";
    background: #ff775c;
    border-radius: 100%;
    top: 0px;
    right: 0px;
  }
}

p.total-rows {
  margin-top: 10px;
  font-size: 14px;
  color: #777;
}

.col-xs-5ths,
.col-sm-5ths,
.col-md-5ths,
.col-lg-5ths {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-xs-5ths {
  width: 20%;
  float: left;
}

@media (min-width: 768px) {
  .col-sm-5ths {
    width: 20%;
    float: left;
  }
}

@media (min-width: 992px) {
  .col-md-5ths {
    width: 20%;
    float: left;
  }
}

@media (min-width: 1200px) {
  .col-lg-5ths {
    width: 20%;
    float: left;
  }
}

.scanning-page {
  .component-wrapper {
    padding: 0;
  }
}

/* sticky footer */

html,
body,
#app,
.app-wrapper {
  height: 100%;
}

footer {
  border-top: 1px solid var(--secondary-tone-4);

  .main-footer {
    .wrapper {
      padding: 25px 0;
      font-size: 12px;
      color: #a7b7ce;

      a {
        font-size: 12px;
        color: #a7b7ce;
      }
    }

    ul {
      list-style-type: none;
      margin: 0 -15px;
      padding: 0;

      li {
        display: inline-block;
        padding: 0 15px;
        cursor: pointer;
      }
    }
  }
}

.app-wrapper {
  display: flex;
  flex-direction: column;

  > main {
    flex: 1 0 auto;
  }
}

.med-loader-container {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  width: 100%;
  left: 0;

  &.in {
    opacity: 1;
    visibility: visible;
  }
}

.onoffswitch-label {
  margin-bottom: 0;
}

.login-page {
  h1 {
    font-size: 26px;
    font-weight: 700;
  }
}

body.modal-open .modal {
  opacity: 1;
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: 100%;
}

body.modal-open .modal .modal-dialog {
  margin: auto;
  height: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
}

body div[role="dialog"] {
  z-index: 10000;
}

.modal-content {
  padding: 35px 25px;
  box-shadow: 0 2px 15px 0 #808080;
  border: 1px solid #f2f2f2;
  border-radius: 4px;
}

.modal-header,
.modal-body,
.modal-footer {
  padding: 0;
}

.modal-body {
  padding: 20px 0;
  color: var(--primary);
  font-size: 14px;
  line-height: 26px;

  p {
    margin: 0;
  }

  span {
    font-size: 16px;
  }
}

.modal-footer {
  border: none;

  .btn-danger {
    font-weight: 700;
    text-align: center;
    width: 100%;
    padding: 12px 0;
  }
}

.modal-header {
  h4 {
    color: var(--primary);
    font-size: 22px;
    font-weight: 300;
    margin-right: 30px;
  }

  padding-bottom: 20px;

  button.close {
    display: none;
  }

  span.icon-close {
    cursor: pointer;
    position: absolute;
    right: 25px;
    top: 38px;
    font-size: 20px;
  }
}

[class^="icon-"],
[class*=" icon-"] {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.modal-backdrop {
  background-color: #1c314f;

  &.fade {
    opacity: 0.3;
  }
}

.margin-bottom-15 {
  margin-bottom: 15px !important;
}

h2:not(.editor-h2) {
  font-size: 26px;
  font-weight: 400;
  margin: 0;
  padding: 5px 0;
}

.breadcrumb {
  background-color: transparent;
}

h3:not(.editor-h3) {
  font-size: 20px;
  font-weight: 400;
  margin: 0;
  padding: 5px 0;
  margin-bottom: 12px;
}

.component-wrapper {
  flex: 1;
  padding: 0px;
}

.flexbox {
  display: flex;
  gap: 12px;
}

.page-list-crm,
.page-assign-roles,
.page-list-persons,
.page-list-staff,
.list-sponsors-main,
.list-sponsor-persons-main,
.list-staff-main,
.list-crm-main,
.list-bank-accounts-main,
.my-account,
.list-hotels-main,
.list-events .list-events-main,
.judge-listing {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 56px);
  min-height: calc(100vh - 56px);

  .top-bar {
    background-color: white;
    padding: 0px 24px;
    display: flex;
    align-items: center;
    height: 112px;
    flex-direction: column;
    border-bottom: 1px solid var(--background-content);
    display: flex;
    position: relative;
    z-index: 1;

    &::before {
      content: "";
      pointer-events: none;
      position: absolute;
      bottom: -21px;
      left: 0;
      width: 100%;
      height: 21px;
      z-index: 0;
      background: rgb(210, 210, 217);
      background: linear-gradient(
        180deg,
        rgba(210, 210, 217, 1) 0%,
        rgba(240, 240, 244, 0) 100%
      );
    }

    & > div {
      display: flex;
      justify-content: space-between;
      flex: 1;
      min-width: 100%;
      max-width: 100%;
      width: 100%;
    }

    & > div:first-child {
      height: 56px;
      border-bottom: 1px solid #ccc;
    }

    & > div:last-child {
      height: 56px;
    }

    .search-wrapper {
      width: 250px;
    }

    .left {
      justify-content: flex-start;
    }

    .middle {
      justify-content: center;
    }

    .right {
      justify-content: flex-end;
    }

    .left,
    .middle,
    .right {
      display: flex;
      align-items: center;
      height: 56px;
      flex: 1;
      gap: 5px;
    }

    .breadcrumb {
      margin: 0px;
    }

    ul:not(.breadcrumb) {
      display: flex;
      justify-content: center;
      text-decoration: none;
      list-style: none;
      align-items: center;
      height: 56px;
      margin: 0px;
      padding: 0px;

      li {
        cursor: pointer;
        position: relative;
        font-weight: 500;
        height: 56px;
        display: flex;
        align-items: center;
        padding: 12px 16px;

        &.selected {
          color: var(--secondary);

          &::after {
            transform: scaleX(1);
          }
        }

        &::after {
          content: "";
          display: block;
          position: absolute;
          bottom: 0px;
          left: 0px;
          width: 100%;
          transition: transform 0.2s;
          transform-origin: center;
          height: 3px;
          transform: scaleX(0);
          background-color: var(--secondary);
        }
      }
    }
  }

  .list-view {
    flex: 1;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    max-width: 100vw;
    padding: 24px;
    background-color: var(--background-content);

    table {
      min-width: 100%;
    }

    .kmb-name {
      min-width: 220px;
      max-width: 500px;
      word-break: break-word;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .kmb-actions,
    .kmb-urlAlias {
      .select-advanced {
        .upper-area {
          padding: 0;
          border: none;
        }

        .bottom-area {
          width: 115px;
          margin-top: 5px;
        }

        .options-wrapper {
          li {
            user-select: none;
            padding: 5px 10px;

            &:first-child {
              opacity: 0.5;
              pointer-events: none;
            }
          }
        }
      }
    }

    .main-wrapper {
      position: relative;
      flex: 1;
      height: 100%;
    }

    td.title {
      color: var(--primary);
      font-size: 14px;
    }

    .med-loader-container {
      position: absolute;
      text-align: center;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 1;
      background-color: white;
    }
  }
}

.invisible {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.subtitle {
  font-weight: 300;
}

.tooltip {
  &.top {
    .tooltip-arrow {
      border-top-color: var(--secondary);
    }
  }

  &.delete-tooltip {
    .tooltip-arrow {
      border-top-color: var(--error-color);
    }

    .tooltip-inner {
      background-color: var(--error-color);
    }
  }
}

.tooltip-inner {
  background-color: var(--secondary);
  padding: 1px 4px;
  font-size: 11px;
}

.normal-modal {
  backdrop-filter: blur(2px);
}

.email-schedule-bar {
  position: relative;
  border-top: 1px solid #e1e1e6;
  flex-wrap: wrap;
  width: 100%;
  background: white;
  min-height: 52px;
  z-index: 2;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  border: 1px solid var(--background-content);
  padding: 4px 35px;
  flex-wrap: wrap;
  gap: 4px;

  .email-save-button {
    height: 40px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    justify-content: center;
    column-gap: 8px;

    &.email-secondary {
      color: white;
      background-color: #0079ca;

      &:hover {
        color: white;
        background-color: #57aeff;
      }

      .icon-wrapper {
        position: relative;

        .clock-potitioner {
          position: absolute;
          top: 7px;
          left: 6px;
        }
      }
    }
  }
}

.confirm-changes-wrapper {
  .confirm-changes {
    .submit-container {
      display: flex;
      column-gap: 16px;
    }

    .modal-footer {
      display: none;
    }

    .modal-body {
      padding: 20px 0 0;

      button:first-child {
        margin-right: 10px;
      }
    }
  }
}

.right {
  display: flex;
  justify-content: flex-end;
}

.flex-right {
  justify-content: flex-end;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 1px solid var(--secondary);
  border-radius: 4px;
  color: var(--secondary);
  outline: none !important;

  &:focus {
    color: var(--secondary);
  }

  &:active,
  &.active {
    box-shadow: none;
  }

  &.selected {
    background-color: var(--secondary);
    color: white;
  }

  &.create {
    margin-left: 16px;
    min-width: 92px;
    height: 40px;
    background-color: var(--secondary);
    color: white;
    font-weight: 600;

    &:hover {
      background-color: var(--secondary-tone-1);
      border: 1px solid var(--secondary-tone-1);
    }
  }

  &.rounded {
    border-radius: 4px;
  }

  &:hover {
    border: 1px solid var(--secondary-tone-1);
    background-color: var(--secondary-tone-1);
    color: white;
  }

  > span {
    display: flex;
    align-items: center;
    font-size: 16px;
    padding-right: 5px;
    padding-left: 3px;
  }

  &.save {
    font-weight: 700 !important;
  }

  &.transparent-dark {
    background-color: transparent;
    border: none;
    color: var(--primary-tone-2);

    &:hover {
      color: var(--primary-tone);
    }
  }

  &.cancel,
  &.save {
    text-transform: capitalize !important;
  }

  &.cancel {
    font-weight: 500;
    padding: 8px 23px;
    border: 1px solid var(--secondary-tone-4);
    background-color: white;
    color: var(--secondary);
    width: 120px;
    box-shadow: none;

    &:hover {
      border: 1px solid var(--secondary-tone-1);
      background-color: var(--secondary-tone-1);
      color: white !important;
    }
  }

  &.btn-white {
    background-color: white;
    color: var(--secondary);
    border: 1px solid var(--secondary);
    box-shadow: none;
  }

  &.save {
    min-width: 120px;
    background-color: var(--secondary);
    color: white;

    &:hover {
      background-color: var(--secondary-tone-1);
      border-color: var(--secondary-tone-1);
    }
  }

  &.btn-green {
    padding: 8px 23px;
    background-color: var(--success);
    box-shadow: none;
    text-transform: uppercase;
    font-weight: 700;
    width: 120px;
    color: white;
    font-weight: 500;
    border: 1px solid var(--success);

    &:focus,
    &:hover {
      background-color: #02bb83;
    }
  }

  &.transparent {
    background-color: transparent;
    border: none;
    outline: none !important;
    box-shadow: none !important;
    padding: 0;
  }
  &.transparent-light {
    background-color: transparent;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    padding: 0;
    margin-top: 0px !important;
  }
  &.transparent-light:hover {
    background-color: transparent;
    border: none;
    outline: none !important;
    box-shadow: none !important;
    padding: 0;
    color: var(--primary-tone);
  }
  &.tab-header-btn {
    display: inline-flex;
    height: 40px;
    gap: 5px;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    color: var(--black-tone-1) !important;
    border: none;

    /* border-radius: 20px; */
    &:hover {
      background-color: var(--primary-tone-4);
      color: var(--primary) !important;
    }
  }

  &.table-header {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--secondary);
    color: var(--secondary);
    padding: 7px 30px;
    font-size: 14px;
    font-weight: 500;

    &:hover {
      background-color: var(--secondary-tone-4);
    }
  }

  &.btn-danger {
    height: 38px;
    min-width: 120px;
    font-weight: 500;
    background-color: var(--warning);
    border: var(--error-color);
    color: white;

    &:hover,
    &:active {
      background-color: #ff775c7c;
    }
  }

  &.btn-red {
    background-color: var(--error-color);
    padding: 8px 23px;
    font-weight: 500;
    border-color: var(--error-color);
    text-transform: uppercase;
    color: white;

    &:hover,
    &:active,
    &:focus {
      border-color: #ff9050;
      background-color: #ff9050;
    }
  }

  &.circle {
    border-radius: 30px;
    background-color: white;
    border: none;
    box-shadow: 0 1px 2px 0 #c2e0eb;
    text-align: center;
    outline: none !important;
    width: 28px;
    height: 28px;
    color: var(--secondary);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 3px;

    & > span {
      display: flex;
      padding-right: 0;
      font-size: 14px;
      line-height: normal;
    }
  }
}

.tab-header {
  & span[class^="icon-"] {
    font-size: 16px;
  }

  position: relative;

  & > div.active,
  & > button.active {
    color: var(--primary);
  }

  .select-advanced {
    display: inline-block;

    &:hover .upper-area,
    &:hover .upper-area > span.directional {
      color: var(--primary);
      background-color: #f0f0f4;
    }

    .upper-area {
      border: none;
      /*  border-radius: 20px; */
      color: var(--tab-header-menu);

      &:hover {
        color: var(--primary);
      }

      & > span.directional {
        color: var(--tab-header-menu);

        &:hover {
          color: var(--primary);
        }
      }
    }
  }

  .select-button,
  .bt-select-button {
    & > div {
      border: none;
    }

    .css-18udhwx-control:hover,
    .css-18udhwx-control:focus-within {
      border: none;
    }

    &:hover & > div,
    &.active & > div,
    &:focus & > div,
    &:active & > div,
    &:focus-within & > div {
      border-color: var(--primary);
    }

    .css-1gtu0rj-indicatorContainer,
    .css-tlfecz-indicatorContainer {
      padding: 0px !important;
    }

    &.active,
    &:hover {
      /* inner menu */
      /* .css-26l3qy-menu {
        min-width: 150px;
      } */
      .css-6q0nyr-Svg,
      .css-1uccc91-singleValue,
      .select-button-placeholder {
        color: var(--primary);
      }
    }

    &.active .css-dreb8z-control,
    &.active .css-18udhwx-control {
      background-color: var(--primary-tone-4) !important;
    }

    & > div:first-child {
      border: none;

      /* border-radius: 20px; */
      &:hover {
        background-color: var(--primary-tone-4);
      }
    }

    .css-6q0nyr-Svg,
    .css-1uccc91-singleValue,
    .select-button-placeholder {
      font-weight: 500;
      color: var(--tab-header-menu);
      display: flex;
    }
  }
}

a {
  color: #56b8d9;

  &:active,
  &:focus,
  &:hover {
    color: var(--secondary);
    text-decoration: none;
  }
}

.animated-label {
  /* material design label */
  position: relative;
  margin-bottom: 20px;

  label {
    pointer-events: none;
    left: 0;
    top: -15px;
    -webkit-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
    color: #636466;
    font-weight: 600;
    margin-bottom: 0;
  }

  input:not(:placeholder-shown) ~ label,
  input:focus ~ label,
  textarea:focus ~ label {
    top: -15px;
    left: 0px;
  }

  input:focus ~ .bar::after,
  input:focus ~ .bar::before {
    width: 50%;
  }

  input,
  textarea {
    padding: 12px 10px 4px;
    border: none;
    border-bottom: 1px solid var(--primary);
    border-radius: 0;
    box-shadow: none !important;
    font-size: 13px;
  }

  .bar {
    position: relative;
    width: 100%;
    display: block;

    &::after,
    &::before {
      content: "";
      height: 2px;
      width: 0;
      bottom: 0;
      position: absolute;
      background-color: var(--secondary);
      transition: 0.2s ease all;
      -moz-transition: 0.2s ease all;
      -webkit-transition: 0.2s ease all;
    }

    &::before {
      left: 50%;
    }

    &::after {
      right: 50%;
    }
  }
}

.pac-container {
  z-index: 1000000 !important;
}

.breadcrumb {
  ul {
    padding-left: 0;
    list-style-type: none;
    margin-bottom: 0;

    li {
      display: inline-block;
      font-size: 14px;

      a {
        color: var(--secondary);
      }

      &::before {
        position: relative;
        top: 1px;
        display: inline-block;
        font-family: "Glyphicons Halflings";
        font-style: normal;
        font-weight: 400;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "\e258";
        font-size: 11px;
        padding: 0 5px;
      }

      &:first-child {
        &::before {
          display: none;
        }
      }
    }
  }
}

.dropdown {
  > a {
    color: #96a1b0;
    font-size: 14px;

    span {
      position: relative;
      top: 2px;
    }
  }

  &.in {
    .dropdown-menu {
      display: block;
    }
  }

  .dropdown-menu {
    border: none;
    box-shadow: none;
    background-color: var(--primary-tone-4);
    padding: 5px 15px;
    min-width: 0;
    padding-left: 0;
    list-style-type: none;
    margin: 0;

    > li {
      > a {
        display: block;
        font-size: 14px;
      }
    }
  }
}

.deploy {
  width: 0;
  opacity: 0;
  transition: all 0.2s ease-in;

  &.in {
    width: 100%;
    opacity: 1;
  }
}

.onoffswitch {
  width: 37px;

  .onoffswitch-switch {
    width: 12px;
    right: 17px;
    transition: all 0.2s ease-in 0s;
    height: 12px;
  }

  .onoffswitch-inner {
    &::after,
    &::before {
      height: 16px;
    }

    &::before {
      background-color: var(--secondary-tone-1);
    }

    &::after {
      background-color: #c7c7c7;
    }
  }
}

.pagination {
  display: block;
  text-align: center;
  margin: 50px 0;

  &.has-rpp-select {
    .rpp-select {
      width: 150px;
      float: left;
    }

    .options-wrapper {
      text-align: left;
      max-height: 100px;

      li {
        font-size: 12px;
        padding: 5px 15px;
      }
    }

    .select-advanced {
      .upper-area {
        padding: 5px 15px;
        font-size: 12px;
        text-align: left;
      }

      span.directional {
        top: 5px;
        color: var(--primary);
      }
    }

    > ul {
      margin-left: 150px;
      padding-left: 0;
    }
  }

  li {
    display: inline-block;

    &.current-page {
      button {
        background-color: var(--secondary);
        color: white;
        border-radius: 4px;
      }
    }

    &.next-page {
      button {
        margin-left: 30px;

        &::after {
          display: inline-block;
          content: "\67";
          font-family: "blue-tree-admin";
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          speak: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          position: relative;
          top: 2px;
          padding-left: 5px;
        }
      }
    }

    &.previous-page {
      button {
        margin-right: 30px;

        &::before {
          display: inline-block;
          content: "\66";
          font-family: "blue-tree-admin";
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          speak: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          position: relative;
          top: 2px;
          padding-right: 5px;
        }
      }
    }

    &.previous-page,
    &.next-page {
      button {
        font-weight: 400;
        font-size: 14px;
      }
    }
  }

  button {
    display: inline-block;
    padding: 5px 10px;
    font-size: 15px;
    color: #6e7c90;
    font-weight: 700;
    margin: 0 5px;
    background-color: transparent;
    border: none;
    outline: none;
  }
}

@media (min-width: 768px) {
  .modal-dialog {
    width: 495px;
  }
}
