.pulse {
  animation: pulse 1s linear infinite;
  animation: pulse 1s linear infinite;
  animation: pulse 1s linear infinite;
  animation: pulse 1s linear infinite;
}

@keyframes "pulse" {
  0% {
    transform: scale(1.15);
    transform: scale(1.15);
    transform: scale(1.15);
    transform: scale(1.15);
    transform: scale(1.15);
  }

  50% {
    transform: scale(0.96);
    transform: scale(0.96);
    transform: scale(0.96);
    transform: scale(0.96);
    transform: scale(0.96);
  }

  100% {
    transform: scale(0.98);
    transform: scale(0.98);
    transform: scale(0.98);
    transform: scale(0.98);
    transform: scale(0.98);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1.15);
    transform: scale(1.15);
  }

  50% {
    transform: scale(0.96);
    transform: scale(0.96);
  }

  100% {
    transform: scale(0.98);
    transform: scale(0.98);
  }
}

@keyframes "pulse" {
  0% {
    transform: scale(1.15);
    transform: scale(1.15);
  }

  50% {
    transform: scale(0.96);
    transform: scale(0.96);
  }

  100% {
    transform: scale(1);
    transform: scale(1);
  }
}

@keyframes "pulse" {
  0% {
    transform: scale(1.15);
    transform: scale(1.15);
  }

  50% {
    transform: scale(0.96);
    transform: scale(0.96);
  }

  100% {
    transform: scale(0.98);
    transform: scale(0.98);
  }
}
