.policy-group-container {
  .btn.new-condition {
    width: 100%;
  }

  .faded {
    color: var(--black-tone-3);
  }

  .condition-block {
    display: flex;
    flex-direction: column;

    .remover {
      position: absolute;
      bottom: -20px;
      right: 0;
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding: 0 12px;

      button.remove {
        color: var(--warning);
        font-size: 12px;
      }

      button.add {
        color: var(--secondary);
        font-size: 12px;
      }
    }

    .add-condition {
      margin-top: 12px;
    }
  }

  .condition {
    display: flex;
    position: relative;
    gap: 5px;

    &>div:nth-child(1),
    &>div:nth-child(3) {
      width: calc(50% - 55px);
      min-width: calc(50% - 55px);
      max-width: calc(50% - 55px);
    }

    &>div:nth-child(2) {
      min-width: 103px;
      max-width: 103px;
      width: 103px;
    }
  }
}

button.btn.circle.remove-condition {
  color: var(--warning);
  position: absolute;
  right: -28px;
  top: 10px;
  background-color: var(--background-content);
  width: 22px;
  height: 22px;
  padding: 0;
  box-shadow: none;
  font-size: 18px;

  &.email {
    right: -14px;
    top: calc(50% - 11px);
  }
}
