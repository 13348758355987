#timeline {
  min-height: 100%;
  margin: 0;
  display: flex;

  *,
  ::after,
  ::before {
    box-sizing: content-box;
  }

  .field-type {
    * {
      box-sizing: border-box;
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 35px;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    width: 4px;
    background-color: #f7fbfd;
  }

  .entries {
    width: calc(100% - 15px);
    margin: auto;
    position: relative;
    left: -5px;

    .entry {
      background-color: white;
      width: calc(50% - 80px);
      float: left;
      padding: 20px;
      clear: both;
      text-align: right;
      box-shadow: 0 1px 5px rgb(0 0 0 / 15%);

      &.entry-exit {
        .title {
          &::before {
            border: 4px solid var(--error-color);
          }
        }
      }

      &:not(:first-child) {
        margin-top: -60px;
      }

      .title {
        font-size: 16px;
        margin-bottom: 12px;
        position: relative;
        color: var(--primary);

        &::before {
          content: "";
          position: absolute;
          width: 8px;
          height: 8px;
          border: 4px solid #14da9e;
          border-radius: 100%;
          top: 50%;
          transform: translateY(-50%);
          right: -73px;
          z-index: 1000;
        }

        &.big::before {
          width: 24px;
          height: 24px;
          transform: translate(8px, -50%);
        }
      }

      .body {
        color: #aaa;

        p {
          line-height: 1.4em;
        }
      }

      &:nth-child(2n) {
        text-align: left;
        float: right;

        .title {
          &::before {
            left: -62px;
          }

          &.big::before {
            transform: translate(-8px, -50%);
          }
        }
      }
    }
  }
}
