.select-advanced {
  position: relative;

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  .upper-area {
    border: 1px solid #d5d9df;
    border-radius: 3px;
    background-color: #fff;
    padding: 12px;
    cursor: pointer;
    position: relative;
    color: var(--tab-header-menu);
    font-size: 14px;
    user-select: none;

    > span.directional {
      padding-left: 8px;
      font-size: 16px;
      color: var(--tab-header-menu);
    }
  }

  &.multiselect {
    .bottom-area {
      padding: 0;
    }

    .options-wrapper {
      li {
        cursor: pointer;

        &.selected {
          background-color: #e8e8e9;
        }
      }
    }
  }

  .options-wrapper {
    padding: 0;
    margin: 0;
    max-height: 300px;
    overflow: auto;

    li {
      display: block;
      color: var(--primary);
      padding: 11px 20px;

      &:hover {
        background-color: #f3f3f3;
      }
    }
  }

  .bottom-area {
    min-width: max-content;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 6px 12px 0 rgb(82 97 115 / 50%);
    padding: 15px;
    position: absolute;
    width: 200px;
    left: 0;
    z-index: 1;
    top: 48px;

    input[type="text"] {
      height: 30px;
    }

    .checkboxes-wrapper,
    .range-wrapper {
      white-space: nowrap;

      > div > span {
        font-size: 11px;
        color: #a7b7ce;
        cursor: pointer;
        margin-bottom: 10px;
        display: inline-block;
        user-select: none;
      }
    }

    .range-wrapper {
      padding-left: 15px;

      > span {
        display: block;
        text-align: right;
        margin-bottom: 0;
      }

      &::before {
        content: "";
        width: 24px;
        display: block;
        height: 50px;
        position: absolute;
        left: 15px;
        top: 28px;
        border: 1px solid #d5d9df;
        border-right: none;
        border-radius: 6px;
      }

      > .rdt {
        margin-bottom: 15px;

        &:last-child {
          margin: 0;
        }

        &::after {
          background-color: #f6f6f6;
          color: var(--secondary);
          content: "\74";
          font-family: icomoon !important;
          font-style: normal !important;
          font-weight: normal !important;
          font-variant: normal !important;
          text-transform: none !important;
          speak: none;
          color: var(--tab-header-menu);
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          position: absolute;
          top: 1px;
          right: 1px;
          width: 28px;
          height: calc(100% - 2px);
          padding: 6px;
          border-left: 1px solid #d5d9df;
          pointer-events: none;
        }
      }
    }
  }
}

body > .checkbox-wrapper {
  z-index: 1;
}

.checkbox-wrapper {
  margin-bottom: 10px;
  position: relative;

  &.sortable {
    padding-left: 15px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  > span {
    position: absolute;
    left: 0;
    cursor: move;
    text-align: center;
    color: #96a1b0;
    font-size: 11px;
    top: 5px;
  }
}

label {
  user-select: none;
  margin-bottom: 0;
}
