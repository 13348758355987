.section-fields .med-table-container table tbody > tr > td.kmb-actions {
  max-width: 45px;
  min-width: 45px;
  z-index: 100;
}

.list-crm-main {
  .information-fields {
    tbody tr {
      &:nth-of-type(4) {
        padding-left: 50px;
      }
    }
  }
}
.crm.information-fields {
  tbody tr {
    &:nth-of-type(5) {
      padding-left: 0px !important;
    }
  }
}
.information-fields {
  &.abstracts {
    tbody tr {
      &:nth-of-type(1),
      &:nth-of-type(2),
      &:nth-of-type(3),
      &:nth-of-type(4),
      &:nth-of-type(5) {
        padding-left: 0px !important;
      }
    }
  }
  .tags-input {
    border: none !important;
  }

  .table {
    min-width: 500px;
  }
  .kmb-actions {
    width: 50px;
    max-width: 50px;
  }
  .table {
    * {
      border: none;
    }
    tbody tr {
      border-radius: 5px;
      margin-bottom: 8px;
    }
    tr {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: stretch;
      min-width: 100%;
      padding-top: 5px;
      padding-bottom: 5px;
    }
    tbody tr {
      &:nth-of-type(1),
      &:nth-of-type(2),
      &:nth-of-type(3) {
        padding-left: 50px;
      }
    }
    td,
    th,
    th div {
      padding: 0;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      white-space: normal !important;
    }
    th.kmb-name {
      padding-left: 12px;
    }
    th.kmb-value {
      min-width: 400px;
    }
    .kmb-name {
      & > div.kmb-flex-center {
        padding-left: 12px;
      }

      & > * {
        justify-content: flex-start !important;
      }
      justify-content: flex-start !important;
    }
    .kmb-remove {
      max-width: 32px;
      width: 32px;
      color: var(--error-color);
      opacity: 0.3;
      cursor: pointer;
    }

    .kmb-askUser,
    .kmb-pricing,
    .kmb-condition,
    .kmb-remove,
    .kmb-required {
      width: 65px;
      flex: unset;
      height: unset;
      .type-checkbox.kmb-checkbox {
        display: flex;
      }
      & > div {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .kmb-type {
      width: 32px;
      flex: unset;
      & > div {
        justify-content: center;
        flex-direction: column;
        gap: 4px;
      }
    }

    .kmb-value {
      & > div {
        min-height: 36px;
        height: 100%;
        max-width: 100%;
      }
    }
    textarea {
      min-height: 36px;
      max-height: 100%;
      height: 100%;
      padding: 8px 0;
      color: var(--primary);
      overflow: hidden;
      display: inline-block;
      vertical-align: middle;
    }
    input:focus,
    input:hover:not(input[disabled]) {
      box-shadow: none;
      background-color: var(--primary-tone-4);
    }
  }

  .disabled {
    cursor: not-allowed;
  }

  .add-fields {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 35px;
    gap: 5px;
    & > div {
      width: 270px;
      max-width: 100%;
    }
    .select-advanced {
      display: inline-block;
    }

    .upper-area {
      text-align: center;
      width: 100%;
      display: inline-block;
      border: 1px solid var(--secondary);
      color: var(--secondary);
      padding: 7px 30px;
      font-size: 14px;

      &:hover {
        background-color: #f5f9fd;
      }

      > span {
        > span {
          font-size: 10px;
          padding-right: 10px;
        }
      }
    }

    .bottom-area {
      text-align: left;
    }
  }
}

.symbol-wrapper {
  display: flex;
  flex-direction: row;
  & > *:first-child {
    margin-right: 5px;
  }
}
.symbol {
  & > *:first-child {
    font-family: "Font Awesome 5 Free";
    font-weight: bold;
    border: 1px solid #96a1b0;
    color: var(--primary);
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    font-size: 10px;
  }
}

//   for drag and drop
.information-row {
  background: white;
  box-shadow: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  min-width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  user-select: none;
  z-index: 10000;

  &.section-row {
    margin-top: 30px;
    align-items: flex-end;
    margin-bottom: 5px;
    background-color: transparent;
    input {
      background-color: transparent;
    }
    .kmb-name {
      max-width: unset;
    }
    .kmb-type,
    .kmb-askUser,
    .kmb-value,
    .kmb-required,
    .kmb-pricing,
    .kmb-condition {
      display: none;
    }
  }
  .kmb-remove:hover {
    opacity: 1 !important;
  }
  &.tr {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    min-width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .kmb-draghandle {
    max-width: 50px;
    width: 50px;
  }

  .error {
    border-bottom: 1px solid var(--error-color) !important;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .kmb-askUser,
  .kmb-required,
  .kmb-remove,
  .kmb-actions,
  .kmb-pricing,
  .kmb-condition {
    width: 34px;
    height: 34px;
  }
  .kmb-value {
    min-width: 400px;
  }

  .information-row:hover .kmb-remove {
    opacity: 1;
  }
  .bold {
    font-weight: bold;
  }

  input.error::placeholder {
    color: var(--error-color) !important;
  }

  .symbol-wrapper {
    display: flex;
    flex-direction: row;
    & > *:first-child {
      margin-right: 5px;
    }
  }
  .symbol {
    & > *:first-child {
      font-family: "Font Awesome 5 Free";
      font-weight: bold;
      border: 1px solid #96a1b0;
      color: var(--primary);
      width: 16px;
      height: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      font-size: 10px;
    }
  }

  * {
    border: none;
  }
  &.tr {
    border-radius: 5px;
    margin-bottom: 8px;
  }
  &.tr {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    min-width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  &tr {
    &:nth-of-type(1),
    &:nth-of-type(2),
    &:nth-of-type(3) {
      padding-left: 50px;
    }
  }
  td {
    padding: 0;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: normal !important;
  }

  .kmb-name {
    & > div.kmb-flex-center {
      padding-left: 12px;
    }

    & > * {
      justify-content: flex-start !important;
    }
    justify-content: flex-start !important;
  }

  .kmb-askUser,
  .kmb-pricing,
  .kmb-condition,
  .kmb-required {
    width: 65px;
    flex: unset;
    height: unset;
    .type-checkbox.kmb-checkbox {
      display: flex;
    }
    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .kmb-type {
    width: 32px;
    flex: unset;
    & > div {
      justify-content: center;
    }
  }

  .kmb-value {
    & > div {
      min-height: 36px;
      height: 100%;
      max-width: 100%;
    }
  }
  textarea {
    min-height: 36px;
    max-height: 100%;
    height: 100%;
    padding: 8px 0;
    color: var(--primary);
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
  }
  input:focus,
  input:hover:not(input[disabled]) {
    box-shadow: none;
    background-color: var(--primary-tone-4);
  }

  .field-type.type-text {
    position: relative;
    input {
      padding-right: 35px;
      min-width: 193px;
    }
    span {
      position: absolute;
      right: 5px;
      top: 50%;
      transform: translateY(-50%);
      color: var(--primary-tone-3);
    }
  }
}
