.section-reservations {
  .med-table-container.is-pinned {
    thead .kmb-eventUserFullName::after,
    tbody .kmb-eventUserFullName::after {
      pointer-events: none;
      content: "";
      position: absolute;
      top: 0;
      right: -77px;
      width: 77px;
      height: calc(100% + 8px);
      background: linear-gradient(90deg, #d7d7e033, rgba(240, 240, 244, 0));
    }

    .kmb-eventUserFullName {
      position: sticky;
      z-index: 2;
    }
  }

  .kmb-eventUserFullName {
    z-index: 2;
    position: sticky;
    left: -24px;
  }

  thead .kmb-eventUserFullName {
    background-color: var(--background-content, white);
  }

  tbody .kmb-eventUserFullName {
    background-color: white;
  }

  .kmb-actions {
    position: sticky;
    right: -24px;
    background: white;
    z-index: 2;
  }
}
