.form-group {
  .switch-toggle-container {
    .switch-toggle-handle {
      margin-top: 2px;
    }
  }
}
.switch-toggle-container {
  width: 28px;
  height: 16px;
  border-radius: 9.6px;
  background-color: #abb8c9;
  cursor: pointer;
  transition: background 0.25s ease 0s;
  font-size: 10px;

  .switch-toggle-handle {
    margin-left: 2px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: white;
    transform: translate3d(0px, 0px, 0px);
    transition: background-color 0.25s ease 0s, transform 0.25s ease 0s,
      box-shadow 0.15s ease 0s;
  }

  &.active {
    background-color: #0079ca;

    .switch-toggle-handle {
      transform: translate3d(12px, 0px, 0px) !important;
    }
  }

  &.disabled {
    background-color: #dadada;

    &.active {
      background-color: #6ca4c9;
    }
  }
}

table tbody tr td,
.policy-switch,
.abstract-rating-switch,
.rate-abstract {
  .switch-toggle-container {
    .switch-toggle-handle {
      transform: translate3d(0px, 2px, 0px);
    }

    &.active {
      .switch-toggle-handle {
        transform: translate3d(12px, 2px, 0px) !important;
      }
    }
  }
}
