.crm-menu {
  .crm-tab-menu {
    ul {
      list-style-type: none;
      padding: 0;
      margin-left: -10px;

      li {
        display: inline-block;
        margin-right: 20px;
        padding-right: 15px;
        padding-bottom: 20px;
        padding-left: 15px;
        padding-top: 10px;
        position: relative;
        transition: all 0.2s ease-in-out;

        &::after {
          content: "";
          display: flex;
          width: 100%;
          left: 0;
          height: 3px;
          position: absolute;
          bottom: 13px;
          transform: scaleX(0);
          transition: transform 0.1s ease-in-out;
        }

        &.active {
          button {
            color: #292929;
            font-weight: 500;
          }

          &::after {
            transform: scaleX(1);
          }
        }
      }

      button {
        color: var(--primary);
        font-size: 15px;
        font-weight: 400;
        line-height: 20px;
        transition: color 0.2s ease-in-out;

        &:hover,
        &:active,
        &:focus {
          color: #292929;
        }
      }
    }
  }

  .main-grid {
    padding-left: 0;

    &.vertical {
      > .row {
        flex-wrap: wrap;
        padding: 0;

        > div {
          width: 100%;
        }
      }

      .tab-items {
        > ul {
          list-style-type: none;
          height: 100%;

          li {
            &.active {
              > button {
                color: var(--secondary);
                font-weight: 500;
              }
            }

            &:last-child {
              margin-bottom: 0;
            }

            button {
              color: var(--primary);
              font-size: 14px;
            }

            ul {
              // second level
              padding: 30px 15px;
              list-style-type: none;

              li {
                > button {
                  text-transform: uppercase;
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
  }
}
.list-sponsors-main {
  .kmb-sponsorImg {
    min-width: 50px;
    min-height: 50px;
    img {
      object-fit: contain !important;
    }
  }
}
.crm-table {
  background-color: white;
  padding: 25px;
  border-radius: 5px;
  margin-bottom: 10px;
}
