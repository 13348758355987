.kmb-subscription {
  .subscription-inner {
    > div {
      margin-bottom: 20px;
    }

    .subscription-wrapper {
      margin-top: 25px;
    }

    .kmb-range {
      margin-top: 10px;
    }
  }
}
